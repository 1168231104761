import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Container, Grid } from "@material-ui/core";
import scrollTo from "utils/scrollTo";
import AccountForm from "components/account/AccountForm";
import Toolbar from "components/layout/Toolbar";

const Account = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      scrollTo("#topPage");
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Minha Conta | +Alegria</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
        id="topPage"
      >
        <Container maxWidth="lg">
          <Toolbar name="Minha Conta" />
          <Grid container spacing={3} sx={{ pt: 3 }}>
            <Grid item lg={12} md={12} xs={12}>
              <AccountForm />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Account;
