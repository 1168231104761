import {
  BarChart,
  User,
  Users,
  Layers,
  Settings,
} from "react-feather";

const Title = ({ text }) => {
  return (
    <h3 
      style={{ 
        textAlign: "center", 
        color: "#12214D",
        fontFamily: "Roboto",
        marginBottom: ".8em" 
      }}
    >
      {text}
    </h3>
  )
} 

const Body = ({ text, size = 14, align = "left" }) => {
  return (
    <p 
      style={{
        fontFamily: "Roboto", 
        fontSize: `${size}px`,
        textAlign: align
      }}
    >
      {text}
    </p>
  )
}
const steps = [
  {
    selector: '.tour-welcome',
    content: () => (
      <>
        <Title text="Seja bem-vindo(a)!" />
        <Body text="Vamos iniciar um tour para conhecer a plataforma! ⛵️" size={16} align="center"/>
      </>
    )
  },
  {
    selector: '.tour-dashboard',
    content: () => (
      <>
        <Title text={<BarChart size={20} color="#12214D" />} />
        <Body text="Painel que permite acompanhar as métricas de faturamento, economia, clientes e vendas." />
      </>
    )
  },
  {
    selector: '.tour-account',
    content: () => (
      <>
        <Title text={<User size={20} color="#12214D" />} />
        <Body text="Permite alterar dados cadastrais como nome, email e senha." />
      </>
    )
  },
  {
    selector: '.tour-settings',
    content: () => (
      <>
        <Title text={<Settings size={20} color="#12214D" />} />
        <Body text="Customizar o layout alterando logo e as cores primárias e secundárias de textos e botões." />
      </>
    )
  },
  {
    selector: '.tour-customers',
    content: () => (
      <>
        <Title text={<Users size={20} color="#12214D" />} />
        <Body text="Visualizar informações dos clientes, como: a captação de clientes, o perfil demográfico e sua segmentação bem como listar os clientes e realizar buscas." />
      </>
    )
  },
  {
    selector: '.tour-orders',
    content: () => (
      <>
        <Title text={<Layers size={20} color="#12214D" />} />
        <Body text="Painel para acompanhar informações referentes aos pedidos, como: últimos pedidos, meios de pagamento, o seu perfil demográfico, além disso, ainda é possivel listar e realizar buscas dos pedidos." />
      </>
    )
  },
]

export default steps;
