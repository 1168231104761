import moment from "moment";
import { logoutRequest, getCollaborator } from "services/api/index";
import * as GeneralActions from "store/actions";

export const SET_HEADER_INFO = "[GLOBAL USER] SET HEADER INFO";
export const SET_USER_INFO = "[GLOBAL USER] SET USER INFO";
export const USER_LOGOUT = "[GLOBAL USER] USER LOGOUT";
export const SET_SETTINGS = "SET_SETTINGS";

export function setSettings(settings) {
  return (dispatch) => {
    dispatch({ type: SET_SETTINGS, settings });
  };
}

export function getCurrentUser() {
  return (dispatch) => {
    dispatch(setStateFromLocalStorage());
    getCollaborator().then((response) => {
      if (response && response.success) {
        dispatch({ type: SET_USER_INFO, payload: response.data });
      } else if (response && response.errors) {
        localStorage.removeItem("APU_header");
      }
    });
  };
}

export function userLogout() {
  return (dispatch) => {
    dispatch({ type: USER_LOGOUT });
  };
}

export function setStateFromLocalStorage() {
  return (dispatch) => {
    const headers = JSON.parse(localStorage.getItem("APU_header"));
    if (headers && headers.expiry < moment().unix()) {
      logoutRequest();
      localStorage.removeItem("APU_header");
      window.location.href = "/login";
    } else {
      dispatch({ type: SET_HEADER_INFO, payload: headers });
    }
  };
}

export function loadApplication() {
  return (dispatch) => {
    dispatch(GeneralActions.getCurrentUser());
  };
}

export const saveReducerToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("reduxState", serializedState);
  } catch (err) {
    console.error("Error saving state to localStorage:", err);
  }
};

export const loadReducerFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("reduxState");
    if (serializedState === null) {
      return undefined; // Return undefined if no state is found in localStorage
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error("Error loading state from localStorage:", err);
    return undefined;
  }
};
