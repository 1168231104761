import { Box, Card } from "@material-ui/core";
import SecondaryButton from "./SecondaryButton";
import Toolbar from "components/layout/Toolbar";

const PSFourthSection = () => {
  return (
    <Card sx={{ p: 2, mt: 2, width: "100%" }}>
      <Toolbar name="Botão secundário" root="settings" />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          height: 150,
          width: 500,
        }}
      >
        <Card
          sx={{
            width: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 4,
          }}
        >
          <SecondaryButton />
        </Card>
      </Box>
    </Card>
  );
};

export default PSFourthSection;
