import React from "react";
import { Card } from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  card: {
    position: "relative",
    padding: "1em 2em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "1.1em",
    borderRadius: "15px",
    minHeight: "396px",
    maxWidth: "500px",
    backgroundImage: (props) =>
      `url(${props.previewSettings?.notifications?.notifications_background_image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    backgroundSize: "100% 100%",
  },
  title: {
    fontFamily: "Open Sans",
    color: (props) => props.previewSettings.main_colors.secondary_color,
    textTransform: "uppercase",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: ".7em",
    position: "relative",
    fontSize: "3em",
    opacity: 0.2,

    fill: (props) => props.previewSettings.main_colors.secondary_color,
  },
  content: {
    padding: 0,
    margin: ".7em 0",
    fontFamily: "Open Sans",
    color: (props) =>
      props.previewSettings.notifications?.notifications_text_color,
  },
  link: {
    color: (props) => props.previewSettings.button_colors?.primary_text_color,
    fontWeight: 700,
    backgroundColor: (props) =>
      props.previewSettings?.button_colors?.primary_background_color,
    textDecoration: "none",
    padding: "15px 25px",
    borderRadius: 15,
    fontFamily: "Open Sans",
  },
}));

const NotificationModal = () => {
  const previewSettings = useSelector((state) => state.preview);
  const classes = useStyles({ previewSettings });

  return (
    <Card className={classes.card}>
      <div>
        <div></div>
        <div className={classes.title}>
          <svg
            className="MuiSvgIcon-root"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            fill="currentColor"
            style={{
              position: "absolute",
              fontSize: "3em",
              opacity: 0.2,
              width: 110,
              height: 110,
            }}
          >
            <path d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path>
          </svg>
          <span>Atenção</span>
        </div>
        <div className={classes.content}>
          Sua assinatura com validade em{" "}
          <strong
            style={{
              color: previewSettings.main_colors.secondary_color,
            }}
          >
            11/08/2023
          </strong>{" "}
          expirou. Acesse sua conta para verificar.
        </div>
        <div style={{ margin: "25px 0px" }}>
          <a href="#asd" className={classes.link}>
            MINHA CONTA
          </a>
        </div>
      </div>
    </Card>
  );
};

export default NotificationModal;
