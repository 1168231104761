import { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { Bar } from "react-chartjs-2";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    textTransform: "uppercase",
    "&:focus": {
      borderBottom: "0px !important",
    },
  },
  selectRoot: {
    //...other styles
    "&:focus": {
      backgroundColor: "yellow",
    },
  },
  select: {
    marginTop: "4px",
    fontWeight: "bold !important",
    fontSize: "12px !important",
    "&:before": {
      borderBottom: "0px !important",
    },
    "&:after": {
      borderBottom: "0px !important",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Graph = (props) => {
  moment().locale("pt-br");
  const {
    title,
    apiFunction,
    items,
    itemsExtra,
    itemsLabel,
    itemsLabelExtra,
    status,
    statusExtra,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [interval, setInterval] = useState(props.startNum || 15);
  const [barWidth, setBarWidth] = useState(itemsExtra ? 12 : 16);
  const [dataItems, setDataItems] = useState(items);
  const [dataItemsExtra, setDataItemsExtra] = useState(itemsExtra);
  const [lastDays, setLastDays] = useState(
    [...new Array(interval)]
      .map((i, idx) =>
        moment().utcOffset("+0000").startOf("day").subtract(idx, "days")
      )
      .reverse()
  );
  const [data, setData] = useState({
    datasets: [],
    labels: lastDays.map((day) => day.format("DD MMM")),
  });

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    dataset: {
      barThickness: 20,
      maxBarThickness: barWidth,
      barPercentage: 0.5,
      categoryPercentage: 0.5,
      ticks: {
        fontColor: theme.palette.text.secondary,
      },
      gridLines: {
        display: false,
        drawBorder: false,
      },
    },
    scales: {
      xAxes: [
        {
          barThickness: 10,
          maxBarThickness: barWidth,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  useEffect(() => {
    if (statusExtra) {
      apiFunction(interval, { status: status }).then((response) => {
        setLastDays(
          [...new Array(interval)]
            .map((i, idx) =>
              moment().utcOffset("+0000").startOf("day").subtract(idx, "days")
            )
            .reverse()
        );
        var array = [...new Array(interval)]
          .map((i, idx) =>
            moment().utcOffset("+0000").startOf("day").subtract(idx, "days")
          )
          .reverse()
          .map((day) => {
            let newArray = response.filter(function (el) {
              return (
                moment(el.day).utcOffset("+0000").format("YYYY-MM-DD") ===
                day.format("YYYY-MM-DD")
              );
            });
            if (newArray.length > 0) {
              return newArray[0].count;
            } else {
              return 0;
            }
          });
        setDataItems(array);
      });

      apiFunction(interval, { status: statusExtra }).then((response) => {
        setLastDays(
          [...new Array(interval)]
            .map((i, idx) =>
              moment().utcOffset("+0000").startOf("day").subtract(idx, "days")
            )
            .reverse()
        );
        var array = [...new Array(interval)]
          .map((i, idx) =>
            moment().utcOffset("+0000").startOf("day").subtract(idx, "days")
          )
          .reverse()
          .map((day) => {
            let newArray = response.filter(function (el) {
              return (
                moment(el.day).utcOffset("+0000").format("YYYY-MM-DD") ===
                day.format("YYYY-MM-DD")
              );
            });
            if (newArray.length > 0) {
              return newArray[0].count;
            } else {
              return 0;
            }
          });
        setDataItemsExtra(array);
      });
    } else {
      apiFunction(interval, { status: status }).then((response) => {
        setLastDays(
          [...new Array(interval)]
            .map((i, idx) =>
              moment().utcOffset("+0000").startOf("day").subtract(idx, "days")
            )
            .reverse()
        );
        var array = [...new Array(interval)]
          .map((i, idx) =>
            moment().utcOffset("+0000").startOf("day").subtract(idx, "days")
          )
          .reverse()
          .map((day) => {
            let newArray = response.filter(function (el) {
              return (
                moment(el.day).utcOffset("+0000").format("YYYY-MM-DD") ===
                day.format("YYYY-MM-DD")
              );
            });
            if (newArray.length > 0) {
              return newArray[0].count;
            } else {
              return 0;
            }
          });
        setDataItems(array);
      });
    }
  }, [interval, apiFunction, status, statusExtra]);

  useEffect(() => {
    if (statusExtra) {
      setData({
        datasets: [
          {
            backgroundColor: colors.indigo[500],
            data: dataItems,
            label: itemsLabel,
          },
          {
            backgroundColor: colors.grey[500],
            data: dataItemsExtra,
            label: itemsLabelExtra,
          },
        ],
        labels: lastDays.map((day) => day.format("DD MMM")),
      });
    } else {
      setData({
        datasets: [
          {
            backgroundColor: colors.indigo[500],
            data: dataItems,
            label: itemsLabel,
          },
        ],
        labels: lastDays.map((day) => day.format("DD MMM")),
      });
    }
  }, [
    dataItems,
    dataItemsExtra,
    itemsLabel,
    itemsLabelExtra,
    lastDays,
    statusExtra,
  ]);

  const handleChange = (event) => {
    setInterval(event.target.value);
    switch (event.target.value) {
      case 60:
        setBarWidth(itemsExtra ? 5 : 7);
        break;
      case 30:
        setBarWidth(itemsExtra ? 10 : 14);
        break;
      default:
        setBarWidth(itemsExtra ? 12 : 16);
        break;
    }
  };

  return !data ? (
    <Card sx={{ height: "100%" }}>
      <Box justifyContent="space-between" display="flex" width="100%" p={2}>
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
      </Box>
      <Divider />
      <CardContent>
        <Box
          sx={{
            minHeight: 280,
            position: "relative",
          }}
        ></Box>
        <Skeleton height={"100%"} />
      </CardContent>
    </Card>
  ) : (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        action={
          <>
            <FormControl variant="standard" className={classes.formControl}>
              <Select
                id="interval"
                value={interval}
                onChange={handleChange}
                className={classes.select}
              >
                <MenuItem value={15}>Últimos 15 dias</MenuItem>
                <MenuItem value={30}>Últimos 30 dias</MenuItem>
                <MenuItem value={60}>Últimos 60 dias</MenuItem>
              </Select>
            </FormControl>
          </>
        }
        title={title}
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            minHeight: 280,
            position: "relative",
          }}
        >
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default Graph;
