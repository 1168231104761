import React from "react";
import SearchIcon from "./images/search-icon.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  searchBar: {
    display: "flex",
    backgroundColor: "#ececee",
    width: "720px",
    height: 38,
    borderRadius: "61px",
    paddingLeft: "2em",
    paddingRight: "2em",
    marginBottom: "1em",
  },
  inputContainer: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    width: "100%",
  },
  input: {
    backgroundColor: "#ececee",
    border: "none",
    width: "95%",
  },
  button: {
    backgroundColor: "#ececee",
    border: "none",
  },
  searchIcon: {
    height: "20px",
    width: "20px",
  },
}));

const SearchBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.searchBar}>
        <div className={classes.inputContainer}>
          <input
            type="text"
            placeholder="Digite aqui o que você procura..."
            className={classes.input}
          />
          <div>
            <button
              aria-label="buscas"
              type="button"
              id="button-addon2"
              className={classes.button}
            >
              <img
                alt="Botao Busca"
                src={SearchIcon}
                className={classes.searchIcon}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
