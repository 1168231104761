import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import "moment/locale/pt-br";

import { Box, Container, Grid } from "@material-ui/core";
import scrollTo from "utils/scrollTo";
// import LatestProducts from "components/dashboard/LatestProducts";
import Graph from "../components/widgets/Graph";
import Customers from "components/dashboard/Customers";
import Access from "components/dashboard/Access";
import EconomyCustomers from "components/dashboard/EconomyCustomers";

import { getOrdersLatest } from "services/api/index";
import GraphSkeleton from "components/widgets/GraphSkeleton";

const Dashboard = () => {
  const [latestSales, setLatestSales] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollTo("#topPage");
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    scrollTo("#topPage");

    getOrdersLatest(60, { status: "delivered" }).then((response) => {
      var array = [...new Array(60)]
        .map((i, idx) => moment().startOf("day").subtract(idx, "days"))
        .reverse()
        .map((day) => {
          let ordersArray = response.filter(function (el) {
            return (
              moment(el.day).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")
            );
          });

          if (ordersArray.length > 0) {
            return ordersArray[0].count;
          } else {
            return 0;
          }
        });
      setLatestSales(array);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard | +Alegria</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
        id="topPage"
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={4} sm={12} xl={4} xs={12}>
              <Access />
            </Grid>
            <Grid item lg={4} sm={12} xl={4} xs={12}>
              <EconomyCustomers />
            </Grid>
            <Grid item lg={4} sm={12} xl={4} xs={12}>
              <Customers />
            </Grid>
            <Grid item lg={12} xs={12}>
              {!latestSales ? (
                <GraphSkeleton />
              ) : (
                <Graph
                  title="Últimas vendas"
                  itemsLabel="Pedidos entregues"
                  items={latestSales}
                  apiFunction={getOrdersLatest}
                  status="delivered"
                  startNum={60}
                />
              )}
            </Grid>
            {/* <Grid item lg={6} xs={12}>
              <LatestProducts sx={{ height: "100%" }} />
            </Grid> */}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
