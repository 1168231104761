import { experimentalStyled } from "@material-ui/core";

export const ListWrapper = experimentalStyled("ul")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  listStyleType: "none",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}))

export const ListItem = experimentalStyled("li")(({ theme }) => ({
  boxShadow: "1px 4px 13px 6px #0000001A",
  borderRadius: "1em",
  height: "fit-content",
  width: "23%",
  [theme.breakpoints.down("md")]: {
    margin: "1em",
    width: "60%",
  },
}))

export const PlanTtitle = experimentalStyled("p")(() => ({
  background: "linear-gradient(152.09deg, #282362 19.58%, #5B55A4 86.77%)",
  borderTopLeftRadius: ".8em",
  borderTopRightRadius: ".8em",
  color: "#fff",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "24px",
  textAlign: "center",
  padding: ".6em 0",
}))

export const AmountContainer = experimentalStyled("div")(() => ({
  borderBottom: "1px solid #D9D9D9",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: ".8em 0",
}))

export const TextContainer = experimentalStyled("p")(() => ({
  color: "#636363",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "14px",
  textAlign: "center",
  width: "90%",
}))

export const HightLinthTextContainer = experimentalStyled("span")(({ primary }) => ({
  color: `${ primary ? "#F99232" : "#282362" }`,
  fontSize: "32px",
  fontWeight: "bold",
}))

export const PricePlan = experimentalStyled("p")(() => ({
  borderBottom: "1px solid #D9D9D9",
  color: "#282362",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "14px",
  textAlign: "center",
  lineHeight: ".8em",
  padding: "1.4em 1em",
  margin: "0 auto",
  width: "90%",
}))

export const HightLightPricePlan = experimentalStyled("span")(() => ({
  fontSize: "24px",
  fontWeight: "bold",
}))

export const TextPlan = experimentalStyled("p")(() => ({
  borderBottom: "1px solid #D9D9D9",
  color: "#636363",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "14px",
  textAlign: "center",
  padding: "1.4em 0",
  margin: "0 auto",
  width: "90%",
}))

export const HightLightTextPlan = experimentalStyled("span")(() => ({
  fontWeight: "bold",
}))

export const EmpytIconContainer = experimentalStyled("div")(() => ({
  borderBottom: "1px solid #D9D9D9",
  textAlign: "center",
  padding: "1.4em 0",
  margin: "0 auto",
  width: "90%",
}))

export const PremiunTextHighLight = experimentalStyled("p")(({ withoutborder }) => ({
  borderBottom: `${withoutborder ? "none": "1px solid #D9D9D9" }`,
  color: "#282362",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "14px",
  fontWeight: "bold",
  padding: `${withoutborder ? "" : "1.4em 0"}` ,
  margin: "auto",
  textAlign: "center",
  width: "90%", 
}))

export const PlusIcon = experimentalStyled("p")(() => ({
  color: "#F99232",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontWeight: "bold",
  fontSize: "32px",
}))

export const Button = experimentalStyled("button")(({ isSelected }) => ({
  background: `${isSelected ? "#282362" : "#F99232" }`,
  border: "none",
  borderRadius: ".5em",
  display: "flex",
  color: "#FFFFFF",
  fontSize: "18px",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  justifyContent: "center",
  padding: ".5em 0",
  margin: "auto",
  marginBottom: "1em",
  width: "94%",
}))
