import "moment/locale/pt-br";

import { Box, Card, CardContent, Divider } from "@material-ui/core";

import { Skeleton } from "@material-ui/lab";

const GraphSkeleton = () => {
  return (
    <Card sx={{ height: "100%" }}>
      <Box justifyContent="space-between" display="flex" width="100%" p={2}>
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
      </Box>
      <Divider />
      <CardContent>
        <Box
          sx={{
            minHeight: 280,
            position: "relative",
          }}
        ></Box>
        <Skeleton height={"100%"} />
      </CardContent>
    </Card>
  );
};

export default GraphSkeleton;
