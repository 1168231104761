import { experimentalStyled } from "@material-ui/core";

const Container = experimentalStyled("div")(({ theme }) => ({
  background: "#fff",
  boxShadow: "2px 6px 16px 16px #0000001A",
  borderRadius: "1em",
  position: "absolute",
  left: "38%",
  top: "38%",
  height: "210px",
  width: "560px",
  zIndex: "1",
  [theme.breakpoints.down("md")]: {
    left: "4%",
    top: "35%",
    width: "460px",
  },
}))

const Title = experimentalStyled("h2")(() => ({
  color: "#282362",
  fontWeight: "bold",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  textAlign: "center",
  padding: "1em 0",
}))

const Text = experimentalStyled("p")(() => ({
  color: "#282362",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "16px",
  textAlign: "center",
  margin: "auto",
  width: "56%",
}))

const Button = experimentalStyled("button")(() => ({
  background: "#282362",
  border: "none",
  borderRadius: ".5em",
  display: "flex",
  color: "#FFFFFF",
  fontSize: "18px",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontWeight: "bold",
  justifyContent: "center",
  padding: ".6em 0",
  margin: "auto",
  marginTop: "1em",
  width: "54%",
}))

const PlanModal = ({open}) => {
  return (
    <Container>
      <Title>Solicitação recebida!</Title>
      <Text>Entraremos em contanto em breve para tirar todas as suas dúvidas sobre os planos.</Text>
      <Button onClick={() => open(false)}>OK</Button>
    </Container>
  )
}

export default PlanModal
