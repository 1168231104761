import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Drawer, List, Divider } from "@material-ui/core";
import {
  BarChart,
  User,
  Users,
  DollarSign,
  Layers,
  LogOut,
  Settings,
  Edit,
  HelpCircle,
} from "react-feather";
import NavItem from "./NavItem";
import { useSettings } from "hooks/useSettings";
import { useSelector } from "react-redux";
import TourNavigation from "components/tourNavigation";
import steps from "components/tourNavigation/tour-setps";

const logout = [
  {
    href: "/logout",
    icon: LogOut,
    title: "Sair",
    className: "tour-logout",
  },
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { isSettings } = useSettings();
  const user = useSelector((state) => state.user);

  const dashboard = [
    {
      href: "/reports",
      icon: BarChart,
      title: "Relatórios",
      className: "tour-dashboard",
    },
    {
      href: "/account",
      icon: User,
      title: "Minha Conta",
      className: "tour-account",
    },
  ];

  if (user?.plan?.settings?.my_plan) {
    dashboard.push({
      href: "/plans",
      icon: Edit,
      title: "Meu Plano",
    });
  }

  if (user?.plan?.settings?.configurations) {
    dashboard.push({
      href: "/settings",
      icon: Settings,
      title: "Configurações",
      className: "tour-settings",
    });
  }

  const reports = [];

  if (user?.plan?.settings?.manage_users) {
    reports.push({
      href: "/customers",
      icon: Users,
      title: "Usuários",
    });
  }

  if (user?.plan?.settings?.orders) {
    reports.push({
      href: "/orders",
      icon: Layers,
      title: "Pedidos",
      className: "tour-orders",
    });
  }

  if (user?.plan?.settings?.billing) {
    reports.push({
      href: "/invoices",
      icon: DollarSign,
      title: "Faturamento",
    });
  }

  if (user?.plan?.settings?.quizzes) {
    reports.push({
      href: "/quizzes",
      icon: HelpCircle,
      title: "Quizzes",
    });
  }

  if (user?.plan?.settings?.surveys) {
    reports.push({
      href: "/surveys",
      icon: Edit,
      title: "Pesquisas",
    });
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const mobileDrawerStyle = () => {
    if (isSettings) return { xs: "block", md: "block", lg: "block" };
    return { xs: "block", md: "none", lg: "none" };
  };

  const drawerStyle = () => {
    if (isSettings) return { xs: "none", md: "none", lg: "none" };
    return { xs: "none", md: "block", lg: "block" };
  };

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <TourNavigation steps={steps} />
      <Box sx={{ p: 2 }}>
        <List>
          {dashboard.map((item, i) => (
            <NavItem
              href={item.href}
              key={i}
              title={item.title}
              icon={item.icon}
              className={item.className}
            />
          ))}
        </List>
        <Divider />
        <List>
          {reports.map((item, i) => (
            <NavItem
              href={item.href}
              key={i}
              title={item.title}
              icon={item.icon}
              className={item.className}
            />
          ))}
        </List>
        <Divider />
        <List>
          {logout.map((item, i) => (
            <NavItem
              href={item.href}
              key={i}
              title={item.title}
              icon={item.icon}
              className={item.className}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Box display={mobileDrawerStyle}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
      <Box display={drawerStyle}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: isSettings ? 0 : 256,
              top: 82,
              height: "calc(100% - 64px)",
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
