import { Card } from "@material-ui/core";
import NotificationModal from "./NotificationModal";
import Toolbar from "components/layout/Toolbar";

const PSThirdSection = () => {
  return (
    <Card sx={{ p: 2, mt: 2, width: "100%" }}>
      <Toolbar name="Notificações" root="settings" />
      <NotificationModal />
    </Card>
  );
};

export default PSThirdSection;
