import { useEffect, useState } from "react";

const { useLocation } = require("react-router");

export const useSettings = () => {
  const [isSettings, setIsSettings] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/settings") {
      setIsSettings(true);
    } else {
      setIsSettings(false);
    }
  }, [location]);

  return { isSettings };
};
