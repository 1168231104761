import { useState, useEffect } from "react";
import moment from "moment";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    textTransform: "uppercase",
    "&:focus": {
      borderBottom: "0px !important",
    },
  },
  selectRoot: {
    //...other styles
    "&:focus": {
      backgroundColor: "yellow",
    },
  },
  select: {
    marginTop: "4px",
    fontWeight: "bold !important",
    fontSize: "12px !important",
    "&:before": {
      borderBottom: "0px !important",
    },
    "&:after": {
      borderBottom: "0px !important",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Summary = (props) => {
  moment().locale("pt-br");
  const {
    icon,
    title,
    hasFilter,
    items,
    apiFunction,
    parentId,
    labelTag,
    valueTag,
    avatarSize,
    spacing,
  } = props;
  const classes = useStyles();
  const [interval, setInterval] = useState(15);
  const [dataItems, setDataItems] = useState(items);

  const handleChange = (event) => {
    setInterval(event.target.value);
  };

  useEffect(() => {
    var parameter = interval;

    if (parentId) {
      parameter = parentId;
    }
    if (hasFilter || parentId) {
      apiFunction(parameter).then((response) => {
        var data = [];
        var newData = [];

        for (let item in response) {
          data.push({ originalLabel: item, value: response[item] });
        }

        for (let item of data) {
          var array = items.filter((el) => {
            return el.originalLabel === item.originalLabel;
          });
          if (array[0]) {
            newData.push({
              originalLabel: item.originalLabel,
              label: array[0].label,
              value: item.value,
              icon: array[0].icon,
              color: array[0].color,
            });
          }
        }

        setDataItems(newData);
      });
    }
  }, [interval, apiFunction, hasFilter, icon, items, parentId]);

  return (
    <>
      <Card sx={{ height: "100%" }}>
        {hasFilter ? (
          <CardHeader
            title={title}
            action={
              <>
                <FormControl variant="standard" className={classes.formControl}>
                  <Select
                    id="interval"
                    value={interval}
                    onChange={handleChange}
                    className={classes.select}
                  >
                    <MenuItem value={15}>Últimos 15 dias</MenuItem>
                    <MenuItem value={30}>Últimos 30 dias</MenuItem>
                    <MenuItem value={60}>Últimos 60 dias</MenuItem>
                  </Select>
                </FormControl>
              </>
            }
          />
        ) : (
          <CardHeader title={title} />
        )}
        <Divider />
        <CardContent>
          {dataItems.length > 0 ? (
            dataItems.map((item, i) => (
              <Grid key={i}>
                <Grid
                  key={i}
                  container
                  spacing={2}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Grid item>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant={labelTag}
                      sx={{ pb: 1 }}
                    >
                      {item.label}
                    </Typography>

                    <Typography color="textPrimary" variant={valueTag}>
                      {item.value}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar
                      sx={{
                        backgroundColor: item.color,
                        height: avatarSize,
                        width: avatarSize,
                      }}
                    >
                      {item.icon}
                    </Avatar>
                  </Grid>
                </Grid>
                {items.length !== i + 1 && (
                  <Divider sx={{ mb: spacing, mt: spacing }} />
                )}
              </Grid>
            ))
          ) : (
            <Typography color="textPrimary" variant="body2">
              Nenhum dado disponível
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default Summary;
