import { useState, useEffect } from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import scrollTo from "utils/scrollTo";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Snackbar,
  CardContent,
  TextField,
  Button,
  Typography,
  Grid,
  Skeleton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { getQuizAnswers, exportResultsQuiz } from "services/api/index";
import { Download } from "react-feather";

const QuizAnswerList = ({ quiz, benefitTiers, ...rest }) => {
  const navigate = useNavigate();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const { id } = useParams();
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(0);
  const [answerList, setAnswerList] = useState(null);
  const [filters, setFilters] = useState({
    name: "",
    benefit_tier: "",
    finished: "",
  });
  const [showSuccess, setShowSuccess] = useState(Boolean(query.get("success")));
  const [showFilters, setShowFilters] = useState(false);
  const [btnFilterLabel, setBtnFilterLabel] = useState("Exibir filtros");

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
    scrollTo("#topTable");
  };

  const handleClick = (id) => {
    if (id["$oid"]) {
      id = id["$oid"];
    }

    navigate(`/quizzes/answers/${id}`);
  };

  const handleCloseAlert = () => {
    setShowSuccess(false);
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
    showFilters
      ? setBtnFilterLabel("Exibir filtros")
      : setBtnFilterLabel("Ocultar filtros");
  };

  const handleClickFilters = () => {
    setAnswerList(null);
    getQuizAnswers(id, page + 1, 10, filters).then((response) => {
      setAnswerList(response.quiz_user_answers);
      setTotal(response.pagination.total);
      setLimit(10);
    });
  };

  const handleClearFilters = () => {
    setAnswerList(null);

    setFilters({ name: "", benefit_tier: "", finished: "" });
    handleShowFilters();
    getQuizAnswers(id, page + 1, 10).then((response) => {
      setAnswerList(response.quiz_user_answers);
      setTotal(response.pagination.total);
      setLimit(10);
    });
  };

  useEffect(() => {
    setAnswerList(null);
    getQuizAnswers(id, page + 1, 10)
      .then((response) => {
        setAnswerList(response.quiz_user_answers);
        setTotal(response.pagination.total);
        setLimit(10);
      })
      .catch((err) => {
        setAnswerList([]);
      });
  }, [page, id]);

  const handleChange = (event) => {
    setFilters((filters) => ({
      ...filters,
      [`${event.target.name}`]: `${event.target.value}`,
    }));
  };

  const exportData = (id) => {
    exportResultsQuiz(id);
  };

  return (
    <Card {...rest} id="topTable">
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Typography color="textSecondary" variant="h4">
                Respostas
                <Button
                  color="primary"
                  variant={showFilters ? "outlined" : "contained"}
                  type="button"
                  onClick={handleShowFilters}
                  sx={{ marginLeft: "20px" }}
                >
                  {btnFilterLabel}
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Button
                color="primary"
                variant="outlined"
                disabled={false}
                type="button"
                onClick={() => exportData(quiz.id)}
                sx={{
                  mx: 0.5,
                }}
                title="Exportar Resultados"
              >
                <Download />
              </Button>
            </Grid>
          </Grid>
          {showFilters && (
            <Box>
              <Grid container spacing={3} pt={3}>
                <Grid item lg={3} md={6} sm={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    label="Pesquisar pelo nome"
                    name="name"
                    value={filters.name}
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={12}>
                  <TextField
                    fullWidth
                    label="Pesquisar pelo Segmento"
                    name="benefit_tier"
                    onChange={handleChange}
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    value={filters.benefit_tier}
                    variant="outlined"
                  >
                    <option value="">Todos</option>
                    {benefitTiers.map((benefitTier) => (
                      <option value={benefitTier.name}>
                        {benefitTier.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={3} md={6} sm={12}>
                  <TextField
                    fullWidth
                    label="Finalizado?"
                    name="finished"
                    onChange={handleChange}
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    value={filters.finished}
                    variant="outlined"
                  >
                    <option value="">Todos</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </TextField>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={6}
                  sm={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    type="button"
                    onClick={handleClearFilters}
                    sx={{ marginRight: "5px" }}
                  >
                    Limpar
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={handleClickFilters}
                  >
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 925 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="center">Respondido em</TableCell>
                <TableCell align="center">Finalizado</TableCell>
                <TableCell align="center">Pontuação</TableCell>
                {quiz.has_prize && (
                  <TableCell align="center">Vale-presente</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!answerList ? (
                <AnswerListSkeleton />
              ) : (
                <>
                  {answerList?.slice(0, limit).map((answer, i) => (
                    <TableRow
                      hover
                      key={i}
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleClick(answer.id)}
                    >
                      <TableCell style={{ wordBreak: "break-all" }}>
                        {answer.user_name}
                      </TableCell>
                      <TableCell align="center">
                        {moment(answer.created_at).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {answer.finished ? "Sim" : "Não"}
                      </TableCell>
                      <TableCell align="center">{answer.points}</TableCell>

                      {quiz.has_prize && (
                        <TableCell align="center">
                          {answer.gift_voucher_token ?? "-"}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
        labelRowsPerPage={"Respostas por página"}
        labelDisplayedRows={({ from, to, count }) => {
          return "" + from + "-" + to + " de " + count;
        }}
      />
      {showSuccess && (
        <Snackbar
          open={showSuccess}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert severity="success">Quiz atualizado com sucesso!</Alert>
        </Snackbar>
      )}
    </Card>
  );
};

export default QuizAnswerList;

const AnswerListSkeleton = () => {
  // eslint-disable-next-line no-unused-vars
  const [answerList, setAnswerList] = useState(() => {
    const array = Array.from({ length: 10 }, (_, i) => i + 1);

    return array;
  });
  return (
    <>
      {answerList.map((_, i) => (
        <TableRow hover sx={{ cursor: "pointer" }} key={i}>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="text" />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
