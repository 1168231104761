import "moment/locale/pt-br";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import { Skeleton } from "@material-ui/lab";

const DashboardSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item width="60%">
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h4"
              sx={{ borderBottom: "1px solid #F4F4F4", pb: 1 }}
            >
              <Skeleton height="30px" width={"70%"} />
            </Typography>
            <Typography color="textPrimary" variant="h5">
              <Skeleton height="30px" width={"80%"} />
            </Typography>

            <Typography color="textSecondary" variant="caption">
              <Skeleton height="30px" width={"100%"} />
            </Typography>
            <Typography color="textPrimary" variant="h4" sx={{ mt: 1 }}>
              <Skeleton height="30px" width={"100%"} />
            </Typography>
          </Grid>
          <Grid item>
            <Skeleton height={56} width={56} variant="circle" />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DashboardSkeleton;
