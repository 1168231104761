import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import scrollTo from "utils/scrollTo";

import { userLogin } from "store/actions";
import { loginRequest } from "services/api/index";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [requestErrors, setRequestErrors] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollTo("#topPage");
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (Array.isArray(requestErrors) && requestErrors.length > 0) {
      setShowError(true);
    }
  }, [requestErrors]);

  const handleCloseAlert = () => {
    setShowAlert(false);
    setShowError(false);
  };

  const onSubmit = async (collaborator) => {
    setIsSubmitting(true);
    loginRequest({
      email: collaborator.email,
      password: collaborator.password,
    })
      .then((response) => {
        if (response.success) {
          dispatch(userLogin(response));
          setShowAlert(true);
          setTimeout(() => {
            navigate("/dashboard", { replace: true });
          }, 2000);
        } else {
          if (response.errors) {
            setRequestErrors(response.errors);
            setIsSubmitting(false);
          } else {
            setRequestErrors([response.error]);
            setIsSubmitting(false);
          }
        }
      })
      .catch((err) => {
        setRequestErrors(["Erro ao se conectar com o servidor"]);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Entrar | +Alegria</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
        id="topPage"
      >
        <Container maxWidth="sm">
          <Formik
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Deve ser um e-mail válido")
                .max(255)
                .required("O e-mail é obrigatório"),
              password: Yup.string().max(255).required("A senha é obrigatória"),
            })}
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(collaborator) => {
              onSubmit(collaborator);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Entrar
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Acesse a nossa plataforma
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="E-mail"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Senha"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Entrar
                  </Button>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                    align="center"
                    sx={{ py: 1 }}
                  >
                    <Link to="/forgot">Esqueci minha senha</Link>
                  </Typography>
                </Box>
                {showAlert && (
                  <Snackbar
                    open={showAlert}
                    autoHideDuration={3000}
                    onClose={handleCloseAlert}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  >
                    <Alert severity="success">
                      {requestErrors && "Você logou com sucesso."}
                    </Alert>
                  </Snackbar>
                )}
                {showError && (
                  <Snackbar
                    open={showError}
                    autoHideDuration={3000}
                    onClose={handleCloseAlert}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  >
                    <Alert severity="error">
                      {requestErrors && (
                        <ul style={{ listStyleType: "none" }}>
                          {requestErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                          ))}
                        </ul>
                      )}
                    </Alert>
                  </Snackbar>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
