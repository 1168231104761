import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import Papa from "papaparse";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardHeader,
  TextField,
  Button,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";

import { createUserImport } from "services/api/index";

const CustomerMassiveActions = (props) => {
  const [customersList, setCustomersList] = useState(null);
  const [selectedFile, setSelectedFile] = useState(false);
  const [importName, setImportName] = useState(false);
  const [importType, setImportType] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.set("name", importName);
    formData.set("type", importType);
    formData.append("_file", selectedFile);

    if (selectedFile) {
      createUserImport(formData).then((response) => {
        if (response.status === "Criado") {
          props.setShowSuccess(true);
          props.setSuccessMessage("Importação criada com sucesso!");
          props.setShowImportNew(!props.showImportNew);
          setSelectedFile(false);
          setImportName(false);
          setImportType(false);
        }
      });
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <CardHeader title="Importações" sx={{ padding: "10px" }}></CardHeader>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <form onSubmit={handleSubmit}>
        <Grid container wrap="wrap" spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Typography
              color="textSecondary"
              variant="body2"
              sx={{ mb: 2, textAlign: "right" }}
            >
              Verifique o tipo de importação e faça download do modelo de
              arquivo:
              <br />
              <a
                href="/assets/reserva_template.csv"
                style={{
                  color: "#12214d",
                  fontWeight: "bold",
                }}
              >
                Reserva de usuário
              </a>
              <br />
              <a
                href="/assets/bloqueio_desbloqueio_template.csv"
                style={{
                  color: "#12214d",
                  fontWeight: "bold",
                }}
              >
                Bloqueio/desbloqueio de usuário
              </a>{" "}
            </Typography>
            <Box
              sx={{
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontSize: "14px",
              }}
            >
              <Grid container spacing={3} pt={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Nome"
                    name="name"
                    onChange={(event) => {
                      setImportName(event.target.value);
                    }}
                    required
                    value={importName ? importName : ""}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Tipo de importação"
                    name="mass_import_type"
                    onChange={(event) => {
                      setImportType(event.target.value);
                    }}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={importType ? importType : ""}
                    variant="outlined"
                  >
                    <option value=""></option>
                    <option value="user_reservation">Reserva de Usuário</option>
                    <option value="unlock_user">
                      Bloqueio/Desbloqueio de Usuário
                    </option>
                  </TextField>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    name="_file"
                    type="file"
                    onChange={(event) => {
                      setSelectedFile(event.currentTarget.files[0]);
                      if (event.currentTarget.files[0]) {
                        Papa.parse(event.currentTarget.files[0], {
                          preview: 10,
                          complete: function (results) {
                            setCustomersList(results.data);
                          },
                        });
                      } else {
                        setCustomersList(null);
                      }
                    }}
                    required
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {customersList && (
          <>
            <Box sx={{ mt: 4, mb: 2 }}>
              <Typography color="textSecondary" variant="h4">
                Lista de Usuários
              </Typography>
            </Box>
            <PerfectScrollbar>
              <Box sx={{ minWidth: 960 }}>
                <Table>
                  <TableHead>
                    {importType === "user_reservation" ? (
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>E-mail</TableCell>
                        <TableCell>CPF</TableCell>
                        <TableCell>Telefone</TableCell>
                        <TableCell>Nascimento</TableCell>
                        <TableCell>Cidade</TableCell>
                        <TableCell>Estado</TableCell>
                        <TableCell>Gênero</TableCell>
                        <TableCell>Segmento</TableCell>
                        <TableCell>Cod. Acesso</TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell>CPF</TableCell>
                        <TableCell>Ação</TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {customersList.map(
                      (customer, i) =>
                        i > 0 && (
                          <TableRow hover key={i} sx={{ cursor: "pointer" }}>
                            {importType === "user_reservation" ? (
                              <>
                                <TableCell>
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {customer[1]}
                                  </Box>
                                </TableCell>
                                <TableCell>{customer[0]}</TableCell>
                                <TableCell>{customer[2]}</TableCell>
                                <TableCell>{customer[3]}</TableCell>
                                <TableCell>{customer[4]}</TableCell>
                                <TableCell>{customer[5]}</TableCell>
                                <TableCell>{customer[6]}</TableCell>
                                <TableCell>{customer[7]}</TableCell>
                                <TableCell>{customer[8]}</TableCell>
                                <TableCell>{customer[9]}</TableCell>
                              </>
                            ) : (
                              <>
                                <TableCell>{customer[0]}</TableCell>
                                <TableCell>{customer[1]}</TableCell>
                              </>
                            )}
                          </TableRow>
                        )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </>
        )}
        <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
          {customersList && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              sx={{ marginLeft: "20px" }}
            >
              Importar
            </Button>
          )}
          <Button
            color="primary"
            variant="outlined"
            type="button"
            onClick={() => props.setShowImportNew(!props.showImportNew)}
            sx={{ marginLeft: "5px" }}
          >
            Cancelar
          </Button>
        </Box>
      </form>
    </>
  );
};

export default CustomerMassiveActions;
