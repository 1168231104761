import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    height: "150px",
    width: "100%",
    textAlign: "center",
    overflow: "hidden",

    zIndex: 1,
  },
  contentContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontFamily: "Open Sans",
    fontWeight: 700,
    fontSize: "24px",
    marginBottom: "0px",
    color: (props) => props.primaryColor,
  },
  formContainer: {
    display: "flex",
    flexFlow: "row",
    width: "unset",
  },
  emailInput: {
    borderRadius: "30px",
    width: "20em",
    border: "0px",
    padding: "0px 10px 0px 10px",
    lineHeight: "30px",
    height: "30px",
    marginRight: "1em",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontSize: "12px",
  },
  submitButton: {
    backgroundColor: (props) => props.primaryColor,
    color: "rgb(255, 255, 255)",
    borderRadius: "10em",
    paddingLeft: "1em",
    paddingRight: "1em",
    border: "0px",
    fontFamily: "Open Sans",
    fontSize: 12,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const Newsletter = () => {
  const previewSettings = useSelector((state) => state.preview);
  const classes = useStyles({
    primaryColor: previewSettings.main_colors.primary_color,
    secondaryColor: previewSettings.main_colors.secondary_color,
    newsletterBackground: previewSettings.images.secondary_image_footer,
  });

  return (
    <div
      className={classes.container}
      style={{
        background: previewSettings?.images?.secondary_image_footer
          ? `url(${previewSettings?.images?.secondary_image_footer})`
          : previewSettings?.main_colors?.secondary_color,
      }}
    >
      <div className={classes.contentContainer}>
        <p className={classes.title}>Fique por dentro das Novidades</p>
        <form className={classes.formContainer}>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Insira seu e-mail aqui"
              className={classes.emailInput}
            />
          </div>
          <button
            aria-label="cadastrar"
            className={classes.submitButton}
            type="submit"
          >
            Cadastrar
          </button>
        </form>
        <div></div>
      </div>
    </div>
  );
};

export default Newsletter;
