import { useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Container, Grid } from "@material-ui/core";
import Toolbar from "components/layout/Toolbar";
import PlanList from "components/plansList/plansList";
import PlanModal from "components/plansList/planModal";

const Plans = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Helmet>
        <title>Meu Plano | +Alegria</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar name="Meu Plano" />
          <Grid container spacing={3} sx={{ pt: 3 }}>
            <Grid item lg={12} md={12} xs={12}>
              { openModal && <PlanModal open={setOpenModal}/>}
              <PlanList 
                planList={planList} 
                open={setOpenModal}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

const planList = [
  {
    title: 'Basic',
    amount_user: '1.000',
    price: '1.000',
    customer: '100% de customização da plataforma <br />(Identidade visual e logo)',
    email_marketing: null,
    crm_mananger: null,
    curation_by_segment: null
  },
  {
    title: 'Plus',
    amount_user: '5.000',
    price: '2.500',
    customer: '100% de customização da plataforma <br />(Identidade visual e logo)',
    email_marketing: 'Disparo de e-mail marketing com a logo do cliente',
    email_marketing_amout: '01',
    crm_mananger: null,
    curation_by_segment: null
  },
  {
    title: 'Advanced',
    amount_user: '10.000',
    price: '4.500',
    customer: '100% de customização da plataforma <br />(Identidade visual e logo)',
    email_marketing: 'Disparo de e-mail marketing com a logo do cliente',
    email_marketing_amout: '04',
    crm_mananger: 'Gestão de CRM',
    curation_by_segment: 'Curadoria de acordo com segmento e necessidade do parceiro'
  },
]

export default Plans
