const scrollTo = (anchor) => {
  const timer = setTimeout(() => {
    if (document.querySelector(anchor)) {
      document.querySelector(anchor).scrollIntoView({
        behavior: "smooth",
      });
    }
  }, 100);
  return () => clearTimeout(timer);
};

export default scrollTo;
