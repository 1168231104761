import { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardHeader,
  Button,
  Grid,
  Divider,
  CircularProgress,
} from "@material-ui/core";

import { getUserImport } from "services/api/index";

const CustomerMassiveActions = (props) => {
  const [importData, setImportData] = useState(false);

  useEffect(() => {
    getUserImport(props.id).then((response) => {
      setImportData(response);
    });
  }, [props]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <CardHeader title="Importações" sx={{ padding: "10px" }}></CardHeader>
        <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
          <Button
            color="primary"
            variant="outlined"
            type="button"
            onClick={() => props.setShowImport(!props.showImport)}
            sx={{ marginLeft: "20px" }}
          >
            Voltar
          </Button>
        </Box>
      </Box>
      <Divider sx={{ mb: 2 }} />
      {importData ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Table border={1} bordercolor="#F4F6F8">
              <TableBody>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    Nome
                  </TableCell>
                  <TableCell>{importData.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    Tipo
                  </TableCell>
                  <TableCell>{importData.type}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    Status
                  </TableCell>
                  <TableCell>{importData.status}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    Criado em
                  </TableCell>
                  <TableCell>{importData.created_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    Log
                  </TableCell>
                  <TableCell>
                    {importData.result_message
                      ? importData.result_message
                          .split("          ")
                          .map((line, i) => (
                            <span key={i}>
                              {line}
                              <br />
                            </span>
                          ))
                      : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      ) : (
        <CircularProgress sx={{ display: "flex", margin: "150px auto" }} />
      )}
    </>
  );
};

export default CustomerMassiveActions;
