import { createSelector } from "reselect";

import moment from "moment";
moment().format();

const user = (state) => state.user;

export const isLoggedIn = createSelector([user], (user) => {
  if (Boolean(user.headers) && Boolean(user.info)) return true;
  return false;
});

export const userInfo = createSelector([user], (user) => {
  if (Boolean(user.headers) && Boolean(user.info)) return user;
});
