import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  itemsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 1540,
    width: "100%",
  },
  item: {
    width: "20%",
    margin: "0 4px",
  },
  image: {
    width: "100%",
  },
  title: {
    display: "flex",
    padding: "5px 10px",
    marginTop: -4,
    color: "#fff",
    fontSize: "1.4em",
    background: (props) => props.primaryColor,
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Open Sans",
    "&:hover": {
      background: (props) => props.secondaryColor,
      cursor: "pointer",
    },
  },
}));

const InterestAreas = () => {
  const previewSettings = useSelector((state) => state.preview);
  const classes = useStyles({
    primaryColor: previewSettings.main_colors.primary_color,
    secondaryColor: previewSettings.main_colors.secondary_color,
  });

  const items = [
    {
      id: 1,
      imageSrc:
        "https://clube-alegria-prod.s3.us-east-2.amazonaws.com/e92a5fc1-e384-47c4-9c5e-aa8fcc8ea43c",
      title: "Entretenimento",
    },
    {
      id: 2,
      imageSrc:
        "https://clube-alegria-prod.s3.us-east-2.amazonaws.com/76dde8fe-f960-4f15-8ee7-f33eb3d43c21",
      title: "Produtos",
    },
    {
      id: 3,
      imageSrc:
        "https://clube-alegria-prod.s3.us-east-2.amazonaws.com/b0902b96-febf-401a-a332-0715bcc62dd3",
      title: "Serviços",
    },
    {
      id: 4,
      imageSrc:
        "https://clube-alegria-prod.s3.us-east-2.amazonaws.com/232eb799-b5f0-4ffd-a46f-4757f7afb6d8",
      title: "Experiências",
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.itemsContainer}>
        {items.map((item) => (
          <div key={item.id} className={classes.item}>
            <img
              src={item.imageSrc}
              alt="entretenimento"
              className={classes.image}
            />
            <div className={classes.title}>{item.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InterestAreas;
