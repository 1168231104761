import { useState, useEffect } from "react";
import Tour from "reactour";

const TourNavigation = ({ steps }) => {
  const [openTour, setOpenTour] = useState(false);

  const alreadyOpenTour = JSON.parse(localStorage.getItem("@openTour"));
 
  useEffect(() => {
    if (alreadyOpenTour === null) {
      setOpenTour(true)
    }
  }, [alreadyOpenTour]);

  const handleOpenTour = () => {
    localStorage.setItem("@openTour", JSON.stringify(false));
    setOpenTour((prev) => !prev)
  }
  return (
    <>
      <Tour 
        steps={steps}
        isOpen={openTour}
        onRequestClose={handleOpenTour}
        accentColor="#12214D"
        rounded={8}
        showNavigationNumber={false}
        showNumber={false}
      />
    </>
  )
}

export default TourNavigation;
