import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";

import { Box, Container, Grid } from "@material-ui/core";

import scrollTo from "utils/scrollTo";
import Toolbar from "components/layout/Toolbar";
import SurveyView from "components/survey/SurveyView";
import SurveyList from "components/survey/SurveyList";
import SurveyNew from "components/survey/SurveyNew";
import SurveyEdit from "components/survey/SurveyEdit";

import { getSurvey, getBenefitTiers } from "services/api/index";

const Survey = (props) => {
  moment().locale("pt-br");
  const { action } = props;
  const { id } = useParams();
  const [survey, setSurvey] = useState(null);
  const [benefitTiers, setBenefitTiers] = useState([]);

  useEffect(() => {
    scrollTo("#topPage");
  }, []);

  useEffect(() => {
    if (id) {
      getSurvey(id).then((response) => {
        setSurvey(response);
      });
    } else {
      setSurvey({});
    }
    getBenefitTiers().then((response) => {
      setBenefitTiers(response);
    });
  }, [id, action]);

  return (
    <>
      <Helmet>
        {action === "new" ? (
          <title>Nova Pesquisa | +Alegria</title>
        ) : action === "edit" ? (
          <title>Editar Pesquisa | +Alegria</title>
        ) : action === "view" ? (
          <title>Pesquisa | +Alegria</title>
        ) : (
          <title>Pesquisas | +Alegria</title>
        )}
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
        id="topPage"
      >
        <Container maxWidth="lg">
          {action === "new" ? (
            <Toolbar name="Nova Pesquisa" root="surveys" />
          ) : action === "edit" ? (
            <Toolbar name="Editar Pesquisa" root="surveys" buttons={[]} />
          ) : action === "view" ? (
            <Toolbar
              name="Pesquisas"
              root="surveys"
              buttons={[
                { label: "Editar", action: `edit/${id}`, primary: true },
                { label: "Voltar", action: `` },
              ]}
            />
          ) : (
            <>
              <Toolbar
                name="Pesquisas"
                root="surveys"
                buttons={[
                  { label: "+ Criar Pesquisa", action: "new", primary: true },
                ]}
              />
            </>
          )}
          <Grid container spacing={3} sx={{ pt: 3 }}>
            <Grid item lg={12} md={12} xs={12}>
              {action === "edit" ? (
                <SurveyEdit survey={survey} benefit_tiers={benefitTiers} />
              ) : action === "new" ? (
                <SurveyNew benefit_tiers={benefitTiers} />
              ) : action === "view" ? (
                <SurveyView benefit_tiers={benefitTiers} />
              ) : (
                <>
                  <SurveyList />
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Survey;
