import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Box,
  Button,
  Skeleton,
} from "@material-ui/core";

const MapChartSkeleton = () => {
  return (
    <>
      <Card sx={{ height: "100%" }}>
        <CardHeader title={<Skeleton variant="text" />} />
        <Divider />
        <CardContent>
          <Box sx={{ justifyContent: "center", display: "flex" }}>
            <Button
              color="primary"
              variant={"contained"}
              sx={{ margin: "10px 2px 15px 2px" }}
            >
              <Skeleton variant="text" />
            </Button>
          </Box>
          <div id="container"></div>
        </CardContent>
      </Card>
    </>
  );
};

export default MapChartSkeleton;
