import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Skeleton,
  Typography,
} from "@material-ui/core";

import { getQuizAnswer } from "services/api/index";
import formatMonetaryValue from "utils/formatMonetaryValue";
import { green, grey } from "@material-ui/core/colors";

const QuizAnswerView = (props) => {
  const { id } = useParams();
  const [quizAnswer, setQuizAnswer] = useState(null);

  useEffect(() => {
    getQuizAnswer(id).then((response) => {
      if (Object.keys(response).length > 0) setQuizAnswer(response);
    });
  }, [id]);

  return (
    <form autoComplete="off" noValidate {...props}>
      {!quizAnswer ? (
        <QuizAnswerViewSkeleton />
      ) : (
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card sx={{ mt: 3 }}>
                  <Grid container p={2} spacing={3}>
                    <Grid item xs={6}>
                      <Box pb={2}>
                        <Typography color="textSecondary" variant="h5">
                          Dados do Cliente
                        </Typography>
                      </Box>
                      <Table border={1} bordercolor="#F4F6F8">
                        <TableBody>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              Nome
                            </TableCell>
                            <TableCell>{quizAnswer.user.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              CPF
                            </TableCell>
                            <TableCell>
                              {" "}
                              {`${quizAnswer.user.cpf.slice(
                                0,
                                3
                              )}.${quizAnswer.user.cpf.slice(
                                3,
                                6
                              )}.${quizAnswer.user.cpf.slice(
                                6,
                                9
                              )}-${quizAnswer.user.cpf.slice(9, 11)}`}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              E-mail
                            </TableCell>
                            <TableCell>{quizAnswer.user.email}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              Telefone
                            </TableCell>
                            <TableCell>
                              {quizAnswer.user.mobile_number}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              Segmento
                            </TableCell>
                            <TableCell>
                              {quizAnswer.user.benefit_tier?.name}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid item xs={6}>
                      <Box pb={2}>
                        <Typography color="textSecondary" variant="h5">
                          Dados do Quiz
                        </Typography>
                      </Box>
                      <Table border={1} bordercolor="#F4F6F8">
                        <TableBody>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              Finalizado
                            </TableCell>
                            <TableCell>
                              {quizAnswer.finished ? "Sim" : "Não"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              Pontuação
                            </TableCell>
                            <TableCell>{quizAnswer.points}</TableCell>
                          </TableRow>
                          {quizAnswer.gift_voucher_token && (
                            <TableRow>
                              <TableCell
                                width="35%"
                                sx={{ backgroundColor: "#F6F6F6" }}
                              >
                                Vale-presente
                              </TableCell>
                              <TableCell>
                                {quizAnswer.gift_voucher_token}
                              </TableCell>
                            </TableRow>
                          )}
                          {quizAnswer.gift_voucher_token && (
                            <TableRow>
                              <TableCell
                                width="35%"
                                sx={{ backgroundColor: "#F6F6F6" }}
                              >
                                Valor
                              </TableCell>
                              <TableCell>
                                {formatMonetaryValue(
                                  quizAnswer.gift_vouchers_value
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              Respondido em
                            </TableCell>
                            <TableCell>
                              {moment(quizAnswer.created_at).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12}>
                {quizAnswer.answers?.length > 0 && (
                  <Card sx={{ mt: 3 }}>
                    <CardHeader title="Respostas do Usuário"></CardHeader>
                    <PerfectScrollbar>
                      <Box sx={{ minWidth: 960 }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Pergunta</TableCell>
                              <TableCell>Resposta</TableCell>
                              <TableCell align="center">Correto?</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {quizAnswer.answers.map((answer, i) => (
                              <TableRow hover key={i}>
                                <TableCell style={{ wordBreak: "break-all" }}>
                                  {answer.question_text}
                                </TableCell>
                                <TableCell style={{ wordBreak: "break-all" }}>
                                  {answer.option_text}
                                </TableCell>
                                <TableCell align="center">
                                  <Box
                                    sx={{
                                      display: "initial",
                                      color: answer.correct
                                        ? "#FFFFFF"
                                        : "#666666",
                                      fontWeight: "bold",
                                      padding: "5px 15px",
                                      textAlign: "center",
                                      textTransform: "uppercase",
                                      backgroundColor: answer.correct
                                        ? green[600]
                                        : grey[200],
                                    }}
                                  >
                                    {answer.correct ? "Sim" : "Não"}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </PerfectScrollbar>
                  </Card>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </form>
  );
};

const QuizAnswerViewSkeleton = () => {
  return (
    <form autoComplete="off" noValidate>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Table border={1} bordercolor="#F4F6F8">
                <TableBody>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Nome
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Área
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Criado em
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Status
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "initial",
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          padding: "5px 15px",
                          textAlign: "center",
                          textTransform: "uppercase",
                        }}
                      >
                        <Skeleton variant="text" />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default QuizAnswerView;
