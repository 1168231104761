export default function validateSurvey(survey) {
  var isInvalid = false;
  var error = "";
  var expanded;
  var childExpanded;
  var type;

  // Validar obrigatoriedade do campo de questões

  if (survey.questions.length === 0) {
    return {
      type: "question",
      success: false,
      tab: 1,
      error: "A criação de perguntas é obrigatória",
    };
  }

  // Validar obrigatoriedade do campo de segmento

  if (survey.benefit_tier_ids.length === 0) {
    return {
      success: false,
      tab: 0,
      field: "benefit_tier",
      error: "O campo segmentação é obrigatório",
    };
  }

  delete survey.benefit_tier;

  // Validação de preenchimento dos campos

  survey.questions?.forEach((question, i) => {
    if (question.question === "") {
      type = "question";
      expanded = i + 1;
      error = "O campo 'Título' das perguntas é obrigatório";
      isInvalid = true;
      return;
    }

    question.options?.forEach((option, z) => {
      if (option.option === "") {
        type = "option";
        expanded = i + 1;
        childExpanded = z + 1;
        isInvalid = true;
        error = "O campo 'Título' das opções é obrigatório";
        return;
      }
    });
  });

  if (isInvalid) {
    return {
      type,
      success: false,
      tab: 1,
      expanded,
      childExpanded,
      error,
    };
  }

  // Cada pergunta precisa possuir pelo menos duas respostas

  survey.questions?.forEach((question, i) => {
    if (
      (question.type === "radio" || question.type === "checkbox") &&
      question.options.length < 2
    ) {
      expanded = i + 1;
      isInvalid = true;
      return;
    }
  });

  if (isInvalid) {
    return {
      type: "question",
      success: false,
      expanded,
      tab: 1,
      error:
        "Cada pergunta com múltipla escolha precisa possuir pelo menos duas opções",
    };
  }

  return { success: true };
}
