import { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import { Box } from "@material-ui/core";

const ColorPicker = (props) => {
  const { initialColor, changeColor, group, name } = props;
  const [selectedColor, setSelectedColor] = useState({
    displayColorPicker: false,
    color: {
      hex: `${initialColor}`,
    },
  });

  useEffect(() => {
    setSelectedColor({
      displayColorPicker: false,
      color: {
        hex: `${initialColor}`,
      },
    });
  }, [initialColor]);

  const handleClick = () => {
    setSelectedColor({
      ...selectedColor,
      displayColorPicker: !selectedColor.displayColorPicker,
    });
  };

  const handleClose = () => {
    setSelectedColor({ ...selectedColor, displayColorPicker: false });
  };

  const handleChange = (color) => {
    setSelectedColor({ ...selectedColor, color: { hex: color.hex } });
    changeColor({ group, name, color: color.hex });
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `${selectedColor.color.hex}`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
        cursor: "pointer",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <Box sx={{ display: "flex" }}>
      <Box style={styles.swatch} onClick={handleClick}>
        <Box style={styles.color} />
      </Box>
      {selectedColor.displayColorPicker ? (
        <Box style={styles.popover}>
          <Box style={styles.cover} onClick={handleClose} />
          <SketchPicker color={selectedColor.color} onChange={handleChange} />
        </Box>
      ) : null}
    </Box>
  );
};

export default ColorPicker;
