import { MinusCircle } from "react-feather";
import PlanBtn from "./planBtn";
import PremiunPlan from "./premiunPlan";
import { 
  ListWrapper,
  ListItem,
  PlanTtitle,
  AmountContainer,
  TextContainer,
  HightLinthTextContainer,
  PricePlan,
  HightLightPricePlan,
  TextPlan,
  HightLightTextPlan,
  EmpytIconContainer,
} from "./planListLayout";


const PlanList = ({ planList, open }) => {
  return (
    <ListWrapper>
      {
        planList.map(plan => (
          <ListItem key={plan.title}>
            <PlanTtitle>{plan.title}</PlanTtitle>
            <AmountContainer>
              <TextContainer>
                até
              </TextContainer>
              <TextContainer>
                <HightLinthTextContainer>{plan.amount_user}</HightLinthTextContainer>
              </TextContainer>
              <TextContainer>usuários</TextContainer>
            </AmountContainer>
            <PricePlan>
              <sup>R$</sup>
              <HightLightPricePlan>{plan.price}</HightLightPricePlan> mês
            </PricePlan>
            <TextPlan dangerouslySetInnerHTML={{ __html: plan.customer }}/>
            <TextPlan>Cadastro de usuários via importação</TextPlan>
            {
              plan.email_marketing
              ? (
                <TextPlan>
                  <HightLightTextPlan>{plan.email_marketing_amout} </HightLightTextPlan>
                  {plan.email_marketing}
                </TextPlan>
              )
              : <EmpytIconContainer><MinusCircle color={"#636363"} size={16}/></EmpytIconContainer>
            }
            {
              plan.crm_mananger
              ? <TextPlan dangerouslySetInnerHTML={{ __html: plan.crm_mananger }}/>
              : <EmpytIconContainer><MinusCircle color={"#636363"} size={16}/></EmpytIconContainer>
            }
            {
              plan.curation_by_segment
              ? <TextPlan dangerouslySetInnerHTML={{ __html: plan.curation_by_segment }}/>
              : <EmpytIconContainer><MinusCircle color={"#636363"} size={16}/></EmpytIconContainer>
            }
            <PlanBtn 
              title={plan.title} 
              open={open}
            />
          </ListItem>
        ))
      }
      <PremiunPlan open={open} />
    </ListWrapper>
  )
} 

export default PlanList
