import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Skeleton,
} from "@material-ui/core";
import Summary from "components/widgets/Summary";
import SummarySkeleton from "components/widgets/SummarySkeleton";

import { getSurvey, getSurveySummary } from "services/api/index";
import formatMonetaryValue from "utils/formatMonetaryValue";
import { green, grey, red } from "@material-ui/core/colors";
import { HelpCircle } from "react-feather";

import SurveyAnswerList from "components/surveyAnswer/SurveyAnswerList";

const SurveyView = (props) => {
  const { id } = useParams();
  const [survey, setSurvey] = useState(null);
  const [surveySummary, setSurveySummary] = useState(null);

  useEffect(() => {
    getSurvey(id).then((response) => {
      if (Object.keys(response).length > 0) setSurvey(response);
    });

    getSurveySummary(id).then((response) => {
      setSurveySummary([
        {
          originalLabel: "completed",
          label: "Completo",
          value: response.completed,
          icon: <HelpCircle />,
          color: green[600],
        },
        {
          originalLabel: "incompleted",
          label: "Incompleto",
          value: response.incompleted,
          icon: <HelpCircle />,
          color: red[600],
        },
        {
          originalLabel: "total",
          label: "Total",
          value: response.total,
          icon: <HelpCircle />,
          color: grey[600],
        },
      ]);
    });
  }, [id]);

  return (
    <form autoComplete="off" noValidate {...props}>
      {!survey ? (
        <SurveyViewSkeleton />
      ) : (
        <Card>
          <CardContent>
            <Grid container spacing={3} mb={3}>
              <Grid item lg={6} md={6} xs={12}>
                {!surveySummary ? (
                  <SummarySkeleton
                    numberOfItems={4}
                    avatarSize={46}
                    spacing={1}
                  />
                ) : (
                  <Summary
                    parentId={survey.id}
                    title="Resumo"
                    items={surveySummary}
                    hasFilter={false}
                    apiFunction={getSurveySummary}
                    icon={HelpCircle}
                    labelTag="h5"
                    valueTag="h6"
                    avatarSize={46}
                    spacing={1}
                  />
                )}
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Table border={1} bordercolor="#F4F6F8">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Nome
                      </TableCell>
                      <TableCell>{survey.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Área
                      </TableCell>
                      <TableCell>{survey.area}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Segmentos
                      </TableCell>
                      <TableCell>
                        {survey.benefit_tiers
                          ?.map((benefit_tier) => benefit_tier.name)
                          .join(", ")}
                      </TableCell>
                    </TableRow>
                    {survey.start_date && (
                      <TableRow>
                        <TableCell
                          width="35%"
                          sx={{ backgroundColor: "#F6F6F6" }}
                        >
                          Data Inicial
                        </TableCell>
                        <TableCell>
                          {moment(survey.start_date).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {survey.end_date && (
                      <TableRow>
                        <TableCell
                          width="35%"
                          sx={{ backgroundColor: "#F6F6F6" }}
                        >
                          Data Final
                        </TableCell>
                        <TableCell>
                          {moment(survey.end_date).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {survey.has_prize && (
                      <TableRow>
                        <TableCell
                          width="35%"
                          sx={{ backgroundColor: "#F6F6F6" }}
                        >
                          Prêmios
                        </TableCell>
                        <TableCell>{survey.gift_vouchers_quantity}</TableCell>
                      </TableRow>
                    )}
                    {survey.has_prize && (
                      <TableRow>
                        <TableCell
                          width="35%"
                          sx={{ backgroundColor: "#F6F6F6" }}
                        >
                          Valor do Prêmio
                        </TableCell>
                        <TableCell>
                          {" "}
                          {formatMonetaryValue(survey.gift_vouchers_value)}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Criado em
                      </TableCell>
                      <TableCell>
                        {moment(survey.created_at).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Status
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "initial",
                            color:
                              survey.status === "Inativo"
                                ? "#666666"
                                : survey.status === "Ativo"
                                ? "#FFFFFF"
                                : "#666666",
                            fontWeight: "bold",
                            padding: "5px 15px",
                            textAlign: "center",
                            textTransform: "uppercase",
                            backgroundColor:
                              survey.status === "Inativo"
                                ? grey[200]
                                : survey.status === "Ativo"
                                ? green[600]
                                : grey[200],
                          }}
                        >
                          {survey.status}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <SurveyAnswerList
              survey={survey}
              benefitTiers={props?.benefit_tiers}
            />
          </CardContent>
        </Card>
      )}
    </form>
  );
};

const SurveyViewSkeleton = () => {
  return (
    <form autoComplete="off" noValidate>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Table border={1} bordercolor="#F4F6F8">
                <TableBody>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Nome
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Área
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Criado em
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Status
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "initial",
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          padding: "5px 15px",
                          textAlign: "center",
                          textTransform: "uppercase",
                        }}
                      >
                        <Skeleton variant="text" />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default SurveyView;
