import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  Snackbar,
  Typography,
  Skeleton,
} from "@material-ui/core";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Alert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  updateSurvey,
  uploadSurveyImages,
  treatRequestErrors,
} from "services/api/index";

import QuestionsList from "./components/QuestionsList";
import validateSurvey from "./components/validateSurvey";

let formData = new FormData();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography variant="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SurveyEdit = (props) => {
  const { id } = useParams();
  const { benefit_tiers, survey } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestErrors, setRequestErrors] = useState([]);
  const [showError, setShowError] = useState(false);
  const [image, setImage] = useState(null);
  const [finishImage, setFinishImage] = useState(null);
  const [tab, setTab] = useState(0);
  const [benefitTiersIds, setBenefitTiersIds] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [disableFields, setDisableFields] = useState(false);
  const [questionTabExpanded, setQuestionTabExpanded] = useState(false);
  const [optionTabExpanded, setOptionTabExpanded] = useState(false);
  const [removeImage, setRemoveImage] = useState(false);
  const [removeFinishImage, setRemoveFinishImage] = useState(false);
  const navigate = useNavigate();

  const MAX_FILE_SIZE = 2097152; //2Mb

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const area = [
    { value: "", label: "" },
    { value: "login", label: "Login" },
    { value: "register", label: "Cadastro de usuário" },
  ];

  useEffect(() => {
    if (Array.isArray(requestErrors) && requestErrors.length > 0) {
      setShowError(true);
    }
  }, [requestErrors]);

  useEffect(() => {
    if (survey) {
      setBenefitTiersIds(
        survey.benefit_tiers?.map((benefit_tier) => ({
          name: benefit_tier.name,
          value: benefit_tier.id,
        }))
      );
      setImage(survey.image);
      setFinishImage(survey.finish_image);
      setQuestions(survey.questions);

      if (survey.completed_answers || survey.incompleted_answers) {
        setDisableFields(true);
      }
    }
  }, [survey]);

  const handleCloseAlert = () => {
    setShowError(false);
  };

  const onSubmit = async (survey, resetForm, setFieldError, setFieldValue) => {
    survey.benefit_tier_ids = benefitTiersIds.map((item) => item.value);
    survey.questions = questions;

    if (removeImage) {
      survey.image = "_destroy";
    }

    if (removeFinishImage) {
      survey.finish_image = "_destroy";
    }

    const result = validateSurvey(survey);

    if (!result.success) {
      setQuestionTabExpanded(false);
      setOptionTabExpanded(false);

      if (result.field) {
        setFieldError(result.field, result.error);
      }

      if (result.expanded && result.type === "question") {
        setQuestionTabExpanded(result.expanded);
      }

      if (result.expanded && result.type === "option") {
        setQuestionTabExpanded(result.expanded);
        setOptionTabExpanded(result.childExpanded);
      }

      setTab(result.tab);
      setRequestErrors([result.error]);
      setShowError(true);
      return;
    }

    setIsSubmitting(true);

    updateSurvey(survey, id)
      .then(async (response) => {
        if (response.success) {
          formData.append("id", response.id);

          let hasImages = false;

          if (image && typeof image === "object") {
            hasImages = true;
            formData.append("image", image);
          }

          if (finishImage && typeof finishImage === "object") {
            hasImages = true;
            formData.append("finish_image", finishImage);
          }

          survey.questions?.forEach((question, index) => {
            if (question.image && typeof question.image === "object") {
              hasImages = true;
              formData.append(`questions[${index}]`, question.image);
            }
          });

          if (hasImages) {
            await uploadSurveyImages(formData)
              .then((response) => {})
              .catch((error) => {
                console.error({ error });
              });
          }

          setIsSubmitting(false);
          resetForm();
          navigate(`/surveys/${id}?success=1`);
        } else {
          setFieldValue("benefit_tier", "");

          if (response.errors) {
            setRequestErrors(response.errors);
            setIsSubmitting(false);
          } else {
            setRequestErrors(treatRequestErrors(response.message));
            setIsSubmitting(false);
          }
        }
      })
      .catch((err) => {
        setRequestErrors(["Erro ao se conectar com o servidor"]);
        setIsSubmitting(false);
      });
  };

  const handleImage = (event) => {
    if (event.target.files[0].size > MAX_FILE_SIZE) {
      alert("O tamanho máximo para uma imagem é de 2Mb");
      setImage(null);
    }

    if (
      event.target.files[0].type !== "image/png" &&
      event.target.files[0].type !== "image/jpeg"
    ) {
      alert("O formato da imagem é inválido");
      setImage(null);
    }

    if (
      event.target.files[0].size <= MAX_FILE_SIZE &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg")
    ) {
      setImage(event.target.files[0]);
    }
  };

  const handleFinishImage = (event) => {
    if (event.target.files[0].size > MAX_FILE_SIZE) {
      alert("O tamanho máximo para uma imagem é de 2Mb");
      setFinishImage(null);
    }

    if (
      event.target.files[0].type !== "image/png" &&
      event.target.files[0].type !== "image/jpeg"
    ) {
      alert("O formato da imagem é inválido");
      setFinishImage(null);
    }

    if (
      event.target.files[0].size <= MAX_FILE_SIZE &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg")
    ) {
      setFinishImage(event.target.files[0]);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeBenefitTiers = (e) => {
    var array = benefitTiersIds;
    var object = {
      name: e.target.options[e.target.options.selectedIndex].text,
      value: e.target.value,
    };

    if (
      object.value &&
      !array.find((element) => element.value === object.value)
    ) {
      array.push(object);
      setBenefitTiersIds(array);
    }
  };

  const removeBenefitTier = (id) => {
    var array = benefitTiersIds.filter((item) => item.value !== id);
    setBenefitTiersIds(array);
  };

  return (
    <>
      {!survey ? (
        <SurveyEditSkeleton />
      ) : (
        <Formik
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required("O nome é obrigatório"),
            area: Yup.string()
              .max(255)
              .required("A área de impacto é obrigatório"),
            title: Yup.string().max(255).required("O título é obrigatório"),
            start_button: Yup.string()
              .max(255)
              .required("O texto do botão é obrigatório"),
            finish_title: Yup.string()
              .max(255)
              .required("O título do fechamento é obrigatório"),
            finish_button: Yup.string()
              .max(255)
              .required("O texto do botão do fechamento é obrigatório"),
            start_date: Yup.date().min(
              today,
              "A data deve ser igual ou posterior a hoje"
            ),
            end_date: Yup.date()
              .when(
                "start_date",
                (start_date, schema) =>
                  start_date &&
                  schema.min(
                    start_date,
                    "Precisa ser maior que a data de início"
                  )
              )
              .min(today, "A data deve ser igual ou posterior a hoje"),
          })}
          initialValues={{
            name: survey.name || "",
            benefit_tier: "",
            benefit_tier_ids: "",
            start_date: survey.start_date || "",
            end_date: survey.end_date || "",
            area: survey.area_value || "",
            title: survey.title || "",
            description: survey.description || "",
            start_button: survey.start_button || "",
            image: survey.image || "",
            unique_fill: survey.unique_fill ? true : false,
            finish_title: survey.finish_title || "",
            finish_description: survey.finish_description || "",
            finish_button: survey.finish_button || "",
            finish_image: survey.finish_image || "",
          }}
          onSubmit={(survey, { resetForm, setFieldError, setFieldValue }) => {
            onSubmit(survey, resetForm, setFieldError, setFieldValue);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <Box p={2}>
                  <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Dados da Pesquisa" {...a11yProps(0)} />
                    <Tab label="Perguntas" {...a11yProps(1)} />
                    <Tab label="Fechamento" {...a11yProps(2)} />
                  </Tabs>
                  <TabPanel value={tab} index={0}>
                    <>
                      <CardContent>
                        <Grid container spacing={3} pt={0}>
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                              fullWidth
                              label="Nome"
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.name}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={Boolean(touched.area && errors.area)}
                              helperText={touched.area && errors.area}
                              fullWidth
                              label="Área de Impacto"
                              name="area"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              select
                              SelectProps={{ native: true }}
                              value={values.area}
                              variant="outlined"
                            >
                              {area.map((area, i) => (
                                <option key={i} value={area.value}>
                                  {area.label}
                                </option>
                              ))}
                            </TextField>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.benefit_tier && errors.benefit_tier
                              )}
                              helperText={
                                touched.benefit_tier && errors.benefit_tier
                              }
                              fullWidth
                              label="Segmentação"
                              name="benefit_tier"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                handleChangeBenefitTiers(e);
                              }}
                              select
                              SelectProps={{ native: true }}
                              value={values.benefit_tier}
                              variant="outlined"
                            >
                              <option value=""></option>
                              {benefit_tiers.map((benefit_tier, i) => (
                                <option key={i} value={benefit_tier.id}>
                                  {benefit_tier.name}
                                </option>
                              ))}
                            </TextField>
                            <TextField
                              type="hidden"
                              name="benefit_tier_ids"
                              value={benefitTiersIds.map((item) => item.value)}
                              required
                              variant="standard"
                            ></TextField>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {benefitTiersIds.map((item, i) => (
                              <Box
                                key={i}
                                p={1}
                                mx={1}
                                style={{
                                  display: "inline-block",
                                  backgroundColor: "#F4F4F4",
                                }}
                              >
                                <Typography
                                  paragraph={false}
                                  variant="body2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => removeBenefitTier(item.value)}
                                >
                                  {item.name} X
                                </Typography>
                              </Box>
                            ))}
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.start_date && errors.start_date
                              )}
                              helperText={
                                touched.start_date && errors.start_date
                              }
                              fullWidth
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              label="Data Inicial"
                              name="start_date"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.start_date}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.end_date && errors.end_date
                              )}
                              helperText={touched.end_date && errors.end_date}
                              fullWidth
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              label="Data Final"
                              name="end_date"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.end_date}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Box
                        sx={{
                          p: 2,
                        }}
                      >
                        <Card sx={{ mb: 0, overflow: "inherit" }}>
                          <CardHeader
                            title="Configurações"
                            style={{ backgroundColor: "#EEE" }}
                          />
                          <Divider />
                          <CardContent>
                            <Grid container spacing={3} pt={3}>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  error={Boolean(touched.title && errors.title)}
                                  helperText={touched.title && errors.title}
                                  fullWidth
                                  label="Título"
                                  name="title"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  required
                                  value={values.title}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  error={Boolean(
                                    touched.description && errors.description
                                  )}
                                  helperText={
                                    touched.description && errors.description
                                  }
                                  fullWidth
                                  label="Descrição"
                                  name="description"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.description}
                                  variant="outlined"
                                  multiline
                                  minRows={3}
                                  maxRows={5}
                                />
                              </Grid>
                              <Grid item md={12} xs={12} pt={2}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      label="Permitir somente um preenchimento por usuário"
                                      name="unique_fill"
                                      onChange={handleChange}
                                      checked={values.unique_fill}
                                      value={values.unique_fill}
                                      variant="outlined"
                                    />
                                  }
                                  label="Permitir somente um preenchimento por usuário"
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  error={Boolean(
                                    touched.start_button && errors.start_button
                                  )}
                                  helperText={
                                    touched.start_button && errors.start_button
                                  }
                                  fullWidth
                                  label="Texto do Botão"
                                  name="start_button"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  required
                                  value={values.start_button}
                                  variant="outlined"
                                  InputProps={{
                                    inputProps: { maxLength: 16 },
                                  }}
                                />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Imagem"
                                  margin="normal"
                                  name="image"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleImage(e);
                                  }}
                                  type="file"
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                {image && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      position: "relative",
                                    }}
                                  >
                                    <img
                                      src={
                                        typeof image === "string"
                                          ? image
                                          : image instanceof Blob ||
                                            image instanceof File
                                          ? URL.createObjectURL(image)
                                          : null
                                      }
                                      alt="Imagem"
                                      style={{
                                        maxHeight: "250px",
                                        maxWidth: "100%",
                                      }}
                                    />

                                    <Button
                                      type="button"
                                      size="small"
                                      color="secondary"
                                      sx={{
                                        mx: 1,
                                        position: "absolute",
                                        bottom: "5px",
                                        backgroundColor: "white",
                                        "&:hover": {
                                          backgroundColor: "#C4C4C4",
                                        },
                                      }}
                                      onClick={() => {
                                        setRemoveImage(true);
                                        setImage(null);
                                      }}
                                    >
                                      Remover
                                    </Button>
                                  </Box>
                                )}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Box>
                    </>
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <QuestionsList
                      setQuestions={setQuestions}
                      questions={questions}
                      tabExpanded={questionTabExpanded}
                      childTabExpanded={optionTabExpanded}
                      disabled={disableFields}
                    />
                  </TabPanel>
                  <TabPanel value={tab} index={2}>
                    <>
                      <CardContent>
                        <Grid container spacing={3} pt={0}>
                          <Grid item md={12} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.finish_title && errors.finish_title
                              )}
                              helperText={
                                touched.finish_title && errors.finish_title
                              }
                              fullWidth
                              label="Título"
                              name="finish_title"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.finish_title}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.finish_description &&
                                  errors.finish_description
                              )}
                              helperText={
                                touched.finish_description &&
                                errors.finish_description
                              }
                              fullWidth
                              label="Descrição"
                              name="finish_description"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.finish_description}
                              variant="outlined"
                              multiline
                              minRows={3}
                              maxRows={5}
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.finish_button && errors.finish_button
                              )}
                              helperText={
                                touched.finish_button && errors.finish_button
                              }
                              fullWidth
                              label="Texto do Botão"
                              name="finish_button"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.finish_button}
                              variant="outlined"
                              InputProps={{
                                inputProps: { maxLength: 16 },
                              }}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              label="Imagem"
                              margin="normal"
                              name="finish_image"
                              onChange={(e) => {
                                handleChange(e);
                                handleFinishImage(e);
                              }}
                              type="file"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            {finishImage && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={
                                    typeof finishImage === "string"
                                      ? finishImage
                                      : finishImage instanceof Blob ||
                                        finishImage instanceof File
                                      ? URL.createObjectURL(finishImage)
                                      : null
                                  }
                                  alt="Imagem"
                                  style={{
                                    maxHeight: "250px",
                                    maxWidth: "100%",
                                  }}
                                />

                                <Button
                                  type="button"
                                  size="small"
                                  color="secondary"
                                  sx={{
                                    mx: 1,
                                    position: "absolute",
                                    bottom: "5px",
                                    backgroundColor: "white",
                                    "&:hover": {
                                      backgroundColor: "#C4C4C4",
                                    },
                                  }}
                                  onClick={() => {
                                    setRemoveFinishImage(true);
                                    setFinishImage(null);
                                  }}
                                >
                                  Remover
                                </Button>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </>
                  </TabPanel>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      onClick={(e) => {
                        handleChangeTab(e, 0);
                      }}
                      disabled={isSubmitting}
                    >
                      Salvar
                    </Button>
                    <Button
                      type="button"
                      sx={{ mx: 1 }}
                      onClick={() => navigate(`/surveys`)}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </Box>
              </Card>
              {showError && (
                <Snackbar
                  open={showError}
                  autoHideDuration={3000}
                  onClose={handleCloseAlert}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert severity="error">
                    {requestErrors && (
                      <ul style={{ listStyleType: "none" }}>
                        {requestErrors.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    )}
                  </Alert>
                </Snackbar>
              )}
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default SurveyEdit;

const SurveyEditSkeleton = () => {
  return (
    <div>
      <Card>
        <CardContent>
          <Grid container spacing={3} pt={3}>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Salvar
          </Button>
          <Button type="button" sx={{ mx: 1 }}>
            Voltar
          </Button>
        </Box>
      </Card>
    </div>
  );
};
