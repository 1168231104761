import { Button } from "./planListLayout";
import { sendContactForEmail } from "services/api";
import { useSelector } from "react-redux";
import { userInfo } from "store/selectors";

const PlanBtn = ({ title, open }) => {
  const user = useSelector(userInfo);

  const handleClick = async () => {
    const data = {
      to: 'maisalegria@easylive.com.br',
      from: user.email,
      subject: 'Troca de Plano B2B',
      message: `
        Nome Clube: ${user.partner_name} <br/>
        Plano Atual: ${user.plan_name} <br/>
        Novo Plano: ${title} <br/>
        e-mail Solicitante: ${user.email} <br/>
        Data Solicitação: ${new Date().toLocaleDateString("pt-br")}
      `,
    };
    open(true);
    await sendContactForEmail(data);
  }

  return (
    <Button 
      isSelected={user.plan_name === title}
      disabled={user.plan_name === title}
      onClick={() => handleClick()}
    >
      { user.plan_name === title ? "Seu plano atual" : "Trocar para " + title }
    </Button>
  )
}

export default PlanBtn
