import { useState } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { useSettings } from "hooks/useSettings";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    paddingTop: 82,
    width: "100%",
    flex: "1 1 auto",

    [theme.breakpoints.up("md")]: {
      paddingLeft: ({ isSettings }) => (isSettings ? 0 : 256),
    },
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    padding: "5px 0px",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

const DashboardLayout = () => {
  const { isSettings } = useSettings();
  const classes = useStyles({ isSettings });

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
