import React from "react";

import Newsletter from "./Newsletter";
import Footer from "./Footer";
import { Card, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    background: "#fff",
    marginTop: 16,
  },
}));

const PSSecondSection = () => {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <Newsletter />
      <Footer />
    </Card>
  );
};

export default PSSecondSection;
