import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";

import { Box, Container, Grid, Snackbar, Divider } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import { green, red, indigo, orange } from "@material-ui/core/colors";

import scrollTo from "utils/scrollTo";
import Toolbar from "components/layout/Toolbar";
import CustomerView from "components/customer/CustomerView";
import CustomerList from "components/customer/CustomerList";
import CustomerNew from "components/customer/CustomerNew";
import CustomerPassword from "components/customer/CustomerPassword";
import CustomerEdit from "components/customer/CustomerEdit";
import CustomerImport from "components/customer/CustomerImport";
import Graph from "components/widgets/Graph";
import Summary from "components/widgets/Summary";
import SummarySkeleton from "components/widgets/SummarySkeleton";
import SimpleTableList from "components/widgets/SimpleTableList";
import MapChart from "components/widgets/MapChart";

import {
  getUser,
  getBenefitTiers,
  updateUser,
  getUsersLatest,
  getActiveUsersByState,
  getActiveUsers,
  getUsersSummary,
} from "services/api/index";
import GraphSkeleton from "components/widgets/GraphSkeleton";
import SimpleTableListSkeleton from "components/widgets/SimpleTableListSkeleton";
import MapChartSkeleton from "components/widgets/MapChartSkeleton";

const Customer = (props) => {
  moment().locale("pt-br");
  const { action } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [benefitTiers, setBenefitTiers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestErrors, setRequestErrors] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [activeUsers, setActiveUsers] = useState(null);
  const [activeUsersByState, setActiveUsersByState] = useState(null);
  const [usersLatest, setUsersLatest] = useState(null);
  const [usersSummary, setUsersSummary] = useState(null);

  useEffect(() => {
    scrollTo("#topPage");

    getActiveUsersByState().then((response) => {
      setActiveUsersByState(
        response.map((item) => ({
          key: item.state,
          value: null,
          object: item.info,
        }))
      );
    });

    getActiveUsers().then((response) => {
      setActiveUsers(
        response.map((item) => ({
          key: item.key,
          value: item.doc_count,
        }))
      );
    });

    getUsersSummary().then((response) => {
      setUsersSummary([
        {
          originalLabel: "active",
          label: "Ativos",
          value: response.active,
          icon: <PeopleIcon />,
          color: green[600],
        },
        {
          originalLabel: "blocked",
          label: "Bloqueados",
          value: response.blocked,
          icon: <PeopleIcon />,
          color: red[600],
        },
        {
          originalLabel: "registered",
          label: "Registrados",
          value: response.registered,
          icon: <PeopleIcon />,
          color: indigo[600],
        },
        {
          originalLabel: "total",
          label: "Total",
          value: response.total,
          icon: <PeopleIcon />,
          color: orange[600],
        },
      ]);
    });

    getUsersLatest(15).then((response) => {
      var array = [...new Array(15)]
        .map((i, idx) => moment().startOf("day").subtract(idx, "days"))
        .reverse()
        .map((day) => {
          let usersArray = response.filter(function (el) {
            return (
              moment(el.day).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")
            );
          });
          if (usersArray.length > 0) {
            return usersArray[0].count;
          } else {
            return 0;
          }
        });
      setUsersLatest(array);
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(requestErrors) && requestErrors.length > 0) {
      setShowError(true);
    }
  }, [requestErrors]);

  useEffect(() => {
    if (id) {
      getUser(id).then((response) => {
        setCustomer(response);
      });
    } else {
      setCustomer({});
    }
    getBenefitTiers().then((response) => {
      setBenefitTiers(response);
    });
  }, [id, action]);

  const handleCloseAlert = () => {
    setShowError(false);
    setShowSuccess(false);
  };

  const blockUser = (block) => {
    setIsSubmitting(true);
    updateUser({ blocked: block }, id)
      .then((response) => {
        if (response.success) {
          setIsSubmitting(false);
          setShowSuccess(true);
          setCustomer(response);
          block
            ? setSuccessMessage("Usuário bloqueado com sucesso!")
            : setSuccessMessage("Usuário desbloqueado com sucesso!");

          navigate(`/customers/${id}`);
        } else {
          if (response.errors) {
            setRequestErrors(response.errors);
            setIsSubmitting(false);
          } else {
            setRequestErrors([response.message]);
            setIsSubmitting(false);
          }
        }
      })
      .catch((err) => {
        setRequestErrors(["Erro ao se conectar com o servidor"]);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Helmet>
        {action === "new" ? (
          <title>Novo Usuário | +Alegria</title>
        ) : action === "edit" ? (
          <title>Editar Usuário | +Alegria</title>
        ) : action === "import" ? (
          <title>Ações em Massa (Usuários) | +Alegria</title>
        ) : action === "change-password" && !customer.last_sign_in_at ? (
          <title>Alterar Senha | +Alegria</title>
        ) : action === "view" ? (
          <title>Usuário | +Alegria</title>
        ) : (
          <title>Usuários | +Alegria</title>
        )}
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
        id="topPage"
      >
        <Container maxWidth="lg">
          {action === "new" ? (
            <Toolbar name="Novo Usuário" root="customers" />
          ) : action === "edit" ? (
            <Toolbar
              name="Editar Usuário"
              root="customers"
              buttons={
                customer && !customer.last_sign_in_at
                  ? [
                      {
                        label: customer.blocked ? "Desbloquear" : "Bloquear",
                        function: true,
                        action: () =>
                          customer.blocked ? blockUser(false) : blockUser(true),
                        primary: true,
                        disabled: isSubmitting,
                      },
                      {
                        label: "Alterar senha",
                        action: `change-password/${id}`,
                      },
                    ]
                  : [
                      {
                        label: customer?.blocked ? "Desbloquear" : "Bloquear",
                        function: true,
                        action: () =>
                          customer?.blocked
                            ? blockUser(false)
                            : blockUser(true),
                        primary: true,
                        disabled: isSubmitting,
                      },
                    ]
              }
            />
          ) : action === "change-password" && !customer.last_sign_in_at ? (
            <Toolbar name="Alterar Senha" root="customers" />
          ) : action === "import" ? (
            <Toolbar name="Importações em Massa" root="customers" />
          ) : action === "view" ? (
            <Toolbar
              name="Usuário"
              root="customers"
              buttons={[
                { label: "Editar", action: `edit/${id}`, primary: true },
                { label: "Voltar", action: `/` },
              ]}
            />
          ) : (
            <>
              <Toolbar
                name="Usuários"
                root="customers"
                buttons={[
                  { label: "+ Criar Usuário", action: "new", primary: true },
                  { label: "Importar", action: "import" },
                ]}
              />
              <Grid container spacing={3} sx={{ pt: 3 }}>
                <Grid item lg={6} md={12} xs={12}>
                  {!usersSummary ? (
                    <SummarySkeleton
                      numberOfItems={4}
                      avatarSize={56}
                      spacing={1}
                    />
                  ) : (
                    <Summary
                      title="Resumo"
                      items={usersSummary}
                      labelTag="h4"
                      valueTag="h5"
                      avatarSize={56}
                      spacing={1}
                    />
                  )}
                </Grid>

                <Grid item lg={6} md={12} xs={12}>
                  {!usersLatest ? (
                    <GraphSkeleton />
                  ) : (
                    <Graph
                      title="Novos usuários"
                      itemsLabel="Novos usuários"
                      items={usersLatest}
                      apiFunction={getUsersLatest}
                    />
                  )}
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  {!activeUsers ? (
                    <SimpleTableListSkeleton
                      numberOfItems={4}
                      cellValue="users_count"
                    />
                  ) : (
                    <SimpleTableList
                      title="Usuários ativos por segmentação"
                      items={activeUsers}
                      cellValue="users_count"
                    />
                  )}
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  {!activeUsersByState ? (
                    <MapChartSkeleton />
                  ) : (
                    <MapChart
                      title="Usuários ativos por estado"
                      items={activeUsersByState}
                      cols={benefitTiers}
                      colValue="benefit_tier"
                      cellValue="count"
                    />
                  )}
                </Grid>
              </Grid>
            </>
          )}
          <Grid container spacing={3} sx={{ pt: 3 }}>
            <Grid item lg={12} md={12} xs={12}>
              {action === "edit" ? (
                <CustomerEdit customer={customer} benefitTiers={benefitTiers} />
              ) : action === "change-password" ? (
                <CustomerPassword
                  customer={customer}
                  benefitTiers={benefitTiers}
                />
              ) : action === "import" ? (
                <CustomerImport />
              ) : action === "new" ? (
                <CustomerNew benefitTiers={benefitTiers} />
              ) : action === "view" ? (
                <CustomerView />
              ) : (
                <>
                  <Divider sx={{ mb: 5, mt: 2 }} />
                  <CustomerList />
                </>
              )}
            </Grid>
          </Grid>
        </Container>
        {showError && (
          <Snackbar
            open={showError}
            autoHideDuration={3000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert severity="error">
              {requestErrors && (
                <ul style={{ listStyleType: "none" }}>
                  {requestErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              )}
            </Alert>
          </Snackbar>
        )}
        {showSuccess && (
          <Snackbar
            open={showSuccess}
            autoHideDuration={3000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert severity="success">{successMessage}</Alert>
          </Snackbar>
        )}
      </Box>
    </>
  );
};

export default Customer;
