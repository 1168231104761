import { useState, useEffect } from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Download } from "react-feather";

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  CardContent,
  Button,
  Typography,
  SvgIcon,
  Dialog,
  TableRow,
  Skeleton,
} from "@material-ui/core";

import { getPartnerInvoices } from "services/api/index";
import formatMonetaryValue from "utils/formatMonetaryValue";

const InvoiceList = () => {
  const [invoices, setInvoices] = useState([]);
  const [invoice, setInvoice] = useState({});
  const [openInvoice, setOpenInvoice] = useState(false);

  const handleCloseInvoiceDetails = () => {
    setOpenInvoice(false);
    setInvoice(null);
  };

  useEffect(() => {
    getPartnerInvoices()
      .then((invoices) => {
        setInvoices(invoices.partner_invoices);
      })
      .catch((error) => {
        setInvoices([]);
        console.error({ error });
      });
  }, []);

  return (
    <>
      {!invoices ? (
        <InvoiceListSkeleton />
      ) : (
        <Card id="topTable">
          <Card>
            <CardContent>
              <Box>
                <Typography color="textSecondary" variant="h4">
                  Histórico de Pagamentos
                </Typography>
              </Box>
            </CardContent>
          </Card>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 960 }}>
              {invoices.length === 0 ? (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Nenhum dado encontrado</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Descrição</TableCell>
                      <TableCell>Valor Total</TableCell>
                      <TableCell align="center">Vencimento</TableCell>
                      <TableCell align="center">Situação</TableCell>
                      <TableCell align="center">Boleto</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices.map((invoice, i) => (
                      <TableRow hover key={i}>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              width: "200px",
                            }}
                          >
                            {invoice.description}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {" "}
                          {formatMonetaryValue(invoice.value)}
                        </TableCell>
                        <TableCell
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          {moment(invoice.expires_at).format("DD/MM/YYYY")}
                        </TableCell>

                        <TableCell align="center" width={"150px"}>
                          <Box
                            sx={{
                              color: "#FFFFFF",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                              padding: "5px",
                              textAlign: "center",
                              textTransform: "uppercase",
                              backgroundColor:
                                invoice.status === "Recebido"
                                  ? "#3949ab"
                                  : invoice.status === "Pago"
                                  ? "green"
                                  : invoice.status === "Cancelado"
                                  ? "gray"
                                  : invoice.status === "Pendente"
                                  ? "#ff0000"
                                  : "#ff0000",
                            }}
                          >
                            {invoice.status}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {invoice.file_link ? (
                            <a
                              href={invoice.file_link}
                              style={{ cursor: "pointer" }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <SvgIcon fontSize="small" color="action">
                                <Download color="blue" />
                              </SvgIcon>
                            </a>
                          ) : (
                            <>--</>
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          color="blue"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setInvoice(invoice);
                            setOpenInvoice(true);
                          }}
                        >
                          <Typography color="blue">DETALHES</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </PerfectScrollbar>

          {invoice?.description && (
            <Dialog
              onClose={handleCloseInvoiceDetails}
              aria-labelledby="simple-dialog-title"
              open={openInvoice}
              maxWidth="md"
              fullWidth
            >
              <>
                <Box sx={{ padding: 3 }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Typography sx={{ width: "100%" }} variant="h4">
                      Detalhes da Fatura
                    </Typography>
                    <Box
                      paddingX={4}
                      alignItems={"center"}
                      justifyContent={"center"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Typography variant="h4" mb={2}>
                        Emissão
                      </Typography>
                      <Typography fontSize={20}>
                        {invoice.created_at
                          ? moment(invoice.created_at).format("DD/MM/YYYY")
                          : "--"}
                      </Typography>
                    </Box>
                    <Box
                      paddingX={4}
                      alignItems={"center"}
                      justifyContent={"center"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Typography variant="h4" mb={2}>
                        Vencimento
                      </Typography>{" "}
                      <Typography fontSize={20}>
                        {" "}
                        {invoice?.created_at
                          ? moment(invoice?.created_at).format("DD/MM/YYYY")
                          : "--"}
                      </Typography>
                    </Box>
                    <Box
                      paddingX={4}
                      alignItems={"center"}
                      justifyContent={"center"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Typography variant="h4" mb={2}>
                        Pagamento
                      </Typography>
                      <Typography fontSize={20}>
                        {invoice?.paid_at
                          ? moment(invoice?.paid_at).format("DD/MM/YYYY")
                          : "--"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#cdcbcb",
                      padding: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 4,
                    }}
                  >
                    <Typography color={"black"} variant="h4">
                      Descrição
                    </Typography>
                    <Typography color={"black"} variant="h4" marginRight={6}>
                      Valor (R$)
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      padding: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 1,
                    }}
                  >
                    <Typography color={"black"} fontSize={20}>
                      {invoice.description}
                    </Typography>
                    <Typography color={"black"} marginRight={6} fontSize={20}>
                      {formatMonetaryValue(invoice.value)}
                    </Typography>
                  </Box>
                  <Typography
                    color={"black"}
                    fontSize={20}
                    marginLeft={1}
                    mt={1}
                  ></Typography>
                  <Box
                    sx={{
                      padding: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 4,
                      paddingRight: 7,
                    }}
                  >
                    <Typography color={"black"} variant="h4">
                      Total -
                    </Typography>
                    <Typography color={"black"} variant="h4" ml={1}>
                      {formatMonetaryValue(invoice.value)}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "flex-end" }} m={3}>
                  {invoice.status !== "Pago" && (
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      sx={{ marginRight: 1 }}
                      disabled={invoice.file_link === null}
                    >
                      <a
                        href={invoice.file_link}
                        style={{ cursor: "pointer", color: "white" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        PAGAR
                      </a>
                    </Button>
                  )}

                  <Button
                    color="primary"
                    variant="outlined"
                    type="submit"
                    ml={2}
                    onClick={handleCloseInvoiceDetails}
                  >
                    FECHAR
                  </Button>
                </Box>
              </>
            </Dialog>
          )}
        </Card>
      )}
    </>
  );
};

export default InvoiceList;

const InvoiceListSkeleton = () => {
  // eslint-disable-next-line no-unused-vars
  const [invoices, setInvoices] = useState(() => {
    const array = Array.from({ length: 10 }, (_, i) => i + 1);

    return array;
  });
  return (
    <Card id="topTable">
      <Card>
        <CardContent>
          <Box>
            <Typography color="textSecondary" variant="h4">
              Histórico de Pagamentos
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 960 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell>Valor Total</TableCell>
                <TableCell align="center">Vencimento</TableCell>
                <TableCell align="center">Situação</TableCell>
                <TableCell align="center">Boleto</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((_, i) => (
                <TableRow hover key={i}>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        width: "200px",
                      }}
                    >
                      <Skeleton variant="text" width={150} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell style={{ width: "150px", textAlign: "center" }}>
                    <Skeleton />
                  </TableCell>

                  <TableCell align="center" width={"150px"}>
                    <Box
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "0.7rem",
                        fontWeight: "bold",
                        padding: "5px",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      <Skeleton />
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    align="center"
                    color="blue"
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography color="blue">DETALHES</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};
