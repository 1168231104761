import { Helmet } from "react-helmet";
import moment from "moment";
import "moment/locale/pt-br";

import { Box, Container, Grid } from "@material-ui/core";

import OrderView from "components/order/OrderView";

import InvoiceList from "components/Invoice/InvoiceList";

const Invoice = (props) => {
  moment().locale("pt-br");
  const { action } = props;

  return (
    <>
      <Helmet>
        {action === "view" ? (
          <title>Faturamento | +Alegria</title>
        ) : (
          <title>Faturamento | +Alegria</title>
        )}
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
        id="topPage"
      >
        <Container maxWidth="lg">
          <Grid container spacing={3} sx={{ pt: 3 }}>
            <Grid item lg={12} md={12} xs={12}>
              {props.type === "view" ? (
                <OrderView />
              ) : (
                <>
                  <InvoiceList />
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Invoice;
