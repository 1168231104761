import { useState, useEffect } from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate, useLocation } from "react-router-dom";
import scrollTo from "utils/scrollTo";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Snackbar,
  CardContent,
  TextField,
  Button,
  Typography,
  Grid,
  Skeleton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { getOrders } from "services/api/index";
import formatMonetaryValue from "utils/formatMonetaryValue";

const OrderList = ({ orders, ...rest }) => {
  const navigate = useNavigate();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const [limit, setLimit] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [orderList, setOrderList] = useState(null);
  const [filters, setFilters] = useState({ number: "", email: "", cpf: "" });
  const [showSuccess, setShowSuccess] = useState(Boolean(query.get("success")));
  const [showFilters, setShowFilters] = useState(false);
  const [btnFilterLabel, setBtnFilterLabel] = useState("Exibir filtros");

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    scrollTo("#topTable");
  };

  const handleClick = (id) => {
    if (id["$oid"]) {
      id = id["$oid"];
    }

    navigate(`/orders/${id}`);
  };

  const handleCloseAlert = () => {
    setShowSuccess(false);
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
    showFilters
      ? setBtnFilterLabel("Exibir filtros")
      : setBtnFilterLabel("Ocultar filtros");
  };

  const handleClickFilters = () => {
    setOrderList(null);
    getOrders(page + 1, 10, filters).then((response) => {
      setOrderList(response.orders);
      setTotal(response.pagination.total);
      setLimit(10);
    });
  };

  const handleClearFilters = () => {
    setOrderList(null);

    setFilters({ name: "", email: "", cpf: "" });
    handleShowFilters();
    getOrders(page + 1, 10).then((response) => {
      setOrderList(response.orders);
      setTotal(response.pagination.total);
      setLimit(10);
    });
  };

  useEffect(() => {
    setOrderList(null);
    getOrders(page + 1, 10).then((response) => {
      setOrderList(response.orders);
      setTotal(response.pagination.total);
      setLimit(10);
    });
  }, [page]);

  const handleChange = (event) => {
    setFilters((filters) => ({
      ...filters,
      [`${event.target.name}`]: `${event.target.value}`,
    }));
  };

  return (
    <Card {...rest} id="topTable">
      <Card>
        <CardContent>
          <Box>
            <Typography color="textSecondary" variant="h4">
              Lista de Pedidos
              <Button
                color="primary"
                variant={showFilters ? "outlined" : "contained"}
                type="button"
                onClick={handleShowFilters}
                sx={{ marginLeft: "20px" }}
              >
                {btnFilterLabel}
              </Button>
            </Typography>
          </Box>
          {showFilters && (
            <Box>
              <Grid container spacing={3} pt={3}>
                <Grid item lg={3} md={6} sm={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    label="Pesquisar pelo número"
                    name="number"
                    value={filters.number}
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    label="Pesquisar pelo e-mail"
                    name="email"
                    value={filters.email}
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    label="Pesquisar pelo CPF"
                    name="cpf"
                    value={filters.cpf}
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={6}
                  sm={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    type="button"
                    onClick={handleClearFilters}
                    sx={{ marginRight: "5px" }}
                  >
                    Limpar
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={handleClickFilters}
                  >
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 960 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nº</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell align="center">Data</TableCell>
                <TableCell align="center">Segmento</TableCell>
                <TableCell align="center">Valor</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!orderList ? (
                <OrderListSkeleton />
              ) : (
                <>
                  {orderList.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        Nenhum dado encontrado
                      </TableCell>
                    </TableRow>
                  ) : (
                    orderList.slice(0, limit).map((order, i) => (
                      <TableRow
                        hover
                        key={i}
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleClick(order.id)}
                      >
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {order.number}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {`${order.cpf.slice(0, 3)}.${order.cpf.slice(
                            3,
                            6
                          )}.${order.cpf.slice(6, 9)}-${order.cpf.slice(
                            9,
                            11
                          )}`}
                        </TableCell>
                        <TableCell>{order.email}</TableCell>
                        <TableCell align="center">
                          {moment(order.order_date_time).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {order.benefit_tier}
                        </TableCell>
                        <TableCell align="center">
                          {formatMonetaryValue(order.value)}
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              color: "#FFFFFF",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                              padding: "5px",
                              textAlign: "center",
                              textTransform: "uppercase",
                              backgroundColor:
                                order.status === "Recebido"
                                  ? "#3949ab"
                                  : order.status === "Entregue"
                                  ? "green"
                                  : order.status === "Pagamento aprovado"
                                  ? "#3949ab"
                                  : order.status === "Cancelado"
                                  ? "gray"
                                  : order.status === "Pagamento não realizado"
                                  ? "#d88200"
                                  : "red",
                            }}
                          >
                            {order.status === "Recebido"
                              ? "Recebido"
                              : order.status === "Entregue"
                              ? "Entregue"
                              : order.status === "Pagamento aprovado"
                              ? "Aprovado"
                              : order.status === "Cancelado"
                              ? "Cancelado"
                              : order.status === "Pagamento não realizado"
                              ? "Não pago"
                              : order.status}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
        labelRowsPerPage={"Pedidos por página"}
        labelDisplayedRows={({ from, to, count }) => {
          return "" + from + "-" + to + " de " + count;
        }}
      />
      {showSuccess && (
        <Snackbar
          open={showSuccess}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="success">Usuário criado com sucesso!</Alert>
        </Snackbar>
      )}
    </Card>
  );
};

export default OrderList;

const OrderListSkeleton = () => {
  // eslint-disable-next-line no-unused-vars
  const [order, setOrderList] = useState(() => {
    const array = Array.from({ length: 10 }, (_, i) => i + 1);

    return array;
  });
  return (
    <>
      {order.map((_, i) => (
        <TableRow hover sx={{ cursor: "pointer" }} key={i}>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="text" />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
