import "react-perfect-scrollbar/dist/css/styles.css";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "components/layout/GlobalStyles";
import "mixins/chartjs";
import theme from "theme";
import "./index.css";
import routes from "routes";
import * as Actions from "store/actions";
import { isLoggedIn } from "store/selectors";

const App = () => {
  const dispatch = useDispatch();
  const logged = useSelector(isLoggedIn);
  const user = useSelector((state) => state.user);
  const routing = useRoutes(routes(logged, user));

  useEffect(() => {
    dispatch(Actions.loadApplication());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
};

export default App;
