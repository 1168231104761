import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  titleContainer: {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
  },
  title: {
    color: (props) => props.primaryColor,
    textTransform: "uppercase",
    fontFamily: "Open Sans",
    letterSpacing: "-1px",
    marginBottom: 0,
    fontWeight: 700,
    fontSize: 24,
  },
  separator: {
    display: "flex",
    backgroundColor: (props) => props.secondaryColor,
    height: "3px",
    width: "1.5em",
  },
  viewAll: {
    color: (props) => props.primaryColor,
    cursor: "pointer",
    fontFamily: "Open Sans",
    textAlign: "left",
    "&:hover": {
      color: (props) => props.secondaryColor,
    },
  },
}));

const StyledTitle = ({ title }) => {
  const previewSettings = useSelector((state) => state.preview);
  const classes = useStyles({
    primaryColor: previewSettings.main_colors.primary_color,
    secondaryColor: previewSettings.main_colors.secondary_color,
  });

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <p className={classes.title}>{title}</p>
        <span className={classes.separator}></span>
      </div>
      <span className={classes.viewAll}>Ver todas</span>
    </div>
  );
};

export default StyledTitle;
