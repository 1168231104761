import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Formik, useFormikContext, useField } from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";

import {
  createUser,
  treatRequestErrors,
  getCitiesRequest,
} from "services/api/index";
import { userInfo } from "store/selectors";

const CitiesField = (props) => {
  const {
    values: { state },
    setFieldValue,
  } = useFormikContext();
  const [field] = useField(props);
  const [allCitiesList, setAllCitiesList] = useState([]);

  useEffect(() => {
    if (state) {
      getCitiesRequest(state).then((res) => {
        setAllCitiesList(res);
        setFieldValue(props.name, res[0].label);
      });
    }
  }, [props.name, setFieldValue, state]);

  return (
    <TextField
      fullWidth
      label={props.label}
      select
      SelectProps={{ native: true }}
      name={props.name}
      onBlur={props.handleBlur}
      onChange={props.handleChange}
      value={props.value}
      variant="outlined"
      {...field}
    >
      {allCitiesList.map((option, i) => (
        <option key={i} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
  );
};

const CustomerNew = (props) => {
  const { benefitTiers } = props;
  const navigate = useNavigate();
  const user = useSelector(userInfo);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestErrors, setRequestErrors] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const gender = [
    { value: "", label: "" },
    { value: "m", label: "Masculino" },
    { value: "f", label: "Feminino" },
    { value: "n", label: "Prefiro não dizer" },
  ];

  const states = [
    { value: "", label: "" },
    { value: "AC", label: "Acre" },
    { value: "AL", label: "Alagoas" },
    { value: "AP", label: "Amapá" },
    { value: "AM", label: "Amazonas" },
    { value: "BA", label: "Bahia" },
    { value: "CE", label: "Ceará" },
    { value: "DF", label: "Distrito Federal" },
    { value: "ES", label: "Espírito Santo" },
    { value: "GO", label: "Goiás" },
    { value: "MA", label: "Maranhão" },
    { value: "MT", label: "Mato Grosso" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "MG", label: "Minas Gerais" },
    { value: "PA", label: "Pará" },
    { value: "PB", label: "Paraíba" },
    { value: "PR", label: "Paraná" },
    { value: "PE", label: "Pernambuco" },
    { value: "PI", label: "Piauí" },
    { value: "RR", label: "Roraima" },
    { value: "RO", label: "Rondônia" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "RN", label: "Rio Grande do Norte" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "SC", label: "Santa Catarina" },
    { value: "SP", label: "São Paulo" },
    { value: "SE", label: "Sergipe" },
    { value: "TO", label: "Tocantins" },
  ];

  useEffect(() => {
    if (Array.isArray(requestErrors) && requestErrors.length > 0) {
      setShowError(true);
    }
  }, [requestErrors]);

  const handleCloseAlert = () => {
    setShowError(false);
    setShowSuccess(false);
    navigate(`/customers`);
  };

  const onSubmit = async (customer, resetForm) => {
    setIsSubmitting(true);
    createUser(customer)
      .then((response) => {
        if (response.success) {
          setIsSubmitting(false);
          setShowSuccess(true);
          resetForm();
        } else {
          if (response.errors) {
            setRequestErrors(response.errors);
            setIsSubmitting(false);
          } else {
            setRequestErrors(treatRequestErrors(response.message));
            setIsSubmitting(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setRequestErrors(["Erro ao se conectar com o servidor"]);
        setIsSubmitting(false);
      });
  };

  const validateCPF = (strCPF) => {
    if (strCPF) {
      strCPF = strCPF.replaceAll(".", "").replace("-", "");
      var Soma;
      var Resto;
      Soma = 0;
      if (
        strCPF.length !== 11 ||
        strCPF === "00000000000" ||
        strCPF === "11111111111" ||
        strCPF === "22222222222" ||
        strCPF === "33333333333" ||
        strCPF === "44444444444" ||
        strCPF === "55555555555" ||
        strCPF === "66666666666" ||
        strCPF === "77777777777" ||
        strCPF === "88888888888" ||
        strCPF === "99999999999"
      )
        return false;

      for (var i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if (Resto === 10 || Resto === 11) Resto = 0;
      if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if (Resto === 10 || Resto === 11) Resto = 0;
      if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
      return true;
    }
  };

  return (
    <>
      <Formik
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("O nome é obrigatório"),
          email: Yup.string()
            .email("Deve ser um e-mail válido")
            .max(255)
            .required("O e-mail é obrigatório"),
          cpf: Yup.string()
            .test("test-invalid-cpf", "CPF inválido", (cpf) => validateCPF(cpf))
            .required("O CPF é obrigatório"),
          benefit_tier_id: Yup.string()
            .max(255)
            .required("A segmentação é obrigatória"),
          password: Yup.string()
            .oneOf(
              [Yup.ref("confirm_password"), null],
              "As senhas não correspondem"
            )
            .min(8, "A senha deve ter no mínimo 8 dígitos"),
          confirm_password: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "As senhas não correspondem"
          ),
        })}
        initialValues={{
          name: "",
          email: "",
          cpf: "",
          benefit_tier_id: "",
          gender: "",
          mobile_number: "",
          state: "",
          city: "",
          password: "",
          confirm_password: "",
          provider: user.info.provider,
        }}
        onSubmit={(customer, { resetForm }) => {
          onSubmit(customer, resetForm);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardContent>
                <Grid container spacing={3} pt={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      fullWidth
                      label="Nome"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputMask
                      mask="99999999999"
                      maskChar=""
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.cpf}
                    >
                      {() => (
                        <TextField
                          error={Boolean(touched.cpf && errors.cpf)}
                          helperText={touched.cpf && errors.cpf}
                          fullWidth
                          label="CPF"
                          name="cpf"
                          required
                          variant="outlined"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      fullWidth
                      label="E-mail"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(
                        touched.benefit_tier && errors.benefit_tier
                      )}
                      helperText={touched.benefit_tier && errors.benefit_tier}
                      fullWidth
                      label="Segmentação"
                      name="benefit_tier_id"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.benefit_tier_id}
                      variant="outlined"
                    >
                      <option value=""></option>
                      {benefitTiers.map((option, i) => (
                        <option key={i} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Gênero"
                      name="gender"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.gender}
                      variant="outlined"
                    >
                      {gender.map((option, i) => (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputMask
                      mask="(99) 99999-9999"
                      maskChar=""
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobile_number}
                    >
                      {() => (
                        <TextField
                          fullWidth
                          label="Telefone"
                          name="mobile_number"
                          variant="outlined"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Estado"
                      name="state"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.state}
                      variant="outlined"
                    >
                      {states.map((option, i) => (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CitiesField
                      name="city"
                      label="Cidade"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      fullWidth
                      label="Código de primeiro acesso"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      variant="outlined"
                      type="password"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(
                        touched.confirm_password && errors.confirm_password
                      )}
                      helperText={
                        touched.confirm_password && errors.confirm_password
                      }
                      fullWidth
                      label="Confirmar código"
                      name="confirm_password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirm_password}
                      variant="outlined"
                      type="password"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Salvar
                </Button>
                <Button
                  type="button"
                  sx={{ mx: 1 }}
                  onClick={() => navigate(`/customers`)}
                >
                  Cancelar
                </Button>
              </Box>
            </Card>
            {showSuccess && (
              <Snackbar
                open={showSuccess}
                autoHideDuration={3000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Alert severity="success">Usuário criado com sucesso!</Alert>
              </Snackbar>
            )}
            {showError && (
              <Snackbar
                open={showError}
                autoHideDuration={3000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Alert severity="error">
                  {requestErrors && (
                    <ul style={{ listStyleType: "none" }}>
                      {requestErrors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  )}
                </Alert>
              </Snackbar>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

export default CustomerNew;
