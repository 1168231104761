import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";

import { Box, Container, Grid } from "@material-ui/core";

import scrollTo from "utils/scrollTo";
import Toolbar from "components/layout/Toolbar";
import SurveyAnswerView from "components/surveyAnswer/SurveyAnswerView";

import { getSurveyAnswer } from "services/api/index";

const SurveyAnswer = (props) => {
  moment().locale("pt-br");
  const { action } = props;
  const { id } = useParams();
  const [surveyAnswer, setSurveyAnswer] = useState(null);

  useEffect(() => {
    scrollTo("#topPage");
  }, []);

  useEffect(() => {
    if (id) {
      getSurveyAnswer(id).then((response) => {
        setSurveyAnswer(response);
      });
    } else {
      setSurveyAnswer({});
    }
  }, [id, action]);

  return (
    <>
      <Helmet>
        {action === "view" ? (
          <title>Resposta | +Alegria</title>
        ) : (
          <title>Respostas | +Alegria</title>
        )}
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
        id="topPage"
      >
        <Container maxWidth="lg">
          {action === "view" ? (
            <Toolbar
              name="Resposta"
              root="surveys"
              buttons={[
                { label: "Voltar", action: `${surveyAnswer?.survey_id}` },
              ]}
            />
          ) : (
            <>
              <Toolbar name="Resposta" root="surveyAnswers" buttons={[]} />
            </>
          )}
          <Grid container spacing={3} sx={{ pt: 3 }}>
            <Grid item lg={12} md={12} xs={12}>
              <SurveyAnswerView />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SurveyAnswer;
