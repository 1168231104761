import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Search as SearchIcon } from "react-feather";

const Toolbar = (props) => {
  const navigate = useNavigate();

  return (
    <Box {...props}>
      <Box
        sx={{
          display: "flex",
          alignItens: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography color="textPrimary" variant="h2">
          {props.name}
        </Typography>
        {props.buttons && props.buttons.length > 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {props.buttons.map((button, i) => {
              return button.primary ? (
                <Button
                  color="primary"
                  variant="contained"
                  disabled={button.disabled}
                  onClick={
                    button.function
                      ? button.action
                      : () => navigate(`/${props.root}/${button.action}`)
                  }
                  key={i}
                  sx={{
                    mx: 0.5,
                  }}
                >
                  {button.label}
                </Button>
              ) : (
                <Button
                  sx={{ mx: 0.5 }}
                  disabled={button.disabled}
                  onClick={() => navigate(`/${props.root}/${button.action}`)}
                  key={i}
                >
                  {button.label}
                </Button>
              );
            })}
          </Box>
        )}
      </Box>
      {props.search && (
        <Box sx={{ mt: 3 }}>
          <Card>
            <CardContent>
              <Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder={props.searchlabel}
                  variant="outlined"
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default Toolbar;
