import axios from "axios";
import store from "store";

const getHeaders = () => {
  const state = store.getState();
  let headers = state.user.headers;

  return headers
    ? {
        "access-token": headers["access-token"],
        "token-type": headers["token-type"],
        client: headers["client"],
        uid: headers["uid"],
        expiry: headers["expiry"],
      }
    : {};
};

export const treatRequestErrors = (requestError) => {
  if (Array.isArray(requestError)) {
    return [...requestError];
  } else if (typeof requestError === "object") {
    var errors = [];
    for (var prop in requestError) {
      if (Array.isArray(requestError[prop])) {
        for (let value of requestError[prop]) {
          errors.push(prop.toUpperCase() + ": " + value);
        }
      } else {
        errors.push(prop.toUpperCase() + ": " + requestError[prop]);
      }
    }
    return errors;
  } else if (typeof requestError === "string") {
    return [requestError];
  }
  return ["Não foi possivel se conectar com o servidor."];
};

const filtersToQueryParams = (params) => {
  let filters = [];
  let paramNames = Object.keys(params);

  paramNames.forEach((name) => {
    if (params[name] !== null && params[name] !== undefined) {
      let value = params[name];
      if (Array.isArray(value)) {
        value.forEach((element) => filters.push(`q[${name}][]=${element}`));
      } else {
        filters.push(`q[${name}]=${value}`);
      }
    }
  });
  return filters.join("&");
};

export const loginRequest = async ({ email, password }) => {
  var collaborator = {
    email: email,
    password: password,
  };

  try {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/auth/sign_in`, {
        collaborator,
      })
      .then((res) => {
        return { ...res.data, success: true };
      });
  } catch (error) {
    let errorResponse = {
      success: false,
      errors: [],
    };
    if (error.response.data.errors) {
      errorResponse.errors.push(...error.response.data.errors);
    } else if (error.response.data.error) {
      errorResponse.errors.push("Usuário não encontrado no clube +alegria.");
    } else {
      errorResponse.errors.push("E-mail ou senha inválidos.");
    }

    return errorResponse;
  }
};

export const logoutRequest = async () => {
  try {
    return await axios
      .delete(`${process.env.REACT_APP_API_URL}/auth/sign_out`, {
        headers: getHeaders(),
      })
      .then((res) => ({ ...res.data, success: true }));
  } catch (error) {
    let data = error.response.data;
    return data;
  }
};

export const forgotPasswordRequest = async (email) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/auth/password`, {
        email: email,
      })
      .then((res) => {
        return { ...res.data.data, success: true };
      });
  } catch (error) {
    return error.response.data;
  }
};

export const resetPassword = async (collaborator) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/auth/password`,
        {
          reset_password_token: collaborator.token,
          password: collaborator.password,
          password_confirmation: collaborator.confirm_password,
        },
        { headers: getHeaders() }
      )
      .then((res) => res.data);
  } catch (error) {
    return error.response.data;
  }
};

export const getSettings = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/settings`, { headers: getHeaders() })
    .then((res) => res.data);
};

export const updateSettings = async (settings) => {
  return await axios
    .put(`${process.env.REACT_APP_API_URL}/settings`, settings, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const uploadImages = async (formData) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/settings/upload_images`, formData, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const uploadQuizImages = async (formData) => {
  return await axios
    .post(
      `${process.env.REACT_APP_API_URL}/quizzes/upload_quiz_images`,
      formData,
      {
        headers: getHeaders(),
      }
    )
    .then((res) => res.data);
};

export const uploadSurveyImages = async (formData) => {
  return await axios
    .post(
      `${process.env.REACT_APP_API_URL}/surveys/upload_survey_images`,
      formData,
      {
        headers: getHeaders(),
      }
    )
    .then((res) => res.data);
};

export const getBenefitTiers = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/benefit_tiers`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getPartnerInvoices = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/partner_invoices`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getCollaborators = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/collaborators`, {
      headers: getHeaders(),
    })
    .then((res) => {
      return res.data;
    });
};

export const getCollaborator = async () => {
  var headers = getHeaders();
  if (Object.keys(headers).length > 0) {
    try {
      return await axios
        .get(`${process.env.REACT_APP_API_URL}/collaborators`, {
          headers,
        })
        .then((res) => ({ data: res.data, success: true }));
    } catch (error) {
      return error.response.data;
    }
  }
};

export const updateCollaborator = async (collaborator) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/collaborators`,
        { collaborator },
        { headers: getHeaders() }
      )
      .then((res) => ({ ...res.data, success: true }));
  } catch (error) {
    let data = { message: error.response.data.message, success: false };
    return data;
  }
};

export const getUsers = async (page, per_page = 10, params = {}) => {
  let queryParams = [
    `page=${page}`,
    `per_page=${per_page}`,
    `order_by=created_at`,
    `direction=DESC`,
    filtersToQueryParams(params),
  ];
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/users?${queryParams.join("&")}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getUsersLatest = async (days = 15) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/users/latest?days=${days}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getActiveUsers = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/users/actives`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getActiveUsersByState = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/users/actives_by_state`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getUsersSummary = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/users/count_by_status`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getUser = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/users/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getUserOrders = async (id, page, per_page = 10, params = {}) => {
  let queryParams = [
    `page=${page}`,
    `per_page=${per_page}`,
    `order_by=created_at`,
    `direction=DESC`,
    filtersToQueryParams(params),
  ];
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/users/${id}/orders?${queryParams.join(
        "&"
      )}`,
      {
        headers: getHeaders(),
      }
    )
    .then((res) => res.data);
};

export const createUser = async (user) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}/users`,
        { user },
        { headers: getHeaders() }
      )
      .then((res) => {
        return { ...res.data, success: true };
      });
  } catch (error) {
    let data = { message: error.response.data.message, success: false };
    return data;
  }
};

export const updateUser = async (user, id) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/users/${id}`,
        { user },
        { headers: getHeaders() }
      )
      .then((res) => ({ ...res.data, success: true }));
  } catch (error) {
    let data = { message: error.response.data.message, success: false };
    return data;
  }
};

export const getUserImports = async (page, per_page = 10) => {
  let queryParams = [
    `page=${page}`,
    `per_page=${per_page}`,
    `order_by=created_at`,
    `direction=DESC`,
  ];

  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/mass_imports?${queryParams.join("&")}`,
      {
        headers: getHeaders(),
      }
    )
    .then((res) => res.data);
};

export const getUserImport = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/mass_imports/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getUserImportActivate = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/mass_imports/${id}/activate`, {
      headers: getHeaders(),
    })
    .then((res) => res.message);
};

export const createUserImport = async (formData) => {
  var headers = getHeaders();
  headers["content-type"] = "multipart/form-data";
  try {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/mass_imports`, formData, {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    let data = { message: error.response.data.message, success: false };
    return data;
  }
};

export const updateUserImport = async (formData, id) => {
  var headers = getHeaders();
  headers["content-type"] = "multipart/form-data";
  try {
    return await axios
      .put(`${process.env.REACT_APP_API_URL}/mass_imports/${id}`, formData, {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    let data = { message: error.response.data.message, success: false };
    return data;
  }
};

export const deleteUserImport = async (id) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/mass_imports/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getProducts = async (page, per_page = 10, params = {}) => {
  let queryParams = [
    `page=${page}`,
    `per_page=${per_page}`,
    `order_by=created_at`,
    `direction=DESC`,
    filtersToQueryParams(params),
  ];
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/products?${queryParams.join("&")}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getProduct = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/products/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getOrders = async (page, per_page = 10, params = {}) => {
  let queryParams = [
    `page=${page}`,
    `per_page=${per_page}`,
    `order_by=order_date_time`,
    `direction=DESC`,
    filtersToQueryParams(params),
  ];

  return await axios
    .get(`${process.env.REACT_APP_API_URL}/orders?${queryParams.join("&")}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getOrdersLatest = async (days, params = {}) => {
  let queryParams = [filtersToQueryParams(params)];
  return await axios
    .get(
      `${
        process.env.REACT_APP_API_URL
      }/orders/latest?days=${days}&${queryParams.join("&")}`,
      {
        headers: getHeaders(),
      }
    )
    .then((res) => res.data);
};

export const getOrdersRejectedLatest = async (days = 15) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/users/latest?days=${days}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getPaymentMethodOrders = async (days = 15) => {
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/orders/by_payment_type?days=${days}`,
      {
        headers: getHeaders(),
      }
    )
    .then((res) => res.data);
};

export const getOrdersSummary = async (days = 15) => {
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/orders/count_by_status?days=${days}`,
      {
        headers: getHeaders(),
      }
    )
    .then((res) => res.data);
};

export const getOrdersByState = async (days = 15) => {
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/orders/count_by_state?days=${days}`,
      {
        headers: getHeaders(),
      }
    )
    .then((res) => res.data);
};

export const getOrder = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/orders/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getGiftVouchersSummary = async (days = 15) => {
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/gift_vouchers/count_by_status?days=${days}`,
      {
        headers: getHeaders(),
      }
    )
    .then((res) => res.data);
};

export const getGiftVouchersLatest = async (days, params = {}) => {
  let queryParams = [filtersToQueryParams(params)];
  return await axios
    .get(
      `${
        process.env.REACT_APP_API_URL
      }/gift_vouchers/latest?days=${days}&${queryParams.join("&")}`,
      {
        headers: getHeaders(),
      }
    )
    .then((res) => res.data);
};

export const getGiftVouchers = async (page, per_page = 10, params = {}) => {
  let queryParams = [
    `page=${page}`,
    `per_page=${per_page}`,
    `order_by=created_at`,
    `direction=DESC`,
    filtersToQueryParams(params),
  ];
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/gift_vouchers?${queryParams.join("&")}`,
      {
        headers: getHeaders(),
      }
    )
    .then((res) => res.data);
};

export const getGiftVoucher = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/gift_vouchers/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const createGiftVoucher = async (gift_voucher) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}/gift_vouchers`,
        { gift_voucher },
        { headers: getHeaders() }
      )
      .then((res) => ({ ...res.data, success: true }));
  } catch (error) {
    let data = { message: error.response.data.message, success: false };
    return data;
  }
};

export const updateGiftVoucher = async (gift_voucher, id) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/gift_vouchers/${id}`,
        { gift_voucher },
        { headers: getHeaders() }
      )
      .then((res) => ({ ...res.data, success: true }));
  } catch (error) {
    let data = { message: error.response.data.message, success: false };
    return data;
  }
};

export const deleteGiftVoucher = async (id) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/gift_vouchers/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

// export const postGiftVouchers = async (token) => {
//   try {
//     return await axios
//       .post(
//         `${process.env.REACT_APP_API_URL}/gift_vouchers`,
//         {
//           gift_voucher: { token: token },
//         },
//         {
//           headers: getHeaders(),
//         }
//       )
//       .then((res) => {
//         return { ...res.data, success: true };
//       });
//   } catch (error) {
//     return error.response.data;
//   }
// };

export const getDashboardOrdersRevenues = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/orders/revenue  `, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getDashboardAccess = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/access  `, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getDashboardOrdersEconomy = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/orders/economy`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getDashboardCustomers = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/users/customers`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const sendContactForEmail = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_EMAIL}/send`,
      {
        ...data,
      }
    );

    return response.data;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const getCitiesRequest = async (UF) => {
  return await axios
    .get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${UF}/municipios`
    )
    .then((res) => {
      return res.data
        .map((city) => city.nome)
        .sort((a, b) => a.localeCompare(b))
        .map((city) => ({ value: city, label: city }));
    });
};

export const createQuiz = async (quiz) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}/quizzes`,
        { quiz },
        { headers: getHeaders() }
      )
      .then((res) => {
        return { ...res.data, success: true };
      });
  } catch (error) {
    let data = { message: error.response.data.message, success: false };
    return data;
  }
};

export const getQuizzes = async (page, per_page = 10, params = {}) => {
  let queryParams = [
    `page=${page}`,
    `per_page=${per_page}`,
    `order_by=created_at`,
    `direction=DESC`,
    filtersToQueryParams(params),
  ];
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/quizzes?${queryParams.join("&")}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getQuiz = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/quizzes/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getQuizSummary = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/quizzes/count_answers/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const exportDataQuiz = async (id) => {
  const headers = { ...getHeaders(), "content-type": "blob" };
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/quizzes/export_data/${id}`,
    responseType: "arraybuffer",
    headers,
  };

  try {
    const response = await axios(config);
    var data = new Blob([response?.data], {
      type: "application / vnd. MS Excel",
    });
    var csvURL = window.URL.createObjectURL(data);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute(
      "download",
      `export_quiz_data_${new Date().getTime()}.xlsx`
    );
    tempLink.click();
  } catch (error) {
    throw Error(error);
  }
};

export const exportResultsQuiz = async (id) => {
  const headers = { ...getHeaders(), "content-type": "blob" };
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/quizzes/export_results/${id}`,
    responseType: "arraybuffer",
    headers,
  };

  try {
    const response = await axios(config);
    var data = new Blob([response?.data], {
      type: "application / vnd. MS Excel",
    });
    var csvURL = window.URL.createObjectURL(data);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute(
      "download",
      `export_quiz_results_${new Date().getTime()}.xlsx`
    );
    tempLink.click();
  } catch (error) {
    throw Error(error);
  }
};

export const getQuizAnswers = async (id, page, per_page = 10, params = {}) => {
  let queryParams = [
    `page=${page}`,
    `per_page=${per_page}`,
    `order_by=created_at`,
    `direction=DESC`,
    filtersToQueryParams(params),
  ];
  return await axios
    .get(
      `${
        process.env.REACT_APP_API_URL
      }/quizzes/${id}/quiz_user_answers?${queryParams.join("&")}`,
      {
        headers: getHeaders(),
      }
    )
    .then((res) => res.data);
};

export const updateQuiz = async (quiz, id) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/quizzes/${id}`,
        { quiz },
        { headers: getHeaders() }
      )
      .then((res) => ({ ...res.data, success: true }));
  } catch (error) {
    let data = { message: error.response.data.message, success: false };
    return data;
  }
};

export const activateQuiz = async (quiz, id) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/quizzes/activate/${id}`,
        { quiz },
        { headers: getHeaders() }
      )
      .then((res) => ({ ...res.data, success: true }));
  } catch (error) {
    let data = { message: error.response.data.message, success: false };
    return data;
  }
};

export const getQuizAnswer = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/quiz_user_answers/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const createSurvey = async (survey) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}/surveys`,
        { survey },
        { headers: getHeaders() }
      )
      .then((res) => {
        return { ...res.data, success: true };
      });
  } catch (error) {
    let data = { message: error.response.data.message, success: false };
    return data;
  }
};

export const getSurveys = async (page, per_page = 10, params = {}) => {
  let queryParams = [
    `page=${page}`,
    `per_page=${per_page}`,
    `order_by=created_at`,
    `direction=DESC`,
    filtersToQueryParams(params),
  ];
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/surveys?${queryParams.join("&")}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getSurvey = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/surveys/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const getSurveySummary = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/surveys/count_answers/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};

export const exportDataSurvey = async (id) => {
  const headers = { ...getHeaders(), "content-type": "blob" };
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/surveys/export_data/${id}`,
    responseType: "arraybuffer",
    headers,
  };

  try {
    const response = await axios(config);
    var data = new Blob([response?.data], {
      type: "application / vnd. MS Excel",
    });
    var csvURL = window.URL.createObjectURL(data);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute(
      "download",
      `export_survey_data_${new Date().getTime()}.xlsx`
    );
    tempLink.click();
  } catch (error) {
    throw Error(error);
  }
};

export const exportResultsSurvey = async (id) => {
  const headers = { ...getHeaders(), "content-type": "blob" };
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/surveys/export_results/${id}`,
    responseType: "arraybuffer",
    headers,
  };

  try {
    const response = await axios(config);
    var data = new Blob([response?.data], {
      type: "application / vnd. MS Excel",
    });
    var csvURL = window.URL.createObjectURL(data);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute(
      "download",
      `export_survey_results_${new Date().getTime()}.xlsx`
    );
    tempLink.click();
  } catch (error) {
    throw Error(error);
  }
};

export const getSurveyAnswers = async (
  id,
  page,
  per_page = 10,
  params = {}
) => {
  let queryParams = [
    `page=${page}`,
    `per_page=${per_page}`,
    `order_by=created_at`,
    `direction=DESC`,
    filtersToQueryParams(params),
  ];
  return await axios
    .get(
      `${
        process.env.REACT_APP_API_URL
      }/surveys/${id}/survey_user_answers?${queryParams.join("&")}`,
      {
        headers: getHeaders(),
      }
    )
    .then((res) => res.data);
};

export const updateSurvey = async (survey, id) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/surveys/${id}`,
        { survey },
        { headers: getHeaders() }
      )
      .then((res) => ({ ...res.data, success: true }));
  } catch (error) {
    let data = { message: error.response.data.message, success: false };
    return data;
  }
};

export const activateSurvey = async (survey, id) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/surveys/activate/${id}`,
        { survey },
        { headers: getHeaders() }
      )
      .then((res) => ({ ...res.data, success: true }));
  } catch (error) {
    let data = { message: error.response.data.message, success: false };
    return data;
  }
};

export const getSurveyAnswer = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/survey_user_answers/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data);
};
