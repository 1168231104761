import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    textTransform: "uppercase",
    "&:focus": {
      borderBottom: "0px !important",
    },
  },
  selectRoot: {
    //...other styles
    "&:focus": {
      backgroundColor: "yellow",
    },
  },
  select: {
    marginTop: "4px",
    fontWeight: "bold !important",
    fontSize: "12px !important",
    "&:before": {
      borderBottom: "0px !important",
    },
    "&:after": {
      borderBottom: "0px !important",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SimpleTableList = (props) => {
  const {
    title,
    items,
    cols,
    colValue,
    cellValue,
    hasFilter,
    apiFunction,
    itemsFormat,
  } = props;
  const [interval, setInterval] = useState(15);
  const [dataItems, setDataItems] = useState(items);
  const classes = useStyles();

  const handleChange = (event) => {
    setInterval(event.target.value);
  };

  useEffect(() => {
    if (hasFilter) {
      apiFunction(interval).then((response) => {
        var data = [];
        var newData = [];

        for (let item in response) {
          data.push({ key: item, value: response[item] });
        }

        for (let item of data) {
          var array = itemsFormat.filter((el) => el.slug === item.key);
          if (array[0]) {
            newData.push({
              key: array[0].key,
              value: item.value,
            });
          }
        }

        setDataItems(newData);
      });
    }
  }, [interval, apiFunction, hasFilter, items, itemsFormat]);

  return (
    <>
      <Card sx={{ height: "100%" }}>
        {hasFilter ? (
          <CardHeader
            action={
              <>
                <FormControl variant="standard" className={classes.formControl}>
                  <Select
                    id="interval"
                    value={interval}
                    onChange={handleChange}
                    className={classes.select}
                  >
                    <MenuItem value={15}>Últimos 15 dias</MenuItem>
                    <MenuItem value={30}>Últimos 30 dias</MenuItem>
                    <MenuItem value={60}>Últimos 60 dias</MenuItem>
                  </Select>
                </FormControl>
              </>
            }
            title={title}
          />
        ) : (
          <CardHeader title={title} />
        )}
        <Divider />
        <CardContent>
          <Box
            sx={{
              maxHeight: 280,
              position: "relative",
              overflowY: "auto",
            }}
          >
            <Table>
              {cols && (
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {cols.map((col, j) => (
                      <TableCell align="center" key={j}>
                        {col}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {dataItems.length > 0 ? (
                  dataItems.map((item, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <strong>{item.key}</strong>
                      </TableCell>
                      {cols ? (
                        cols.map((col, j) => {
                          let cell = item.object
                            .filter(function (el) {
                              return el[colValue] === col;
                            })
                            .map((info, index) => info[cellValue]);
                          return (
                            <TableCell key={j} align="center">
                              {cell.length > 0 ? cell[0] : 0}
                            </TableCell>
                          );
                        })
                      ) : (
                        <TableCell align="center">{item.value}</TableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell style={{ border: 0 }}>
                      Nenhum dado disponível
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default SimpleTableList;
