import React from "react";
import Header from "./Header";
import InterestAreas from "./InterestAreas";
import OfferHighlights from "./OfferHighlights";

import { Card, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#fff",
    paddingBottom: 16,
  },
  contentContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  maxWidthContainer: {
    maxWidth: 1140,
  },
}));

const PSFirstSection = () => {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <Header />
      <InterestAreas />
      <div className={classes.contentContainer}>
        <div className={classes.maxWidthContainer}>
          <OfferHighlights />
        </div>
      </div>
    </Card>
  );
};

export default PSFirstSection;
