import PlanBtn from "./planBtn";
import { 
  ListItem,
  PlanTtitle,
  AmountContainer,
  TextContainer,
  HightLinthTextContainer,
  PricePlan,
  HightLightPricePlan,
  TextPlan,
  HightLightTextPlan,
  PremiunTextHighLight,
  PlusIcon,
} from "./planListLayout";

const PremiunPlan = ({ open }) => {
  return (
    <ListItem key="premium">
      <PlanTtitle>Premium</PlanTtitle>
      <AmountContainer>
        <TextContainer>
          Mais de
        </TextContainer>
        <TextContainer>
          <HightLinthTextContainer primary="true">10.000</HightLinthTextContainer>
        </TextContainer>
        <TextContainer>usuários</TextContainer>
      </AmountContainer>
      <PricePlan>
        <HightLightPricePlan>A consultar</HightLightPricePlan>
      </PricePlan>
      <TextPlan>
        <p>Tudo o que está incluso no</p>
        <p><HightLightTextPlan>Plano Advanced</HightLightTextPlan></p>
        <PlusIcon>+</PlusIcon>
        <PremiunTextHighLight withoutborder="true">
          E-mail marketing 100% customizado
        </PremiunTextHighLight>
      </TextPlan>
      <div>
        <PremiunTextHighLight>Criação de campanhas e peças</PremiunTextHighLight>
      </div>
      <div>
        <PremiunTextHighLight>Conteúdos e projetos exclusivos</PremiunTextHighLight>
      </div>
      <div>
        <PremiunTextHighLight>Equipe de atendimento especializada</PremiunTextHighLight>
      </div>
      <div>
        <PremiunTextHighLight>URL personalizada</PremiunTextHighLight>
      </div>
      <PlanBtn 
        title="Premium" 
        open={open}
      />
    </ListItem>
  )
}

export default PremiunPlan
