import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import scrollTo from "utils/scrollTo";

import { resetPassword } from "services/api/index";

const ResetPassword = () => {
  const { token } = useParams();
  const [requestErrors, setRequestErrors] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    scrollTo("#topPage");
  }, []);

  useEffect(() => {
    if (Array.isArray(requestErrors) && requestErrors.length > 0) {
      setShowError(true);
    }
  }, [requestErrors]);

  const handleCloseAlert = () => {
    setShowAlert(false);
    setShowError(false);
  };

  const onSubmit = async (collaborator) => {
    setIsSubmitting(true);
    resetPassword({
      token: token,
      password: collaborator.password,
      confirm_password: collaborator.confirm_password,
    })
      .then((response) => {
        if (response.success) {
          setShowAlert(true);
        } else {
          if (response.errors) {
            setRequestErrors(response.errors);
            setIsSubmitting(false);
          } else {
            setRequestErrors([response.error]);
            setIsSubmitting(false);
          }
        }
      })
      .catch((err) => {
        setRequestErrors(["Erro ao se conectar com o servidor"]);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Entrar | +Alegria</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
        id="topPage"
      >
        <Container maxWidth="sm">
          <Formik
            validationSchema={Yup.object().shape({
              password: Yup.string().max(255).required("A senha é obrigatória"),
              confirm_password: Yup.string()
                .max(255)
                .required("A senha é obrigatória"),
            })}
            initialValues={{
              password: "",
              confirm_password: "",
            }}
            onSubmit={(collaborator) => {
              onSubmit(collaborator);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Redefina sua senha
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Digite sua nova senha abaixo
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Senha"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(
                    touched.confirm_password && errors.confirm_password
                  )}
                  fullWidth
                  helperText={
                    touched.confirm_password && errors.confirm_password
                  }
                  label="Confirmar Senha"
                  margin="normal"
                  name="confirm_password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.confirm_password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Redefinir
                  </Button>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                    align="center"
                    sx={{ py: 1 }}
                  >
                    <Link to="/login">Voltar</Link>
                  </Typography>
                </Box>
                {showAlert && (
                  <Snackbar
                    open={showAlert}
                    autoHideDuration={6000}
                    onClose={handleCloseAlert}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  >
                    <Alert severity="success">
                      {requestErrors &&
                        "Senha atualizada com sucesso. Volte e faça o login."}
                    </Alert>
                  </Snackbar>
                )}
                {showError && (
                  <Snackbar
                    open={showError}
                    autoHideDuration={3000}
                    onClose={handleCloseAlert}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  >
                    <Alert severity="error">
                      {requestErrors && (
                        <ul style={{ listStyleType: "none" }}>
                          {requestErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                          ))}
                        </ul>
                      )}
                    </Alert>
                  </Snackbar>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ResetPassword;
