import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Snackbar,
  Skeleton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import * as Yup from "yup";

import { userLogin } from "store/actions";

import { getCollaborators, updateCollaborator } from "services/api/index";

const AccountForm = (props) => {
  const dispatch = useDispatch();
  const [b2bCollaborator, setB2bCollaborator] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestErrors, setRequestErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    getCollaborators().then((response) => {
      setB2bCollaborator(response);
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(requestErrors) && requestErrors.length > 0) {
      setShowError(true);
    }
  }, [requestErrors]);

  const handleCloseAlert = () => {
    setShowError(false);
    setShowSuccess(false);
  };

  const onSubmit = async (collaborator) => {
    if (collaborator.password === "" || collaborator.password === "") {
      delete collaborator.password;
      delete collaborator.confirm_password;
    }

    setIsSubmitting(true);
    updateCollaborator(collaborator)
      .then((response) => {
        if (response.success) {
          dispatch(userLogin(response));
          setIsSubmitting(false);
          setShowSuccess(true);
          setSuccessMessage("Usuário atualizado com sucesso!");
        } else {
          if (response.errors) {
            setRequestErrors(response.errors);
            setIsSubmitting(false);
          } else {
            setRequestErrors([response.error]);
            setIsSubmitting(false);
          }
        }
      })
      .catch((err) => {
        setRequestErrors(["Erro ao se conectar com o servidor"]);
        setIsSubmitting(false);
      })
      .finally(() => {
        setRequestErrors(["Erro ao se conectar com o servidor"]);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      {!b2bCollaborator ? (
        <AccountFormSkeleton />
      ) : (
        <Formik
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required("O nome é obrigatório"),
            email: Yup.string()
              .email("Deve ser um e-mail válido")
              .max(255)
              .required("O e-mail é obrigatório"),
            password: Yup.string()
              .oneOf(
                [Yup.ref("confirm_password"), null],
                "As senhas não correspondem"
              )
              .min(8, "Deve ter no mínimo 8 caracteres"),
            confirm_password: Yup.string().oneOf(
              [Yup.ref("password"), null],
              "As senhas não correspondem"
            ),
          })}
          initialValues={{
            name: b2bCollaborator.name || "",
            email: b2bCollaborator.email || "",
            password: "",
            confirm_password: "",
          }}
          onSubmit={(b2bCollaborator) => {
            onSubmit(b2bCollaborator);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardContent>
                  <Grid container spacing={3} pt={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        fullWidth
                        label="Nome"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        fullWidth
                        label="E-mail"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        fullWidth
                        label="Senha"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        variant="outlined"
                        type="password"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.confirm_password && errors.confirm_password
                        )}
                        helperText={
                          touched.confirm_password && errors.confirm_password
                        }
                        fullWidth
                        label="Confirmar Senha"
                        name="confirm_password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirm_password}
                        variant="outlined"
                        type="password"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Salvar
                  </Button>
                </Box>
              </Card>
              {showError && (
                <Snackbar
                  open={showError}
                  autoHideDuration={3000}
                  onClose={handleCloseAlert}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert severity="error">
                    {requestErrors && (
                      <ul style={{ listStyleType: "none" }}>
                        {requestErrors.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    )}
                  </Alert>
                </Snackbar>
              )}
              {showSuccess && (
                <Snackbar
                  open={showSuccess}
                  autoHideDuration={3000}
                  onClose={handleCloseAlert}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert severity="success">{successMessage}</Alert>
                </Snackbar>
              )}
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AccountForm;

const AccountFormSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} pt={3}>
          <Grid item md={6} xs={12}>
            <Skeleton variant="rect" height={"53px"} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Skeleton variant="rect" height={"53px"} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Skeleton variant="rect" height={"53px"} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Skeleton variant="rect" height={"53px"} />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Button color="primary" variant="contained" type="submit">
          Salvar
        </Button>
      </Box>
    </Card>
  );
};
