import React from "react";
import { useSelector } from "react-redux";
import discountFlag from "./images/bandeira_desc.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    height: "100%",
    width: "206px",
    borderRadius: 0,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    marginLeft: "7px",
    marginRight: "7px",
    cursor: "pointer",
    background: "white",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
    "&:hover": {
      cursor: "pointer",
    },
  },
  image: {
    width: "100%",
    height: "100%",
  },
  flag: {
    width: "65px",
    height: "25px",
    position: "absolute",
    top: 5,
    right: 10,
  },
  discount: {
    color: "#fff",
    fontSize: ".7em",
    fontFamily: "Montserrat",
    fontWeight: 600,
    padding: "0 0 0 0",
    width: 45,
    position: "absolute",
    top: 10,
    right: 14,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontFamily: "Open Sans",
    alignItems: "flex-start",
    flex: "1 1 auto",
    minHeight: "1px",
    padding: "1.25rem",
    height: 108,
  },
  button: {
    color: (props) => props.primaryTextColor,
    textTransform: "none",
    backgroundColor: (props) => props.primaryBackgroundColor,
    borderRadius: "2.25rem",
    fontSize: ".8em",
    padding: "0.8em 3em",
    fontWeight: 700,
    boxShadow: "2px 2px 5px #ccc",
    border: 0,
    transition: ".3s",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const ProductCard = ({ product }) => {
  const previewSettings = useSelector((state) => state.preview);
  const classes = useStyles({
    primaryTextColor: previewSettings?.button_colors?.primary_text_color,
    primaryBackgroundColor:
      previewSettings?.button_colors?.primary_background_color,
  });

  return (
    <div className={classes.container}>
      <img
        src={product?.image}
        alt={product?.productName}
        className={classes.image}
      />
      <img
        src={discountFlag}
        alt="bandeira de desconto"
        className={classes.flag}
      />
      <span className={classes.discount}>
        {product?.discount}%
        <span
          style={{ fontSize: ".7em", fontFamily: "Montserrat", marginLeft: 4 }}
        >
          OFF
        </span>
      </span>
      <div className={classes.content}>
        <div style={{ fontFamily: "Open Sans" }}>{product?.productName}</div>
      </div>
      <button
        aria-label="comprar ou ver preço"
        type="button"
        className={classes.button}
      >
        Ver preço
      </button>
    </div>
  );
};

export default ProductCard;
