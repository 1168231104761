import React from "react";
import { useSelector } from "react-redux";

import SearchBar from "./SearchBar";
import NavLinks from "./NavLinks";
import UserActions from "./UserActions";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  hamburguer: {
    border: "0 !important",
    "& span": {
      width: "25px",
      height: "19px",
      "& span": {
        height: "2px",
        "&:before": {
          width: "25px",
          height: "2px",
          top: "8px !important",
        },
        "&:after": {
          width: "25px",
          height: "2px",
          top: "16px !important",
        },
      },
    },
  },
  header: {
    zIndex: 5,
    position: "relative",
    background: "#fff",
    width: "100%",
    transition: "all 0.5s ease",
    display: "flex",
    padding: "20px 50px",
  },
  mainLogo: {
    width: "9em",
  },
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 1140,
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const previewSettings = useSelector((state) => state.preview);

  return (
    <>
      <div>
        <div
          style={{ backgroundColor: props.headerBg }}
          className={classes.header}
        >
          <div className={classes.container}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {previewSettings?.logo && (
                <img
                  alt="logo"
                  className={classes.mainLogo}
                  src={
                    typeof previewSettings?.logo === "string"
                      ? previewSettings?.logo
                      : previewSettings?.logo instanceof Blob
                      ? URL.createObjectURL(previewSettings?.logo)
                      : null
                  }
                  style={{ marginRight: "16px" }}
                />
              )}
              <NavLinks />
            </div>
            <div style={{ display: "flex" }}>
              <UserActions />
            </div>
          </div>
        </div>
        {<SearchBar searchCallback={() => {}} />}
      </div>
    </>
  );
};

export default Header;
