import { useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DeleteIcon from "@material-ui/icons/Delete";
import OptionsList from "./OptionsList";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const QuestionsList = ({
  questions,
  setQuestions,
  tabExpanded,
  childTabExpanded,
  disabled,
}) => {
  const [expanded, setExpanded] = useState(tabExpanded);
  const [questionsList, setQuestionsList] = useState(questions);

  useEffect(() => {
    setQuestions(questionsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsList]);

  const addQuestion = () => {
    setQuestionsList([
      ...questionsList,
      {
        id: questionsList.length + 1,
        question: "",
        description: "",
        type: "",
        image: "",
        options: [],
      },
    ]);
  };

  return (
    <>
      <Box id="questions" mb={3}>
        {questionsList.length === 0 && (
          <Box textAlign="center" py={2}>
            <Typography>Nenhuma pergunta criada</Typography>
          </Box>
        )}
        {questionsList.map((question, i) => {
          return (
            <QuestionForm
              expanded={expanded}
              question={question}
              key={question.id ?? i + 1}
              id={question.id ?? i + 1}
              setExpanded={setExpanded}
              questionsList={questionsList}
              setQuestionsList={setQuestionsList}
              tabExpanded={childTabExpanded}
              disabled={disabled}
            />
          );
        })}
      </Box>
      {!disabled && (
        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={addQuestion}
        >
          + Pergunta
        </Button>
      )}
    </>
  );
};

const QuestionForm = ({
  question,
  expanded,
  id,
  setExpanded,
  questionsList,
  setQuestionsList,
  tabExpanded,
  disabled,
}) => {
  const classes = useStyles();
  const [questionData, setQuestionData] = useState(question ?? null);
  const [image, setImage] = useState(question.image);
  const [options, setOptions] = useState(question.options);
  const [removeImage, setRemoveImage] = useState(false);

  const MAX_FILE_SIZE = 2097152; //2Mb

  const types = [
    { value: "", label: "" },
    { value: "text", label: "Texto" },
    { value: "textarea", label: "Texto longo" },
    { value: "radio", label: "Múltipla escolha (Único)" },
    { value: "checkbox", label: "Múltipla escolha (Vários)" },
  ];

  useEffect(() => {
    if (questionData.id) {
      setQuestionsList(
        questionsList.map((question) => {
          return questionData.id === question.id ? questionData : question;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionData]);

  useEffect(() => {
    setQuestionData((question) => {
      if (removeImage) {
        question.image = "_destroy";
      }

      return {
        ...question,
        options,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, removeImage]);

  const handleExpanded = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChange = (event) => {
    setQuestionData((question) => ({
      ...question,
      [`${event.target.name}`]: `${event.target.value}`,
    }));
  };

  const handleImage = (event) => {
    if (event.target.files[0].size > MAX_FILE_SIZE) {
      alert("O tamanho máximo para uma imagem é de 2Mb");
      setImage(null);
    }

    if (
      event.target.files[0].type !== "image/png" &&
      event.target.files[0].type !== "image/jpeg"
    ) {
      alert("O formato da imagem é inválido");
      setImage(null);
    }

    if (
      event.target.files[0].size <= MAX_FILE_SIZE &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg")
    ) {
      setImage(event.target.files[0]);
      setQuestionData((question) => ({
        ...question,
        image: event.target.files[0],
      }));
    }
  };

  const removeQuestion = (id) => {
    setQuestionsList(questionsList.filter((question) => question.id !== id));
  };

  return (
    <Accordion expanded={expanded === id} onChange={handleExpanded(id)}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls={`panel${id}bh-content`}
        id={`panel${id}bh-header`}
        classes={{ content: classes.content }}
        style={{ backgroundColor: "#EEE" }}
      >
        <Typography>
          {questionData.question === ""
            ? "Nova pergunta"
            : questionData.question}
        </Typography>
        {!disabled && <DeleteIcon onClick={() => removeQuestion(id)} />}
      </AccordionSummary>
      <AccordionDetails>
        <Card>
          <CardContent>
            <Grid container spacing={3} pt={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Pergunta"
                  name="question"
                  onChange={handleChange}
                  value={questionData.question}
                  variant="outlined"
                  required
                  disabled={disabled}
                  InputLabelProps={{
                    shrink: questionData.question ? true : false,
                  }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Descrição"
                  name="description"
                  onChange={handleChange}
                  value={questionData.description}
                  variant="outlined"
                  multiline
                  minRows={3}
                  maxRows={5}
                  disabled={disabled}
                  InputLabelProps={{
                    shrink: questionData.description ? true : false,
                  }}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Imagem"
                  margin="normal"
                  name="image"
                  onChange={(e) => {
                    handleChange(e);
                    handleImage(e);
                  }}
                  disabled={disabled}
                  type="file"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {image && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <img
                      src={
                        typeof image === "string"
                          ? image
                          : image instanceof Blob || image instanceof File
                          ? URL.createObjectURL(image)
                          : null
                      }
                      alt="Imagem"
                      style={{
                        maxHeight: "250px",
                        maxWidth: "100%",
                      }}
                    />

                    <Button
                      type="button"
                      size="small"
                      color="secondary"
                      sx={{
                        mx: 1,
                        position: "absolute",
                        bottom: "5px",
                        backgroundColor: "white",
                      }}
                      onClick={() => {
                        setRemoveImage(true);
                        setImage(null);
                      }}
                    >
                      Remover
                    </Button>
                  </Box>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Tipo"
                  name="type"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={questionData.type}
                  variant="outlined"
                >
                  {types.map((type, i) => (
                    <option key={i} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              {(questionData.type === "radio" ||
                questionData.type === "checkbox") && (
                <Grid item md={12} xs={12}>
                  <Typography variant="h5" mb={3}>
                    Opções:
                  </Typography>
                  <OptionsList
                    options={questionData.options ?? []}
                    setOptions={setOptions}
                    tabExpanded={tabExpanded}
                    disabled={disabled}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

export default QuestionsList;
