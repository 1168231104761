import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
  CircularProgress,
} from "@material-ui/core";

import ColorPicker from "utils/colorPicker";
import { getSettings, updateSettings, uploadImages } from "services/api/index";
import { useDispatch, useSelector } from "react-redux";
import { setSettings } from "store/actions/preview";

let formData = new FormData();

const Settings = (props) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.preview);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [requestErrors, setRequestErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    getSettings().then((response) => {
      dispatch(setSettings(response));
    });
  }, [dispatch]);

  const handleChangeColors = (event, group) => {
    dispatch(
      setSettings({
        ...settings,
        [group]: {
          ...settings[group],
          [event.target.name]: event.target.value,
        },
      })
    );
  };

  const handleChange = (event) => {
    if (event?.target?.files) {
      if (event.target.name === "logo_secondary") {
        dispatch(
          setSettings({
            ...settings,
            images: {
              ...settings.images,
              [event.target.name]: event.target.files[0],
            },
            footer_logo: event.target.files[0],
          })
        );
      } else {
        dispatch(
          setSettings({
            ...settings,
            [event.target.name]: event.target.files[0],
            main_logo: event.target.files[0],
          })
        );
      }
    } else {
      dispatch(
        setSettings({
          ...settings,
          [event.target.name]: event.target.value,
        })
      );
    }
  };

  const changeColor = (response) => {
    dispatch(
      setSettings({
        ...settings,
        [response.group]: {
          ...settings[response.group],
          [response.name]: response.color,
        },
      })
    );
  };

  const handleSubmit = async (event) => {
    // const settingsPayload = { ...settings };

    // delete settingsPayload.name;
    const settingsPayload = {
      partner: {
        partner_settings_attributes: {
          primary_color: settings.main_colors.primary_color,
          secondary_color: settings.main_colors.secondary_color,
          primary_text_color: settings.button_colors.primary_text_color,
          primary_background_color:
            settings.button_colors.primary_background_color,
          secondary_text_color: settings.button_colors.secondary_text_color,
          secondary_background_color:
            settings.button_colors.secondary_background_color,
          // logo: settings.logo,
          notifications_text_color:
            settings.notifications.notifications_text_color,
          notifications_background_color:
            settings.notifications.notifications_background_color,
        },
      },
    };
    event.preventDefault();

    setIsSubmitting(true);

    updateSettings(settingsPayload)
      .then((response) => {
        setIsSubmitting(false);
        setShowSuccess(true);
        setSuccessMessage("Configurações salvas com sucesso!");

        settings.footer_logo &&
          formData.append("footer_logo", settings.footer_logo);
        settings.main_logo && formData.append("main_logo", settings.main_logo);

        uploadImages(formData)
          .then((response) => {})
          .catch((error) => {
            console.error({ error });
          });
      })
      .catch((error) => {
        setRequestErrors(["Erro ao se conectar com o servidor"]);
        setShowError(true);
        setIsSubmitting(false);
      });
  };

  const handleCloseAlert = () => {
    setShowError(false);
    setShowSuccess(false);
  };

  return (
    <form onSubmit={handleSubmit} {...props}>
      {settings && (
        <>
          <Card sx={{ mb: 3, overflow: "inherit" }}>
            <CardHeader title="Cores Principais" />
            <Divider />
            <CardContent>
              <Grid container wrap="wrap" spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Cor primária"
                    margin="normal"
                    name="primary_color"
                    onChange={(e) => handleChangeColors(e, "main_colors")}
                    type="text"
                    value={settings?.main_colors?.primary_color}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ColorPicker
                            initialColor={settings?.main_colors?.primary_color}
                            changeColor={changeColor}
                            group="main_colors"
                            name="primary_color"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Cor secundária"
                    margin="normal"
                    name="secondary_color"
                    onChange={(e) => handleChangeColors(e, "main_colors")}
                    type="text"
                    value={settings?.main_colors?.secondary_color}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ColorPicker
                            initialColor={
                              settings?.main_colors?.secondary_color
                            }
                            changeColor={changeColor}
                            group="main_colors"
                            name="secondary_color"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mb: 3, overflow: "inherit" }}>
            <CardHeader title="Botão Principal" />
            <Divider />
            <CardContent>
              <Grid container wrap="wrap" spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Cor do texto"
                    margin="normal"
                    name="primary_button_text_color"
                    onChange={(e) => handleChangeColors(e, "button_colors")}
                    type="text"
                    value={settings?.button_colors?.primary_text_color}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ColorPicker
                            initialColor={
                              settings?.button_colors?.primary_text_color
                            }
                            changeColor={changeColor}
                            group="button_colors"
                            name="primary_text_color"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Cor de fundo"
                    margin="normal"
                    name="primary_button_bg_color"
                    onChange={(e) => handleChangeColors(e, "button_colors")}
                    type="text"
                    value={settings?.button_colors?.primary_background_color}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ColorPicker
                            initialColor={
                              settings?.button_colors?.primary_background_color
                            }
                            changeColor={changeColor}
                            group="button_colors"
                            name="primary_background_color"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mb: 3, overflow: "inherit" }}>
            <CardHeader title="Botão Secundário" />
            <Divider />
            <CardContent>
              <Grid container wrap="wrap" spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Cor do texto"
                    margin="normal"
                    name="secondary_button_text_color"
                    onChange={(e) => handleChangeColors(e, "button_colors")}
                    type="text"
                    value={settings?.button_colors?.secondary_text_color}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ColorPicker
                            initialColor={
                              settings?.button_colors?.secondary_text_color
                            }
                            changeColor={changeColor}
                            group="button_colors"
                            name="secondary_text_color"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Cor de fundo"
                    margin="normal"
                    name="secondary_button_bg_color"
                    onChange={(e) => handleChangeColors(e, "button_colors")}
                    type="text"
                    value={settings?.button_colors?.secondary_background_color}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ColorPicker
                            initialColor={
                              settings?.button_colors
                                ?.secondary_background_color
                            }
                            changeColor={changeColor}
                            group="button_colors"
                            name="secondary_background_color"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mb: 3, overflow: "inherit" }}>
            <CardHeader title="Imagens" />
            <Divider />
            <CardContent>
              <Grid container wrap="wrap" spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Logo principal"
                    margin="normal"
                    name="logo"
                    onChange={handleChange}
                    type="file"
                    value=""
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {settings?.logo && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={
                          typeof settings?.logo === "string"
                            ? settings?.logo
                            : settings?.logo instanceof Blob ||
                              settings?.logo instanceof File
                            ? URL.createObjectURL(settings?.logo)
                            : null
                        }
                        alt="Logo principal"
                        style={{ maxHeight: "100px", maxWidth: "100%" }}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Logo do Rodapé"
                    margin="normal"
                    name="logo_secondary"
                    onChange={handleChange}
                    type="file"
                    value=""
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  {settings?.images?.logo_secondary && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {typeof settings?.images?.logo_secondary === "string" ? (
                        <img
                          src={settings?.images?.logo_secondary}
                          alt="Logo principal"
                          style={{ maxHeight: "100px", maxWidth: "100%" }}
                        />
                      ) : (
                        settings?.images?.logo_secondary instanceof Blob && (
                          <img
                            src={URL.createObjectURL(
                              settings?.images?.logo_secondary
                            )}
                            alt="Logo principal"
                            style={{ maxHeight: "100px", maxWidth: "100%" }}
                          />
                        )
                      )}
                    </Box>
                  )}
                </Grid>
                {/* <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Fundo da Seção de Newsletter"
                    margin="normal"
                    name="newsletter_bg"
                    onChange={handleChange}
                    type="file"
                    value=""
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {settings?.images?.secondary_image_footer && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={settings?.images?.secondary_image_footer}
                        alt="Fundo da seção de Newsletter"
                        style={{ maxHeight: "100px", maxWidth: "100%" }}
                      />
                    </Box>
                  )}
                </Grid> */}
                {/* <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Fundo do Rodapé"
                    margin="normal"
                    name="footer_bg"
                    onChange={handleChange}
                    type="file"
                    value=""
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {settings?.images?.primary_image_footer && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={settings?.images?.primary_image_footer}
                        alt="Fundo do rodapé"
                        style={{ maxHeight: "100px", maxWidth: "100%" }}
                      />
                    </Box>
                  )}
                </Grid> */}
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mb: 3, overflow: "inherit" }}>
            <CardHeader title="Notificações" />
            <Divider />
            <CardContent>
              <Grid container wrap="wrap" spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Cor do texto"
                    margin="normal"
                    name="notifications_text"
                    onChange={(e) => handleChangeColors(e, "notifications")}
                    type="text"
                    value={settings?.notifications?.notifications_text_color}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ColorPicker
                            initialColor={
                              settings?.notifications?.notifications_text_color
                            }
                            changeColor={changeColor}
                            group="notifications"
                            name="notifications_text_color"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Cor de Fundo"
                    margin="normal"
                    name="notifications_bg"
                    onChange={(e) => handleChangeColors(e, "notifications")}
                    type="text"
                    value={
                      settings?.notifications?.notifications_background_color
                    }
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ColorPicker
                            initialColor={
                              settings?.notifications
                                ?.notifications_background_color
                            }
                            changeColor={changeColor}
                            group="notifications"
                            name="notifications_background_color"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  {/* <TextField
                    fullWidth
                    label="Imagem de Fundo"
                    margin="normal"
                    name="notifications_bg_image"
                    onChange={handleChange}
                    type="file"
                    value=""
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {settings?.notifications?.notifications_background_image && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={
                          settings?.notifications
                            ?.notifications_background_image
                        }
                        alt="Imagem de fundo"
                        style={{ maxHeight: "100px", maxWidth: "100%" }}
                      />
                    </Box>
                  )} */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              pl: 2,
              pr: 2,
              pb: 2,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? <CircularProgress size={24} /> : "Salvar"}
            </Button>
          </Box>

          {showError && (
            <Snackbar
              open={showError}
              autoHideDuration={3000}
              onClose={handleCloseAlert}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert severity="error">
                {requestErrors && (
                  <ul style={{ listStyleType: "none" }}>
                    {requestErrors.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                )}
              </Alert>
            </Snackbar>
          )}

          {showSuccess && (
            <Snackbar
              open={showSuccess}
              autoHideDuration={3000}
              onClose={handleCloseAlert}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert severity="success">{successMessage}</Alert>
            </Snackbar>
          )}
        </>
      )}
    </form>
  );
};

export default Settings;
