import * as Actions from "store/actions/user";

function saveHeadersToLocalStorage(headers) {
  localStorage.setItem("APU_header", JSON.stringify(headers));
}

function savePlanToLocalStorage(plan) {
  localStorage.setItem("APU_Plan", JSON.stringify(plan));
}

function removeHeadersFromLocalStorage() {
  localStorage.removeItem("APU_header");
}

function getPlanFromLocalStorage() {
  return JSON.parse(localStorage.getItem("APU_Plan"));
}

function removePlanFromLocalStorage() {
  localStorage.removeItem("APU_Plan");
}

const INITIAL_STATE = {
  headers: null,
  info: null,
  plan: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Actions.SET_HEADER_INFO: {
      saveHeadersToLocalStorage(action.payload);
      return { ...state, headers: action.payload };
    }
    case Actions.SET_USER_INFO: {
      const plan = getPlanFromLocalStorage();

      return { ...state, info: action.payload, plan };
    }
    case Actions.USER_LOGOUT: {
      removeHeadersFromLocalStorage();
      removePlanFromLocalStorage();
      return INITIAL_STATE;
    }
    case Actions.USER_LOGIN: {
      saveHeadersToLocalStorage(action.headers);
      savePlanToLocalStorage(action.plan);
      return {
        ...state,
        info: action.user,
        headers: action.headers,
        plan: action.plan,
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
