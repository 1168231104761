import { combineReducers } from "redux";
import user from "./user";
import preview from "./preview";

const createReducer = (asyncReducers) =>
  combineReducers({
    user: user,
    preview: preview,
    ...asyncReducers,
  });

export default createReducer;
