import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import "moment/locale/pt-br";

import { Box, Container, Grid, Divider } from "@material-ui/core";
import { green, red, indigo, grey } from "@material-ui/core/colors";
import LayersIcon from "@material-ui/icons/Layers";

import scrollTo from "utils/scrollTo";
import Toolbar from "components/layout/Toolbar";
import OrderView from "components/order/OrderView";
import OrderList from "components/order/OrderList";
import Graph from "components/widgets/Graph";
import Summary from "components/widgets/Summary";
import SimpleTableList from "components/widgets/SimpleTableList";
import MapChart from "components/widgets/MapChart";

import {
  getOrdersLatest,
  getOrdersSummary,
  getPaymentMethodOrders,
  getOrdersByState,
} from "services/api/index";
import SummarySkeleton from "components/widgets/SummarySkeleton";
import GraphSkeleton from "components/widgets/GraphSkeleton";
import SimpleTableListSkeleton from "components/widgets/SimpleTableListSkeleton";
import MapChartSkeleton from "components/widgets/MapChartSkeleton";

const Order = (props) => {
  moment().locale("pt-br");
  const { action } = props;
  const [latestNewOrders, setLatestNewOrders] = useState(null);
  const [latestRejectedOrders, setLatestRejectedOrders] = useState(null);
  const [ordersSummary, setOrdersSummary] = useState(null);
  const [paymentMethodOrders, setPaymentMethodOrders] = useState(null);
  const [paymentMethodOrdersFormat, setPaymentMethodOrdersFormat] =
    useState(null);
  const [ordersByState, setOrdersByState] = useState(null);

  useEffect(() => {
    scrollTo("#topPage");

    getOrdersSummary().then((response) => {
      setOrdersSummary([
        {
          originalLabel: "delivered",
          label: "Entregues",
          value: response.delivered,
          icon: <LayersIcon />,
          color: green[600],
        },
        {
          originalLabel: "rejected",
          label: "Rejeitados",
          value: response.rejected,
          icon: <LayersIcon />,
          color: red[600],
        },
        {
          originalLabel: "saved",
          label: "Recebidos",
          value: parseInt(response.saved),
          icon: <LayersIcon />,
          color: indigo[600],
        },
        {
          originalLabel: "cancelled",
          label: "Cancelados",
          value: response.cancelled,
          icon: <LayersIcon />,
          color: grey[600],
        },
      ]);

      getOrdersByState().then((response) => {
        setOrdersByState(
          response.map((item) => ({
            key: item.state,
            value: item.info.count,
          }))
        );
      });
    });

    getOrdersLatest(15, { status: "delivered" }).then((response) => {
      var array = [...new Array(15)]
        .map((i, idx) => moment().startOf("day").subtract(idx, "days"))
        .reverse()
        .map((day) => {
          let ordersArray = response.filter(function (el) {
            return (
              moment(el.day).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")
            );
          });

          if (ordersArray.length > 0) {
            return ordersArray[0].count;
          } else {
            return 0;
          }
        });
      setLatestNewOrders(array);
    });

    getOrdersLatest(15, { status: "rejected" }).then((response) => {
      var array = [...new Array(15)]
        .map((i, idx) => moment().startOf("day").subtract(idx, "days"))
        .reverse()
        .map((day) => {
          let ordersArray = response.filter(function (el) {
            return (
              moment(el.day).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")
            );
          });
          if (ordersArray.length > 0) {
            return ordersArray[0].count;
          } else {
            return 0;
          }
        });
      setLatestRejectedOrders(array);
    });

    getPaymentMethodOrders().then((response) => {
      var paymentOrdersArray = [];

      var paymentOrdersFormatArray = [
        {
          key: "Boleto",
          slug: "bank_slip",
        },
        {
          key: "Cartão de Crédito",
          slug: "credit_card",
        },
        {
          key: "Pix",
          slug: "pix",
        },
        {
          key: "Ame",
          slug: "ame",
        },
        {
          key: "PicPay",
          slug: "picpay",
        },
      ];

      Object.keys(response).forEach(function (key) {
        if (key === "bank_slip") {
          paymentOrdersArray.push({
            key: "Boleto",
            value: response[key],
          });
        }

        if (key === "credit_card") {
          paymentOrdersArray.push({
            key: "Cartão de Crédito",
            value: response[key],
          });
        }

        if (key === "pix") {
          paymentOrdersArray.push({
            key: "Pix",
            value: response[key],
          });
        }

        if (key === "ame") {
          paymentOrdersArray.push({
            key: "Ame",
            value: response[key],
          });
        }

        if (key === "picpay") {
          paymentOrdersArray.push({
            key: "PicPay",
            value: response[key],
          });
        }
      });

      setPaymentMethodOrders(paymentOrdersArray);
      setPaymentMethodOrdersFormat(paymentOrdersFormatArray);
    });
  }, []);

  return (
    <>
      <Helmet>
        {action === "view" ? (
          <title>Pedido | +Alegria</title>
        ) : (
          <title>Pedidos | +Alegria</title>
        )}
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
        id="topPage"
      >
        <Container maxWidth="lg">
          {props.type === "view" ? (
            <Toolbar
              name="Pedido"
              root="orders"
              buttons={[{ label: "Voltar", action: `/` }]}
            />
          ) : (
            <>
              <Toolbar name="Pedidos" root="orders" />

              <Grid container spacing={3} sx={{ pt: 3 }}>
                <Grid item lg={6} md={12} xs={12}>
                  {!ordersSummary ? (
                    <SummarySkeleton
                      numberOfItems={4}
                      avatarSize={46}
                      spacing={1}
                    />
                  ) : (
                    <Summary
                      title="Resumo"
                      items={ordersSummary}
                      hasFilter={true}
                      apiFunction={getOrdersSummary}
                      icon={LayersIcon}
                      labelTag="h5"
                      valueTag="h6"
                      avatarSize={46}
                      spacing={1}
                    />
                  )}
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  {!latestNewOrders || !latestRejectedOrders ? (
                    <GraphSkeleton />
                  ) : (
                    <Graph
                      title="Últimos pedidos"
                      itemsLabel="Pedidos entregues"
                      itemsLabelExtra="Pedidos rejeitados"
                      items={latestNewOrders}
                      itemsExtra={latestRejectedOrders}
                      apiFunction={getOrdersLatest}
                      status="delivered"
                      statusExtra="rejected"
                    />
                  )}
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  {!paymentMethodOrders || !paymentMethodOrdersFormat ? (
                    <SimpleTableListSkeleton />
                  ) : (
                    <SimpleTableList
                      title="Meios de pagamento"
                      items={paymentMethodOrders}
                      hasFilter={true}
                      apiFunction={getPaymentMethodOrders}
                      itemsFormat={paymentMethodOrdersFormat}
                    />
                  )}
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  {!ordersByState ? (
                    <MapChartSkeleton />
                  ) : (
                    <MapChart
                      title="Pedidos entregues por estado"
                      items={ordersByState}
                      cellValue="orders"
                      apiFunction={getOrdersByState}
                    />
                  )}
                </Grid>
              </Grid>
            </>
          )}
          <Grid container spacing={3} sx={{ pt: 3 }}>
            <Grid item lg={12} md={12} xs={12}>
              {props.type === "view" ? (
                <OrderView />
              ) : (
                <>
                  <Divider sx={{ mb: 5, mt: 2 }} />
                  <OrderList />
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Order;
