import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, IconButton, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
// import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import Logo from "./Logo";
import { useSettings } from "hooks/useSettings";

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  // const [notifications] = useState([]);
  const { isSettings } = useSettings();

  const mobileNavStyle = () => {
    if (isSettings) return { xs: "block", md: "block", lg: "block" };

    return { xs: "block", md: "none", lg: "none" };
  };
  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar
        sx={{
          padding: "10px",
          borderBottom: "7px solid",
          borderColor: "primary.alternative",
        }}
      >
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box display={{ xs: "block", md: "block", lg: "block" }}>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton color="inherit">
            <RouterLink to="/logout">
              <InputIcon sx={{ color: "white" }} />
            </RouterLink>
          </IconButton>
        </Box>
        <Box display={mobileNavStyle}>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
