import React from "react";

import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  button: {
    color: (props) => props.secondaryTextColor,
    textTransform: "none",
    backgroundColor: (props) => props.secondaryBackgroundColor,
    borderRadius: "2.25rem",
    fontSize: ".8em",
    padding: "0.8em 3em",
    fontWeight: 700,
    boxShadow: "2px 2px 5px #ccc",
    border: 0,
    transition: ".3s",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
const SecondaryButton = () => {
  const previewSettings = useSelector((state) => state.preview);
  const classes = useStyles({
    secondaryTextColor: previewSettings?.button_colors?.secondary_text_color,
    secondaryBackgroundColor:
      previewSettings?.button_colors?.secondary_background_color,
  });
  return (
    <button
      aria-label="comprar ou ver preço"
      type="button"
      className={classes.button}
    >
      ADICIONAR
    </button>
  );
};

export default SecondaryButton;
