import { Navigate } from "react-router-dom";
import DashboardLayout from "components/layout/DashboardLayout";
import Account from "pages/Account";
import Customer from "pages/Customer";
// import GiftVoucher from "pages/GiftVoucher";
import Order from "pages/Order";
import Dashboard from "pages/Dashboard";
import Settings from "pages/Settings";
import MainLayout from "components/layout/MainLayout";
import Login from "pages/Login";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";
import Logout from "pages/Logout";
import Invoice from "pages/Invoice";
import ProtectedRoute from "components/ProtectedRoute";
import Plans from "pages/Plans";
import Quiz from "pages/Quiz";
import QuizAnswer from "pages/QuizAnswer";
import Survey from "pages/Survey";
import SurveyAnswer from "pages/SurveyAnswer";

const routes = (isLoggedIn, user) => {
  const userHeader = JSON.parse(localStorage.getItem("APU_header"));

  if (isLoggedIn) {
    return [
      {
        path: "/",
        element: <DashboardLayout />,
        children: [
          {
            path: "account",
            element: <Account />,
          },
          {
            path: "customers",
            children: [
              {
                path: "/",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.manage_users}>
                    <Customer />
                  </ProtectedRoute>
                ),
              },

              {
                path: "new",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.manage_users}>
                    <Customer action="new" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "edit/:id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.manage_users}>
                    <Customer action="edit" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "import",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.manage_users}>
                    <Customer action="import" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "change-password/:id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.manage_users}>
                    <Customer action="change-password" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "block/:id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.manage_users}>
                    <Customer />
                  </ProtectedRoute>
                ),
              },
              {
                path: "unblock/:id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.manage_users}>
                    <Customer />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.manage_users}>
                    <Customer action="view" />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: "orders",
            children: [
              {
                path: "/",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.orders}>
                    <Order />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.orders}>
                    <Order type="view" action="view" />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: "invoices",
            children: [
              {
                path: "/",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.billing}>
                    <Invoice />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.billing}>
                    <Invoice type="view" action="view" />
                  </ProtectedRoute>
                ),
              },
            ],
          },

          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "settings",
            element: (
              <ProtectedRoute user={user?.plan?.settings?.configurations}>
                <Settings />
              </ProtectedRoute>
            ),
          },
          {
            path: "/plans",
            element: (
              <ProtectedRoute user={user?.plan?.settings?.my_plan}>
                <Plans />
              </ProtectedRoute>
            ),
          },
          {
            path: "quizzes",
            children: [
              {
                path: "/",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.quizzes}>
                    <Quiz />
                  </ProtectedRoute>
                ),
              },
              {
                path: "new",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.quizzes}>
                    <Quiz action="new" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "edit/:id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.quizzes}>
                    <Quiz action="edit" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "activate/:id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.quizzes}>
                    <Quiz />
                  </ProtectedRoute>
                ),
              },
              {
                path: "deactivate/:id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.quizzes}>
                    <Quiz />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.quizzes}>
                    <Quiz action="view" />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: "quizzes/answers",
            children: [
              {
                path: ":id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.quizzes}>
                    <QuizAnswer action="view" />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: "surveys",
            children: [
              {
                path: "/",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.surveys}>
                    <Survey />
                  </ProtectedRoute>
                ),
              },
              {
                path: "new",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.surveys}>
                    <Survey action="new" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "edit/:id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.surveys}>
                    <Survey action="edit" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "activate/:id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.surveys}>
                    <Survey />
                  </ProtectedRoute>
                ),
              },
              {
                path: "deactivate/:id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.surveys}>
                    <Survey />
                  </ProtectedRoute>
                ),
              },
              {
                path: ":id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.surveys}>
                    <Survey action="view" />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: "surveys/answers",
            children: [
              {
                path: ":id",
                element: (
                  <ProtectedRoute user={user?.plan?.settings?.surveys}>
                    <SurveyAnswer action="view" />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          { path: "/", element: <Navigate to="/dashboard" /> },
          { path: "*", element: <Navigate to="/dashboard" /> },
        ],
      },
      {
        path: "/",
        element: <MainLayout />,
        children: [{ path: "logout", element: <Logout /> }],
      },
    ];
  } else if (!userHeader) {
    return [
      {
        path: "/",
        element: <MainLayout />,
        children: [
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "forgot",
            element: <ForgotPassword />,
          },
          {
            path: "reset/:token",
            element: <ResetPassword />,
          },
          { path: "/", element: <Navigate to="/login" /> },
          { path: "*", element: <Navigate to="/login" /> },
        ],
      },
    ];
  } else {
    return [];
  }
};

export default routes;
