import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import * as Yup from "yup";

import { updateUser } from "services/api/index";

const CustomerPassword = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { customer } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestErrors, setRequestErrors] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    if (Array.isArray(requestErrors) && requestErrors.length > 0) {
      setShowError(true);
    }
  }, [requestErrors]);

  const handleCloseAlert = () => {
    setShowError(false);
    setShowSuccess(false);
  };

  const onSubmit = async (customer) => {
    setIsSubmitting(true);
    updateUser(customer, id)
      .then((response) => {
        if (response.success) {
          setIsSubmitting(false);
          setShowSuccess(true);
          setSuccessMessage("Senha atualizada com sucesso!");
        } else {
          if (response.errors) {
            setRequestErrors(response.errors);
            setIsSubmitting(false);
          } else {
            setRequestErrors([response.message]);
            setIsSubmitting(false);
          }
        }
      })
      .catch((err) => {
        setRequestErrors(["Erro ao se conectar com o servidor"]);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      {customer && (
        <Formik
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .oneOf(
                [Yup.ref("confirm_password"), null],
                "As senhas não correspondem"
              )
              .min(8, "A senha deve ter no mínimo 8 dígitos"),
            confirm_password: Yup.string().oneOf(
              [Yup.ref("password"), null],
              "As senhas não correspondem"
            ),
          })}
          initialValues={{
            password: "",
            confirm_password: "",
          }}
          onSubmit={(customer) => {
            onSubmit(customer);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardContent>
                  <Grid container spacing={3} pt={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        fullWidth
                        label="Senha"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        variant="outlined"
                        type="password"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.confirm_password && errors.confirm_password
                        )}
                        helperText={
                          touched.confirm_password && errors.confirm_password
                        }
                        fullWidth
                        label="Confirmar Senha"
                        name="confirm_password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirm_password}
                        variant="outlined"
                        type="password"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Salvar
                  </Button>
                  <Button
                    type="button"
                    sx={{ mx: 1 }}
                    onClick={() => navigate(`/customers/edit/${id}`)}
                  >
                    Voltar
                  </Button>
                </Box>
              </Card>
              {showError && (
                <Snackbar
                  open={showError}
                  autoHideDuration={3000}
                  onClose={handleCloseAlert}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert severity="error">
                    {requestErrors && (
                      <ul style={{ listStyleType: "none" }}>
                        {requestErrors.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    )}
                  </Alert>
                </Snackbar>
              )}
              {showSuccess && (
                <Snackbar
                  open={showSuccess}
                  autoHideDuration={3000}
                  onClose={handleCloseAlert}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert severity="success">{successMessage}</Alert>
                </Snackbar>
              )}
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default CustomerPassword;
