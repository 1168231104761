import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Snackbar,
  Skeleton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";

import { updateUser, treatRequestErrors } from "services/api/index";

const CustomerEdit = (props) => {
  const { id } = useParams();
  const { benefitTiers, customer } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestErrors, setRequestErrors] = useState([]);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  const gender = [
    { value: "", label: "" },
    { value: "m", label: "Masculino" },
    { value: "f", label: "Feminino" },
    { value: "n", label: "Prefiro não dizer" },
  ];

  const states = [
    { value: "", label: "" },
    { value: "AC", label: "Acre" },
    { value: "AL", label: "Alagoas" },
    { value: "AP", label: "Amapá" },
    { value: "AM", label: "Amazonas" },
    { value: "BA", label: "Bahia" },
    { value: "CE", label: "Ceará" },
    { value: "DF", label: "Distrito Federal" },
    { value: "ES", label: "Espírito Santo" },
    { value: "GO", label: "Goiás" },
    { value: "MA", label: "Maranhão" },
    { value: "MT", label: "Mato Grosso" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "MG", label: "Minas Gerais" },
    { value: "PA", label: "Pará" },
    { value: "PB", label: "Paraíba" },
    { value: "PR", label: "Paraná" },
    { value: "PE", label: "Pernambuco" },
    { value: "PI", label: "Piauí" },
    { value: "RR", label: "Roraima" },
    { value: "RO", label: "Rondônia" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "RN", label: "Rio Grande do Norte" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "SC", label: "Santa Catarina" },
    { value: "SP", label: "São Paulo" },
    { value: "SE", label: "Sergipe" },
    { value: "TO", label: "Tocantins" },
  ];

  useEffect(() => {
    if (Array.isArray(requestErrors) && requestErrors.length > 0) {
      setShowError(true);
    }
  }, [requestErrors]);

  const handleCloseAlert = () => {
    setShowError(false);
  };

  const onSubmit = async (customer) => {
    setIsSubmitting(true);

    delete customer.cpf;

    updateUser(customer, id)
      .then((response) => {
        if (response.success) {
          setIsSubmitting(false);
          navigate(`/customers/${id}?success=1`);
        } else {
          if (response.errors) {
            setRequestErrors(response.errors);
            setIsSubmitting(false);
          } else {
            setRequestErrors(treatRequestErrors(response.message));
            setIsSubmitting(false);
          }
        }
      })
      .catch((err) => {
        setRequestErrors(["Erro ao se conectar com o servidor"]);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      {!customer ? (
        <CustomerEditSkeleton />
      ) : (
        <Formik
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required("O nome é obrigatório"),
            email: Yup.string()
              .email("Deve ser um e-mail válido")
              .max(255)
              .required("O e-mail é obrigatório"),
            cpf: Yup.string().max(255).required("O CPF é obrigatório"),
            benefit_tier_id: Yup.string()
              .max(255)
              .required("A segmentação é obrigatória"),
            gender: Yup.string().max(255).required("O gênero é obrigatório"),
            state: Yup.string().max(255).required("O estado é obrigatório"),
            city: Yup.string().max(255).required("A cidade é obrigatória"),
            mobile_number: Yup.string()
              .max(255)
              .required("O telefone é obrigatório"),
          })}
          initialValues={{
            name: customer.name || "",
            email: customer.email || "",
            cpf:
              `${customer.cpf.slice(0, 3)}.${customer.cpf.slice(
                3,
                6
              )}.${customer.cpf.slice(6, 9)}-${customer.cpf.slice(9, 11)}` ||
              "",
            benefit_tier_id:
              customer.benefit_tier?.id || customer.benefit_tier_id || "",
            gender: customer.gender || "",
            mobile_number: customer.mobile_number || "",
            state: customer.state || "",
            city: customer.city || "",
          }}
          onSubmit={(customer) => {
            onSubmit(customer);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardContent>
                  <Grid container spacing={3} pt={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        fullWidth
                        label="Nome"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.cpf && errors.cpf)}
                        helperText={touched.cpf && errors.cpf}
                        fullWidth
                        label="CPF"
                        name="cpf"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.cpf}
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        fullWidth
                        label="E-mail"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.benefit_tier && errors.benefit_tier
                        )}
                        helperText={touched.benefit_tier && errors.benefit_tier}
                        fullWidth
                        label="Segmentação"
                        name="benefit_tier_id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.benefit_tier_id}
                        variant="outlined"
                      >
                        {benefitTiers.map((option, i) => (
                          <option key={i} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Gênero"
                        name="gender"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        required
                        SelectProps={{ native: true }}
                        value={values.gender}
                        variant="outlined"
                        disabled={true}
                      >
                        {gender.map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <InputMask
                        mask="(99) 99999-9999"
                        maskChar=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.mobile_number}
                        disabled={true}
                      >
                        {() => (
                          <TextField
                            disabled={true}
                            fullWidth
                            label="Telefone"
                            name="mobile_number"
                            required
                            variant="outlined"
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Estado"
                        name="state"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        required
                        SelectProps={{ native: true }}
                        value={values.state}
                        variant="outlined"
                      >
                        {states.map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Cidade"
                        required
                        name="city"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.city}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Salvar
                  </Button>
                  <Button
                    type="button"
                    sx={{ mx: 1 }}
                    onClick={() => navigate(`/customers/${id}`)}
                  >
                    Voltar
                  </Button>
                </Box>
              </Card>
              {showError && (
                <Snackbar
                  open={showError}
                  autoHideDuration={3000}
                  onClose={handleCloseAlert}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert severity="error">
                    {requestErrors && (
                      <ul style={{ listStyleType: "none" }}>
                        {requestErrors.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    )}
                  </Alert>
                </Snackbar>
              )}
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default CustomerEdit;

const CustomerEditSkeleton = () => {
  return (
    <div>
      <Card>
        <CardContent>
          <Grid container spacing={3} pt={3}>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton variant="rect" height={"53px"} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Salvar
          </Button>
          <Button type="button" sx={{ mx: 1 }}>
            Voltar
          </Button>
        </Box>
      </Card>
    </div>
  );
};
