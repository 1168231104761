import { useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  FormControlLabel,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Checkbox,
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const ResultsList = ({
  results,
  setResults,
  hasPrize,
  tabExpanded,
  disabled,
}) => {
  const [expanded, setExpanded] = useState(tabExpanded);
  const [resultsList, setResultsList] = useState(results);

  useEffect(() => {
    setResults(resultsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsList]);

  const addResult = () => {
    setResultsList([
      ...resultsList,
      {
        id: resultsList.length + 1,
        result: "",
        description: "",
        min_points: "",
        max_points: "",
        finish_button: "",
        image: "",
        winning: false,
      },
    ]);
  };

  return (
    <>
      <Box id="results" mb={3}>
        {resultsList.length === 0 && (
          <Box textAlign="center" py={2}>
            <Typography>Nenhum resultado criado</Typography>
          </Box>
        )}
        {resultsList.map((result, i) => {
          return (
            <ResultForm
              expanded={expanded}
              result={result}
              id={result.id ?? i + 1}
              key={result.id ?? i + 1}
              setExpanded={setExpanded}
              resultsList={resultsList}
              setResultsList={setResultsList}
              hasPrize={hasPrize}
              disabled={disabled}
            />
          );
        })}
      </Box>
      {!disabled && (
        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={addResult}
        >
          + Resultado
        </Button>
      )}
    </>
  );
};

const ResultForm = ({
  result,
  expanded,
  id,
  setExpanded,
  resultsList,
  setResultsList,
  hasPrize,
  disabled,
}) => {
  const classes = useStyles();
  const [resultData, setResultData] = useState(result);
  const [image, setImage] = useState(result.image);
  const [checked, setChecked] = useState(result.winning);
  const [removeImage, setRemoveImage] = useState(false);

  const MAX_FILE_SIZE = 2097152; //2Mb

  useEffect(() => {
    if (resultData.id) {
      setResultsList(
        resultsList.map((result) =>
          resultData.id === result.id ? resultData : result
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultData]);

  const handleExpanded = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleWinningField = (event) => {
    setChecked(event.target.checked);
    setResultData((result) => {
      if (removeImage) {
        result.image = "_destroy";
      }

      return {
        ...result,
        [`${event.target.name}`]: event.target.checked ? true : false,
      };
    });
  };

  const handleChange = (event) => {
    setResultData((result) => ({
      ...result,
      [`${event.target.name}`]: `${event.target.value}`,
    }));
  };

  const handleImage = (event) => {
    if (event.target.files[0].size > MAX_FILE_SIZE) {
      alert("O tamanho máximo para uma imagem é de 2Mb");
      setImage(null);
    }

    if (
      event.target.files[0].type !== "image/png" &&
      event.target.files[0].type !== "image/jpeg"
    ) {
      alert("O formato da imagem é inválido");
      setImage(null);
    }

    if (
      event.target.files[0].size <= MAX_FILE_SIZE &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg")
    ) {
      setImage(event.target.files[0]);
      setResultData((result) => ({
        ...result,
        image: event.target.files[0],
      }));
    }
  };

  const removeResult = (id) => {
    setResultsList(resultsList.filter((result) => result.id !== id));
  };

  return (
    <Accordion expanded={expanded === id} onChange={handleExpanded(id)}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls={`panel${id}bh-content`}
        id={`panel${id}bh-header`}
        classes={{ content: classes.content }}
        style={{
          backgroundColor: "#EEE",
        }}
      >
        <Typography>
          {resultData.result === "" ? "Novo resultado" : resultData.result}
        </Typography>
        {!disabled && <DeleteIcon onClick={() => removeResult(id)} />}
      </AccordionSummary>
      <AccordionDetails>
        <Card>
          <CardContent>
            <Grid container spacing={3} pt={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Resultado"
                  name="result"
                  required
                  onChange={handleChange}
                  value={resultData.result}
                  variant="outlined"
                  disabled={disabled}
                  InputLabelProps={{
                    shrink: resultData.result ? true : false,
                  }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Descrição"
                  name="description"
                  onChange={handleChange}
                  value={resultData.description}
                  variant="outlined"
                  multiline
                  minRows={3}
                  maxRows={5}
                  disabled={disabled}
                  InputLabelProps={{
                    shrink: resultData.description ? true : false,
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Mínimo de pontos"
                  name="min_points"
                  required
                  onChange={handleChange}
                  value={resultData.min_points}
                  variant="outlined"
                  type="number"
                  disabled={disabled}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: resultData.min_points?.toString() ? true : false,
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Máximo de pontos"
                  name="max_points"
                  required
                  type="number"
                  onChange={handleChange}
                  value={resultData.max_points}
                  variant="outlined"
                  disabled={disabled}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: resultData.max_points?.toString() ? true : false,
                  }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Texto do botão"
                  name="finish_button"
                  required
                  onChange={handleChange}
                  value={resultData.finish_button}
                  variant="outlined"
                  disabled={disabled}
                  InputLabelProps={{
                    shrink: resultData.finish_button ? true : false,
                  }}
                  InputProps={{
                    inputProps: { maxLength: 16 },
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Imagem"
                  margin="normal"
                  name="image"
                  onChange={(e) => {
                    handleChange(e);
                    handleImage(e);
                  }}
                  type="file"
                  variant="outlined"
                  disabled={disabled}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {image && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <img
                      src={
                        typeof image === "string"
                          ? image
                          : image instanceof Blob || image instanceof File
                          ? URL.createObjectURL(image)
                          : null
                      }
                      alt="Imagem"
                      style={{
                        maxHeight: "250px",
                        maxWidth: "100%",
                      }}
                    />

                    <Button
                      type="button"
                      size="small"
                      color="secondary"
                      sx={{
                        mx: 1,
                        position: "absolute",
                        bottom: "5px",
                        backgroundColor: "white",
                      }}
                      onClick={() => {
                        setRemoveImage(true);
                        setImage(null);
                      }}
                    >
                      Remover
                    </Button>
                  </Box>
                )}
              </Grid>
              {hasPrize && (
                <Grid item md={12} xs={12} pt={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        label="Título"
                        name="winning"
                        onChange={handleWinningField}
                        checked={checked}
                        value={resultData.winning}
                        variant="outlined"
                        disabled={disabled}
                      />
                    }
                    label="Resultado premiado"
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

export default ResultsList;
