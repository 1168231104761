import { useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  FormControlLabel,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Checkbox,
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const OptionsList = ({ options, setOptions, tabExpanded, disabled }) => {
  const [expanded, setExpanded] = useState(tabExpanded);
  const [optionsList, setOptionsList] = useState(options);

  useEffect(() => {
    setOptions(optionsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsList]);

  const addOption = () => {
    setOptionsList([
      ...optionsList,
      {
        id: optionsList.length + 1,
        option: "",
        correct: false,
      },
    ]);
  };

  return (
    <>
      <Box id="options" mb={3}>
        {optionsList.length === 0 && (
          <Box textAlign="center" py={2}>
            <Typography>Nenhuma resposta criada</Typography>
          </Box>
        )}
        {optionsList?.map((option, i) => {
          return (
            <OptionForm
              expanded={expanded}
              option={option}
              id={option.id ?? i + 1}
              key={option.id ?? i + 1}
              setExpanded={setExpanded}
              optionsList={optionsList}
              setOptionsList={setOptionsList}
              disabled={disabled}
            />
          );
        })}
      </Box>
      {!disabled && (
        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={addOption}
        >
          + Resposta
        </Button>
      )}
    </>
  );
};

const OptionForm = ({
  option,
  expanded,
  id,
  setExpanded,
  optionsList,
  setOptionsList,
  disabled,
}) => {
  const classes = useStyles();
  const [optionData, setOptionData] = useState(option ?? null);
  const [checked, setChecked] = useState(option.correct);

  useEffect(() => {
    if (optionData.id) {
      setOptionsList(
        optionsList.map((option) =>
          optionData.id === option.id ? optionData : option
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionData]);

  const handleExpanded = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChange = (event) => {
    setOptionData((option) => ({
      ...option,
      [`${event.target.name}`]: `${event.target.value}`,
    }));
  };

  const handleCorrectField = (event) => {
    setChecked(event.target.checked);
    setOptionData((option) => ({
      ...option,
      [`${event.target.name}`]: event.target.checked ? true : false,
    }));
  };

  const removeOption = (id) => {
    setOptionsList(optionsList.filter((option) => option.id !== id));
  };

  return (
    <Accordion expanded={expanded === id} onChange={handleExpanded(id)}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls={`panel${id}bh-content`}
        id={`panel${id}bh-header`}
        classes={{ content: classes.content }}
        style={{ backgroundColor: "#EEE" }}
      >
        <Typography>
          {optionData.option === "" ? "Nova resposta" : optionData.option}
        </Typography>
        {!disabled && <DeleteIcon onClick={() => removeOption(id)} />}
      </AccordionSummary>
      <AccordionDetails>
        <Card>
          <CardContent>
            <Grid container spacing={3} pt={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Resposta"
                  name="option"
                  required
                  onChange={handleChange}
                  value={optionData.option}
                  variant="outlined"
                  disabled={disabled}
                  InputLabelProps={{
                    shrink: optionData.option ? true : false,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item md={12} xs={12} pt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    label="Título"
                    name="correct"
                    onChange={handleCorrectField}
                    checked={checked}
                    disabled={disabled}
                    value={optionData.correct}
                    variant="outlined"
                  />
                }
                label="Resposta correta"
              />
            </Grid>
          </CardContent>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

export default OptionsList;
