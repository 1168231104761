import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    textTransform: "uppercase",
    "&:focus": {
      borderBottom: "0px !important",
    },
  },
  selectRoot: {
    //...other styles
    "&:focus": {
      backgroundColor: "yellow",
    },
  },
  select: {
    marginTop: "4px",
    fontWeight: "bold !important",
    fontSize: "12px !important",
    "&:before": {
      borderBottom: "0px !important",
    },
    "&:after": {
      borderBottom: "0px !important",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const MapChart = (props) => {
  const { title, items, cols, colValue, cellValue, hasFilter, apiFunction } =
    props;
  const classes = useStyles();
  const [selected, setSelected] = useState("Todos");
  const [interval, setInterval] = useState(15);
  const [dataItems, setDataItems] = useState(items);

  useEffect(() => {
    const statesArray =
      selected === "Todos"
        ? dataItems.map((item, i) => [
            [item.key],
            item.object
              ? item.object.reduce((sum, count) => {
                  return sum + count.count;
                }, 0)
              : item.value,
          ])
        : dataItems.map((item, i) => {
            let cell = item.object
              .filter(function (el) {
                return el[colValue] === selected;
              })
              .map((info, index) => info[cellValue]);
            return [[item.key], cell.length > 0 ? cell[0] : 0];
          });

    const statesObject = new Map(statesArray);
    const obj = Object.fromEntries(statesObject);

    const dataBrazil = [
      ["br-sp", obj.SP ?? 0],
      ["br-ma", obj.MA ?? 0],
      ["br-pa", obj.PA ?? 0],
      ["br-sc", obj.SC ?? 0],
      ["br-ba", obj.BA ?? 0],
      ["br-ap", obj.AP ?? 0],
      ["br-ms", obj.MS ?? 0],
      ["br-mg", obj.MG ?? 0],
      ["br-go", obj.GO ?? 0],
      ["br-rs", obj.RS ?? 0],
      ["br-to", obj.TO ?? 0],
      ["br-pi", obj.PI ?? 0],
      ["br-al", obj.AL ?? 0],
      ["br-pb", obj.PB ?? 0],
      ["br-ce", obj.CE ?? 0],
      ["br-se", obj.SE ?? 0],
      ["br-rr", obj.RR ?? 0],
      ["br-pe", obj.PE ?? 0],
      ["br-pr", obj.PR ?? 0],
      ["br-es", obj.ES ?? 0],
      ["br-rj", obj.RJ ?? 0],
      ["br-rn", obj.RN ?? 0],
      ["br-am", obj.AM ?? 0],
      ["br-mt", obj.MT ?? 0],
      ["br-df", obj.DF ?? 0],
      ["br-ac", obj.AC ?? 0],
      ["br-ro", obj.RO ?? 0],
    ];

    // Create the chart
    window.Highcharts.mapChart("container", {
      chart: {
        map: "countries/br/br-all",
      },

      title: {
        text: "",
      },

      subtitle: {
        text: "",
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: "bottom",
        },
      },

      colorAxis: {
        min: 0,
      },

      series: [
        {
          data: dataBrazil,
          name: "Usuários " + selected,
          states: {
            hover: {
              color: "#df941f",
            },
          },
          dataLabels: {
            enabled: false,
            format: "{point.name}",
          },
        },
      ],
    });
  }, [selected, colValue, cellValue, dataItems]);

  useEffect(() => {
    if (hasFilter) {
      apiFunction(interval).then((response) => {
        setDataItems(
          response.map((item) => ({
            key: item.state,
            value: item.info.count,
          }))
        );
      });
    }
  }, [interval, apiFunction, hasFilter]);

  const handleBtn = (col) => {
    setSelected(col);
  };

  const handleChange = (event) => {
    setInterval(event.target.value);
  };

  return (
    <>
      <Card sx={{ height: "100%" }}>
        {hasFilter ? (
          <CardHeader
            title={title}
            action={
              <>
                <FormControl variant="standard" className={classes.formControl}>
                  <Select
                    id="interval"
                    value={interval}
                    onChange={handleChange}
                    className={classes.select}
                  >
                    <MenuItem value={15}>Últimos 15 dias</MenuItem>
                    <MenuItem value={30}>Últimos 30 dias</MenuItem>
                    <MenuItem value={60}>Últimos 60 dias</MenuItem>
                  </Select>
                </FormControl>
              </>
            }
          />
        ) : (
          <CardHeader title={title} />
        )}
        <Divider />
        <CardContent>
          <Box sx={{ justifyContent: "center", display: "flex" }}>
            {cols && (
              <Button
                color="primary"
                variant={"Todos" === selected ? "contained" : ""}
                sx={{ margin: "10px 2px 15px 2px" }}
                onClick={() => handleBtn("Todos")}
              >
                Todos
              </Button>
            )}
            {cols &&
              cols.map((col, j) => (
                <Button
                  key={j}
                  color="primary"
                  variant={col.name === selected ? "contained" : ""}
                  sx={{ margin: "10px 2px 15px 2px" }}
                  onClick={() => handleBtn(col.name)}
                >
                  {col.name}
                </Button>
              ))}
          </Box>
          <div id="container"></div>
        </CardContent>
      </Card>
    </>
  );
};

export default MapChart;
