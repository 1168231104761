import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Skeleton,
  Typography,
} from "@material-ui/core";

import { getSurveyAnswer } from "services/api/index";

const SurveyAnswerView = (props) => {
  const { id } = useParams();
  const [surveyAnswer, setSurveyAnswer] = useState(null);

  useEffect(() => {
    getSurveyAnswer(id).then((response) => {
      if (Object.keys(response).length > 0) setSurveyAnswer(response);
    });
  }, [id]);

  return (
    <form autoComplete="off" noValidate {...props}>
      {!surveyAnswer ? (
        <SurveyAnswerViewSkeleton />
      ) : (
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card sx={{ mt: 3 }}>
                  <Grid container p={2} spacing={3}>
                    <Grid item xs={6}>
                      <Box pb={2}>
                        <Typography color="textSecondary" variant="h5">
                          Dados do Cliente
                        </Typography>
                      </Box>
                      <Table border={1} bordercolor="#F4F6F8">
                        <TableBody>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              Nome
                            </TableCell>
                            <TableCell>{surveyAnswer.user.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              CPF
                            </TableCell>
                            <TableCell>
                              {" "}
                              {`${surveyAnswer.user.cpf.slice(
                                0,
                                3
                              )}.${surveyAnswer.user.cpf.slice(
                                3,
                                6
                              )}.${surveyAnswer.user.cpf.slice(
                                6,
                                9
                              )}-${surveyAnswer.user.cpf.slice(9, 11)}`}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              E-mail
                            </TableCell>
                            <TableCell>{surveyAnswer.user.email}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              Telefone
                            </TableCell>
                            <TableCell>
                              {surveyAnswer.user.mobile_number}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              Segmento
                            </TableCell>
                            <TableCell>
                              {surveyAnswer.user.benefit_tier?.name}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid item xs={6}>
                      <Box pb={2}>
                        <Typography color="textSecondary" variant="h5">
                          Dados da Pesquisa
                        </Typography>
                      </Box>
                      <Table border={1} bordercolor="#F4F6F8">
                        <TableBody>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              Finalizado
                            </TableCell>
                            <TableCell>
                              {surveyAnswer.finished ? "Sim" : "Não"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              width="35%"
                              sx={{ backgroundColor: "#F6F6F6" }}
                            >
                              Respondido em
                            </TableCell>
                            <TableCell>
                              {moment(surveyAnswer.created_at).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12}>
                {surveyAnswer.answers?.length > 0 && (
                  <Card sx={{ mt: 3 }}>
                    <CardHeader title="Respostas do Usuário"></CardHeader>
                    <PerfectScrollbar>
                      <Box sx={{ minWidth: 960 }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ wordBreak: "break-all" }}>
                                Pergunta
                              </TableCell>
                              <TableCell style={{ wordBreak: "break-all" }}>
                                Resposta
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {surveyAnswer.answers.map((answer, i) => (
                              <TableRow hover key={i}>
                                <TableCell>{answer.question_text}</TableCell>
                                <TableCell>{answer.option_text}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </PerfectScrollbar>
                  </Card>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </form>
  );
};

const SurveyAnswerViewSkeleton = () => {
  return (
    <form autoComplete="off" noValidate>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Table border={1} bordercolor="#F4F6F8">
                <TableBody>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Nome
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Área
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Criado em
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Status
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "initial",
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          padding: "5px 15px",
                          textAlign: "center",
                          textTransform: "uppercase",
                        }}
                      >
                        <Skeleton variant="text" />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default SurveyAnswerView;
