import { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  FormControl,
  Select,
  MenuItem,
  Skeleton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    textTransform: "uppercase",
    "&:focus": {
      borderBottom: "0px !important",
    },
  },
  selectRoot: {
    //...other styles
    "&:focus": {
      backgroundColor: "yellow",
    },
  },
  select: {
    marginTop: "4px",
    fontWeight: "bold !important",
    fontSize: "12px !important",
    "&:before": {
      borderBottom: "0px !important",
    },
    "&:after": {
      borderBottom: "0px !important",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SimpleTableListSkeleton = (props) => {
  const { numberOfItems, cols, hasFilter } = props;
  const [interval, setInterval] = useState(15);
  // eslint-disable-next-line no-unused-vars
  const [dataItems, setDataItems] = useState(() => {
    const array = Array.from({ length: numberOfItems }, (_, i) => i + 1);

    return array;
  });
  const classes = useStyles();

  const handleChange = (event) => {
    setInterval(event.target.value);
  };

  return (
    <>
      <Card sx={{ height: "100%" }}>
        {hasFilter ? (
          <CardHeader
            action={
              <>
                <FormControl variant="standard" className={classes.formControl}>
                  <Select
                    id="interval"
                    value={interval}
                    onChange={handleChange}
                    className={classes.select}
                  >
                    <MenuItem value={15}>
                      <Skeleton variant="text" />
                    </MenuItem>
                    <Skeleton variant="text" />
                    <MenuItem value={30}></MenuItem>
                    <Skeleton variant="text" />
                    <MenuItem value={60}></MenuItem>
                  </Select>
                </FormControl>
              </>
            }
            title={<Skeleton variant="text" />}
          />
        ) : (
          <CardHeader title={<Skeleton variant="text" />} />
        )}
        <Divider />
        <CardContent>
          <Box
            sx={{
              maxHeight: 280,
              position: "relative",
              overflowY: "auto",
            }}
          >
            <Table>
              {cols && (
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {cols.map((col, j) => (
                      <TableCell align="center" key={j}>
                        {col}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {dataItems &&
                  dataItems.map((_, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <strong>
                          <Skeleton variant="text" />
                        </strong>
                      </TableCell>
                      {cols ? (
                        <></>
                      ) : (
                        // cols.map((col, j) => {
                        //   let cell = item.object
                        //     .filter(function (el) {
                        //       return el[colValue] === col;
                        //     })
                        //     .map((info, index) => info[cellValue]);
                        //   return (
                        //     <TableCell key={j} align="center">
                        //       {cell.length > 0 ? cell[0] : 0}
                        //     </TableCell>
                        //   );
                        // })
                        <TableCell align="center">
                          <Skeleton variant="text" />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default SimpleTableListSkeleton;
