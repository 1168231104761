import { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";

import { getDashboardCustomers } from "services/api";
import DashboardSkeleton from "./DashboardSkeleton";

const Customers = (props) => {
  const [customers, setCustomers] = useState(null);
  const [arrow, setArrow] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [actualMonth, setActualMonth] = useState(null);

  useEffect(() => {
    getDashboardCustomers().then((response) => {
      setActualMonth(moment().format("MMM/YY"));
      setCustomers(response);

      if (response.actual_month > response.last_month) {
        let increase = response.actual_month - response.last_month;
        let difference = (increase / response.last_month) * 100;
        setArrow("up");
        if (isNaN(difference) || !response.last_month) {
          setPercentage("0%");
        } else {
          setPercentage(`${difference.toFixed(2)}%`);
        }
      } else {
        let decrease = response.last_month - response.actual_month;
        let difference = (decrease / response.last_month) * 100;
        setArrow("down");

        if (isNaN(difference) || !response.last_month) {
          setPercentage("0%");
        } else {
          setPercentage(`${difference.toFixed(2)}%`);
        }
      }
    });
  }, []);

  return !customers ? (
    <DashboardSkeleton />
  ) : (
    <Card sx={{ height: "100%" }} {...props}>
      {customers && (
        <CardContent>
          <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h4"
                sx={{ borderBottom: "1px solid #F4F4F4", pb: 1 }}
              >
                Usuários
              </Typography>
              <Typography color="textPrimary" variant="h5">
                {customers.actual_month}
                <Typography
                  color="textSecondary"
                  variant="caption"
                  sx={{ pl: 1 }}
                >
                  ({actualMonth})
                </Typography>
              </Typography>

              {percentage &&
                isNaN(percentage) &&
                percentage !== "0%" &&
                percentage !== "0.00%" && (
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      pt: 2,
                    }}
                  >
                    {arrow === "up" ? (
                      <>
                        <ArrowUpwardIcon sx={{ color: green[900] }} />
                        <Typography
                          variant="body2"
                          sx={{
                            color: green[900],
                            mr: 1,
                          }}
                        >
                          {percentage}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <ArrowDownwardIcon sx={{ color: red[900] }} />
                        <Typography
                          variant="body2"
                          sx={{
                            color: red[900],
                            mr: 1,
                          }}
                        >
                          {percentage}
                        </Typography>
                      </>
                    )}

                    <Typography color="textSecondary" variant="caption">
                      mês passado
                    </Typography>
                  </Box>
                )}
              <Typography color="textPrimary" variant="h4" sx={{ mt: 2 }}>
                <Typography color="textSecondary" variant="caption">
                  Total:
                </Typography>{" "}
                {customers.total}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                sx={{
                  backgroundColor: green[600],
                  height: 56,
                  width: 56,
                }}
              >
                <PeopleIcon />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

export default Customers;
