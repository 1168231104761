export default function validateQuiz(quiz) {
  var isInvalid = false;
  var error = "";
  var expanded;
  var childExpanded;
  var type;

  // Validar obrigatoriedade do campo de questões

  if (quiz.questions.length === 0) {
    return {
      type: "question",
      success: false,
      tab: 1,
      error: "A criação de perguntas é obrigatória",
    };
  }

  // Validar obrigatoriedade do campo de resultados

  if (quiz.results.length === 0) {
    return {
      type: "result",
      success: false,
      tab: 2,
      error: "A criação de resultados é obrigatória",
    };
  }

  // Validar obrigatoriedade do campo de segmento

  if (quiz.benefit_tier_ids.length === 0) {
    return {
      success: false,
      tab: 0,
      field: "benefit_tier",
      error: "O campo segmentação é obrigatório",
    };
  }

  delete quiz.benefit_tier;

  // Cada pergunta precisa possuir pelo menos duas respostas

  quiz.questions?.forEach((question, i) => {
    if (question.options.length < 2) {
      expanded = i + 1;
      isInvalid = true;
      return;
    }
  });

  if (isInvalid) {
    return {
      type: "question",
      success: false,
      expanded,
      tab: 1,
      error: "Cada pergunta precisa possuir pelo menos duas respostas",
    };
  }

  // Validação de preenchimento dos campos

  quiz.questions?.forEach((question, i) => {
    if (question.question === "") {
      type = "question";
      expanded = i + 1;
      error = "O campo 'Título' das perguntas é obrigatório";
      isInvalid = true;
      return;
    }

    if (
      question.options?.filter((option) => option.correct).length === 0 ||
      question.options?.filter((option) => option.correct).length > 1
    ) {
      type = "question";
      expanded = i + 1;
      isInvalid = true;
      error =
        "Uma das perguntas está sem respostas corretas ou com mais de uma marcada";
      return;
    }

    question.options?.forEach((option, z) => {
      if (option.option === "") {
        type = "option";
        expanded = i + 1;
        childExpanded = z + 1;
        isInvalid = true;
        error = "O campo 'Título' das respostas é obrigatório";
        return;
      }
    });
  });

  if (isInvalid) {
    return {
      type,
      success: false,
      tab: 1,
      expanded,
      childExpanded,
      error,
    };
  }

  // Ao menos um dos resultados precisa ser premiado

  if (
    quiz.has_prize &&
    quiz.results?.filter((result) => result.winning).length === 0
  ) {
    return {
      type: "result",
      success: false,
      tab: 2,
      error: "Ao menos um dos resultados precisa ser premiado",
    };
  }

  // Um dos resultados está com a pontuação superior ao número de questões

  if (
    quiz.results?.filter((result) => result.max_points > quiz.questions.length)
      .length > 0 ||
    quiz.results?.filter((result) => result.min_points > quiz.questions.length)
      .length > 0
  ) {
    return {
      type: "result",
      success: false,
      tab: 2,
      error:
        "Um dos resultados está com a pontuação superior ao número de questões",
    };
  }

  var resultsMinPoints = [];
  var resultsMaxPoints = [];
  var points = [];
  var pointsRange = [];

  quiz.results?.forEach((result, index) => {
    if (result.result === "") {
      expanded = index + 1;
      error = "O campo 'Título' dos resultados é obrigatório";
      isInvalid = true;
      return;
    }

    if (result.min_points === "") {
      expanded = index + 1;
      error = "O campo 'Mínimo de pontos' dos resultados é obrigatório";
      isInvalid = true;
      return;
    }

    if (result.max_points === "") {
      expanded = index + 1;
      error = "O campo 'Máximo de pontos' dos resultados é obrigatório";
      isInvalid = true;
      return;
    }

    if (result.finish_button === "") {
      expanded = index + 1;
      error = "O campo 'Texto do botão' dos resultados é obrigatório";
      isInvalid = true;
      return;
    }
  });

  if (isInvalid) {
    return {
      type: "result",
      success: false,
      expanded,
      tab: 2,
      error,
    };
  }

  quiz.results?.forEach((result, index) => {
    // Um ou mais resultados está com a pontuação repetida ou o mesmo intervalo de pontos

    if (
      resultsMaxPoints.includes(parseInt(result.max_points)) ||
      resultsMinPoints.includes(parseInt(result.min_points)) ||
      resultsMaxPoints.includes(parseInt(result.min_points)) ||
      resultsMinPoints.includes(parseInt(result.max_points))
    ) {
      expanded = index + 1;
      error =
        "Um ou mais resultados está com a pontuação repetida ou o mesmo intervalo de pontos";
      isInvalid = true;
      return;
    }

    if (
      points.length === 0 &&
      parseInt(result.min_points) > parseInt(result.max_points)
    ) {
      expanded = index + 1;
      error = "Um ou mais resultados está com a pontuação incorreta";
      isInvalid = true;
      return;
    }

    points.forEach((point) => {
      if (
        (parseInt(point.min_points) >= parseInt(result.min_points) &&
          parseInt(point.min_points) <= parseInt(result.max_points)) ||
        (parseInt(point.max_points) >= parseInt(result.min_points) &&
          parseInt(point.max_points) <= parseInt(result.max_points))
      ) {
        expanded = index + 1;
        error = "Um ou mais resultados está com a pontuação incorreta";
        isInvalid = true;
        return;
      }
    });

    resultsMinPoints.push(parseInt(result.min_points));
    resultsMaxPoints.push(parseInt(result.max_points));

    points.push({
      min_points: parseInt(result.min_points),
      max_points: parseInt(result.max_points),
    });

    var count = parseInt(result.min_points);

    while (count <= parseInt(result.max_points)) {
      pointsRange.push(count);
      count++;
    }
  });

  if (isInvalid) {
    return {
      type: "result",
      success: false,
      expanded,
      tab: 2,
      error,
    };
  }

  for (var i = 0; i < quiz.questions.length; i++) {
    // Uma ou mais possibilidades de pontuação não estão sendo contempladas
    if (!pointsRange.includes(i + 1)) {
      type = "result";
      error =
        "Uma ou mais possibilidades de pontuação não estão sendo contempladas";
      isInvalid = true;
      break;
    }
  }

  if (isInvalid) {
    return {
      type: "result",
      success: false,
      expanded,
      tab: 2,
      error,
    };
  }

  // Um dos resultados precisa contemplar o caso em que o usuário erra de todas as questões

  if (pointsRange.length > 0 && !pointsRange.includes(0)) {
    return {
      type: "result",
      success: false,
      tab: 2,
      error:
        "Um dos resultados precisa contemplar o caso em que o usuário erra de todas as questões",
    };
  }

  return { success: true };
}
