import ProductCard from "./ProductCard";

const { default: StyledTittle } = require("./StyleTittle");

const products = [
  {
    discount: 39,
    image:
      "https://clube-alegria-prod.s3.us-east-2.amazonaws.com/c0dcbf86-0ecb-4bf8-9770-c9d8af0873f4",
    productName: "CINEMARK - SALAS 2D",
  },
  {
    discount: 11,
    image:
      "https://clube-alegria-prod.s3.us-east-2.amazonaws.com/4e277c76-73ed-4ef3-a0bf-af719b1149b4",
    productName: "Show internacional | Roger Waters - Rio de Janeiro",
  },
  {
    discount: 40,
    image:
      "https://clube-alegria-prod.s3.us-east-2.amazonaws.com/d5d8e92f-9e2d-4cdd-b35a-ed5144121800",
    productName: "CINEMA UCI | SALAS 2D",
  },
  {
    discount: 35,
    image:
      "https://clube-alegria-prod.s3.us-east-2.amazonaws.com/edeade25-8755-420c-ab56-ea7bfe544d72",
    productName: "CINEMA KINOPLEX - SALAS 2D",
  },
  {
    discount: 35,
    image:
      "https://clube-alegria-prod.s3.us-east-2.amazonaws.com/e5977c7c-7ebe-4274-940d-46f260c058ed",
    productName: "CINEMA GNC | SALAS 2D",
  },
  // Add more product objects here
];

const OfferHighlights = () => {
  return (
    <div style={{ marginTop: "32px" }}>
      <StyledTittle title="Destaques" />

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {products.map((product, i) => (
          <ProductCard key={i} product={product} />
        ))}
      </div>
    </div>
  );
};

export default OfferHighlights;
