import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Card,
  Snackbar,
  CardContent,
  Divider,
  Box,
  CardHeader,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import ImportNew from "components/customerImport/importNew";
import ImportList from "components/customerImport/importList";
import ImportView from "components/customerImport/importView";
import ImportEdit from "components/customerImport/importEdit";

const CustomerMassiveActions = ({ customers, ...rest }) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const [showSuccess, setShowSuccess] = useState(Boolean(query.get("success")));
  const [successMessage, setSuccessMessage] = useState("");
  const [showImportNew, setShowImportNew] = useState(false);
  const [showImportEdit, setShowImportEdit] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCloseAlert = () => {
    setShowSuccess(false);
  };

  return (
    <>
      <Card sx={{ mb: 3, overflow: "inherit" }} id="topTable">
        <CardContent>
          <>
            {loading && <CustomerMassiveActionsSkeleton />}
            {showImportNew ? (
              <ImportNew
                setShowSuccess={setShowSuccess}
                setSuccessMessage={setSuccessMessage}
                showImportNew={showImportNew}
                setShowImportNew={setShowImportNew}
              />
            ) : showImport ? (
              <ImportView
                setShowSuccess={setShowSuccess}
                setSuccessMessage={setSuccessMessage}
                showImport={showImport}
                setShowImport={setShowImport}
                id={id}
              />
            ) : showImportEdit ? (
              <ImportEdit
                setShowSuccess={setShowSuccess}
                setSuccessMessage={setSuccessMessage}
                showImportEdit={showImportEdit}
                setShowImportEdit={setShowImportEdit}
                id={id}
              />
            ) : (
              <ImportList
                setShowSuccess={setShowSuccess}
                setSuccessMessage={setSuccessMessage}
                showImportNew={showImportNew}
                setShowImportNew={setShowImportNew}
                showImportEdit={showImportEdit}
                setShowImportEdit={setShowImportEdit}
                showImport={showImport}
                setShowImport={setShowImport}
                setId={setId}
                setLoading={setLoading}
              />
            )}
            {showSuccess && (
              <Snackbar
                open={showSuccess}
                autoHideDuration={3000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Alert severity="success">{successMessage}</Alert>
              </Snackbar>
            )}
          </>
        </CardContent>
      </Card>
    </>
  );
};

export default CustomerMassiveActions;

const CustomerMassiveActionsSkeleton = () => {
  // eslint-disable-next-line no-unused-vars
  const [importNumber, setImportNumber] = useState([...Array(10).keys()]);
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <CardHeader title="Importações" sx={{ padding: "10px" }}></CardHeader>
        <Box>
          <Button
            color="primary"
            variant="outlined"
            type="button"
            sx={{ marginLeft: "20px" }}
          >
            Nova Importação
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="button"
            sx={{ marginLeft: "5px" }}
          >
            Voltar
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ minWidth: 960, mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {importNumber.map((_, i) => (
              <TableRow key={i} hover>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="right">
                  <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    type="button"
                    sx={{
                      marginLeft: "5px",
                      padding: "0px 9px",
                    }}
                  >
                    <Skeleton variant="text" width={30} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
