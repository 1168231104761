import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Card } from "@material-ui/core";
import scrollTo from "utils/scrollTo";
import Toolbar from "components/layout/Toolbar";
import SettingsForm from "components/settings/SettingsForm";

import PSFirstSection from "components/PreviewSettings/PSFirstSection";
import PSSecondSection from "components/PreviewSettings/PSSecondSection";
import PSThirdSection from "components/PreviewSettings/PSThirdSection";
import PSFourthSection from "components/PreviewSettings/PSFourthSection";

const Settings = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      scrollTo("#topPage");
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Configurações | +Alegria</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
        id="topPage"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ p: 2, width: "100%" }}>
            <PSFirstSection />
            <PSSecondSection />
            <PSThirdSection />
            <PSFourthSection />
          </Box>
          <Card sx={{ mt: 2, p: 2, maxWidth: 350 }}>
            <Toolbar name="Configurações" root="settings" />
            <Box sx={{ mt: 3 }}>
              <SettingsForm />
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default Settings;
