import * as Actions from "store/actions/preview";

const INITIAL_STATE = {
  name: "",
  logo: "",
  main_colors: {
    primary_color: "",
    hover_primary_color: "",
    secondary_color: "",
    hover_secondary_color: "",
    background_color: "",
    link_color: "",
    link_hover_color: "",
    title_color: "",
  },
  button_colors: {
    primary_text_color: "",
    primary_hover_text_color: "",
    primary_background_color: "",
    primary_hover_background_color: "",
    secondary_background_color: "",
    secondary_hover_background_color: "",
    secondary_text_color: "",
    secondary_hover_text_color: "",
  },
  payment_info: {
    payment_options: [],
  },
  images: {
    logo_secondary: "",
    primary_image_footer: "",
    secondary_image_footer: "",
  },
  documents: {
    terms_and_conditions: "",
    privacy_politic: "",
  },
  notifications: {
    notifications_text_color: "",
    notifications_background_color: "",
    notifications_background_image: "",
  },
};

const initialState = Actions.loadReducerFromLocalStorage() || INITIAL_STATE;
const previewReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_SETTINGS: {
      const newState = { ...action.settings };
      Actions.saveReducerToLocalStorage(newState); // Save the updated state
      return newState;
    }

    default: {
      return state;
    }
  }
};

export default previewReducer;
