import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  ul: {
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    padding: 0,
  },
  li: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontFamily: "Open Sans",
    color: (props) => props.primaryColor,
  },
}));

const NavLinks = () => {
  const previewSettings = useSelector((state) => state.preview);
  const classes = useStyles({
    primaryColor: previewSettings.main_colors.primary_color,
  });

  return (
    <div className={classes.container}>
      <ul className={classes.ul}>
        {["OFERTAS", "DÚVIDAS"].map((link, index) => (
          <li key={index} className={classes.li}>
            {link}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavLinks;
