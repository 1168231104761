import { useState, useEffect } from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate, useLocation } from "react-router-dom";
import { green, grey } from "@material-ui/core/colors";
import GetAppIcon from "@material-ui/icons/GetApp";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";

import scrollTo from "utils/scrollTo";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Snackbar,
  CardContent,
  TextField,
  Button,
  Typography,
  Grid,
  Skeleton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { getQuizzes, exportDataQuiz, activateQuiz } from "services/api/index";

const QuizList = ({ quizzes, ...rest }) => {
  const navigate = useNavigate();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const [limit, setLimit] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [quizList, setQuizzesList] = useState(null);
  const [filters, setFilters] = useState({ name: "", status: "" });
  const [requestErrors, setRequestErrors] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(Boolean(query.get("success")));
  const [showSuccessMessage, setShowSuccessMessage] = useState(
    "Quiz criado com sucesso!"
  );
  const [showFilters, setShowFilters] = useState(false);
  const [btnFilterLabel, setBtnFilterLabel] = useState("Exibir filtros");

  const [anchorEls, setAnchorEls] = useState([]);

  const handleClickMenu = (event, index) => {
    const newAnchorEls = anchorEls.slice();
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleClose = (index) => {
    const newAnchorEls = anchorEls.slice();
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    scrollTo("#topTable");
  };

  const handleClick = (id) => {
    if (id["$oid"]) {
      id = id["$oid"];
    }

    navigate(`/quizzes/${id}`);
  };

  const handleCloseAlert = () => {
    setShowSuccess(false);
    setShowError(false);
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
    showFilters
      ? setBtnFilterLabel("Exibir filtros")
      : setBtnFilterLabel("Ocultar filtros");
  };

  const handleClickFilters = () => {
    setQuizzesList(null);
    getQuizzes(page + 1, 10, filters).then((response) => {
      setQuizzesList(response.quizzes);
      setAnchorEls(Array(response.quizzes?.length).fill(null));
      setTotal(response.pagination.total);
      setLimit(10);
    });
  };

  const handleClearFilters = () => {
    setQuizzesList(null);
    setFilters({ name: "", status: "" });
    handleShowFilters();
    getQuizzes(page + 1, 10).then((response) => {
      setQuizzesList(response.quizzes);
      setAnchorEls(Array(response.quizzes?.length).fill(null));
      setTotal(response.pagination.total);
      setLimit(10);
    });
  };

  const exportData = (id) => {
    exportDataQuiz(id);
  };

  useEffect(() => {
    setQuizzesList(null);
    getQuizzes(page + 1, 10).then((response) => {
      setQuizzesList(response.quizzes);
      setAnchorEls(Array(response.quizzes?.length).fill(null));
      setTotal(response.pagination.total);
      setLimit(10);
    });
  }, [page]);

  const handleChange = (event) => {
    setFilters((filters) => ({
      ...filters,
      [`${event.target.name}`]: `${event.target.value}`,
    }));
  };

  const activeQuiz = (status, id) => {
    activateQuiz({ status }, id)
      .then((response) => {
        if (response.success) {
          getQuizzes(page + 1, 10).then((response) => {
            setQuizzesList(response.quizzes);
            setAnchorEls(Array(response.quizzes?.length).fill(null));
            setTotal(response.pagination.total);
            setLimit(10);
            setShowSuccess(true);
            setShowSuccessMessage(
              status === "active"
                ? "Quiz ativado com sucesso!"
                : "Quiz desativado com sucesso!"
            );
          });
        } else {
          if (response.errors) {
            setShowError(true);
            setRequestErrors(response.errors);
          } else {
            setShowError(true);
            setRequestErrors([response.message]);
          }
        }
      })
      .catch((err) => {
        setShowError(true);
        setRequestErrors(["Erro ao se conectar com o servidor"]);
      });
  };

  return (
    <Card {...rest} id="topTable">
      <Card>
        <CardContent>
          <Box>
            <Typography color="textSecondary" variant="h4">
              Lista de Quizzes
              <Button
                color="primary"
                variant={showFilters ? "outlined" : "contained"}
                type="button"
                onClick={handleShowFilters}
                sx={{ marginLeft: "20px" }}
              >
                {btnFilterLabel}
              </Button>
            </Typography>
          </Box>
          {showFilters && (
            <Box>
              <Grid container spacing={3} pt={3}>
                <Grid item lg={3} md={6} sm={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    label="Pesquisar pelo nome"
                    name="name"
                    value={filters.name}
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={12}>
                  <TextField
                    fullWidth
                    label="Pesquisar pelo status"
                    name="status"
                    onChange={handleChange}
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    value={filters.status}
                    variant="outlined"
                  >
                    <option value="">Todos</option>
                    <option value="active">Ativo</option>
                    <option value="inactive">Inativo</option>
                  </TextField>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={6}
                  sm={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    type="button"
                    onClick={handleClearFilters}
                    sx={{ marginRight: "5px" }}
                  >
                    Limpar
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={handleClickFilters}
                  >
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>

      <PerfectScrollbar>
        <Box sx={{ minWidth: 960 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="center">Área</TableCell>
                <TableCell align="center">Segmentos</TableCell>
                <TableCell align="center">Respostas</TableCell>
                <TableCell align="center">Data Inicial</TableCell>
                <TableCell align="center">Data Final</TableCell>
                <TableCell align="center">Criado em</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!quizList ? (
                <QuizListSkeleton />
              ) : (
                <>
                  {quizList.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        Nenhum dado encontrado
                      </TableCell>
                    </TableRow>
                  ) : (
                    quizList.slice(0, limit).map((quiz, i) => (
                      <TableRow
                        hover
                        key={i}
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleClick(quiz.id)}
                      >
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              wordBreak: "break-all",
                            }}
                          >
                            {quiz.name}
                          </Box>
                        </TableCell>
                        <TableCell align="center">{quiz.area}</TableCell>
                        <TableCell align="center">
                          {quiz.benefit_tiers
                            ?.map((benefit_tier) => benefit_tier.name)
                            .join(", ")}
                        </TableCell>
                        <TableCell align="center">
                          {quiz.completed_answers ?? 0}
                        </TableCell>
                        <TableCell align="center">
                          {quiz.start_date &&
                            moment(quiz.start_date).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {quiz.end_date &&
                            moment(quiz.end_date).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {moment(quiz.created_at).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              color:
                                quiz.status === "Inativo"
                                  ? "#666666"
                                  : quiz.status === "Ativo"
                                  ? "#FFFFFF"
                                  : "#666666",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                              padding: "5px",
                              textAlign: "center",
                              textTransform: "uppercase",
                              backgroundColor:
                                quiz.status === "Inativo"
                                  ? grey[200]
                                  : quiz.status === "Ativo"
                                  ? green[600]
                                  : grey[200],
                            }}
                          >
                            {quiz.status}
                          </Box>
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={(e) => e.stopPropagation()}
                          title="Ações"
                        >
                          <Button
                            id={`menu-button-${quiz.id}`}
                            aria-controls={
                              anchorEls[i] ? `menu-${quiz.id}` : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={anchorEls[i] ? "true" : undefined}
                            onClick={(event) => handleClickMenu(event, i)}
                          >
                            <MoreHorizIcon />
                          </Button>
                          <Menu
                            id={`menu-${quiz.id}`}
                            anchorEl={anchorEls[i]}
                            open={Boolean(anchorEls[i])}
                            onClose={() => handleClose(i)}
                            MenuListProps={{
                              "aria-labelledby": `menu-button-${i.id}`,
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleClose(i);
                                exportData(quiz.id);
                              }}
                            >
                              <ListItemIcon>
                                <GetAppIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Exportar</ListItemText>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleClose(i);
                                activeQuiz(
                                  quiz.status === "Ativo"
                                    ? "inactive"
                                    : "active",
                                  quiz.id
                                );
                              }}
                            >
                              <ListItemIcon>
                                <PowerSettingsNewIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>
                                {quiz.status === "Ativo"
                                  ? "Desativar"
                                  : "Ativar"}
                              </ListItemText>
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
        labelRowsPerPage={"Quizzes por página"}
        labelDisplayedRows={({ from, to, count }) => {
          return "" + from + "-" + to + " de " + count;
        }}
      />
      {showSuccess && (
        <Snackbar
          open={showSuccess}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="success">{showSuccessMessage}</Alert>
        </Snackbar>
      )}
      {showError && (
        <Snackbar
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="error">
            {requestErrors && (
              <ul style={{ listStyleType: "none" }}>
                {requestErrors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            )}
          </Alert>
        </Snackbar>
      )}
    </Card>
  );
};

export default QuizList;

const QuizListSkeleton = () => {
  // eslint-disable-next-line no-unused-vars
  const [quiz, setQuiz] = useState(() => {
    const array = Array.from({ length: 10 }, (_, i) => i + 1);

    return array;
  });
  return (
    <>
      {quiz.map((_, i) => (
        <TableRow hover sx={{ cursor: "pointer" }} key={i}>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="center">
            <Skeleton variant="text" />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
