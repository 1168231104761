import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import scrollTo from "utils/scrollTo";

import { userLogout } from "store/actions";
import { logoutRequest } from "services/api/index";

const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    scrollTo("#topPage");
  }, []);

  useEffect(() => {
    logoutRequest()
      .then(() => {
        const logout = setTimeout(() => {
          navigate("/login", { replace: true });
        }, 3000);
        return () => clearTimeout(logout);
      })
      .catch(() => {
        navigate("/login", { replace: true });
      })
      .finally(() => dispatch(userLogout()));
  }, [dispatch, navigate]);

  return (
    <>
      <Helmet>
        <title>Sair | +Alegria</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
        id="topPage"
      >
        <Container maxWidth="sm">
          <Card>
            <CardContent sx={{ paddingBottom: "16px !important" }}>
              <Box sx={{ maxWidth: 500, textAlign: "center" }}>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Você foi deslogado com sucesso!
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
