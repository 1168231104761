import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";

import { Box, Container, Grid } from "@material-ui/core";

import scrollTo from "utils/scrollTo";
import Toolbar from "components/layout/Toolbar";
import QuizView from "components/quiz/QuizView";
import QuizList from "components/quiz/QuizList";
import QuizNew from "components/quiz/QuizNew";
import QuizEdit from "components/quiz/QuizEdit";

import { getQuiz, getBenefitTiers } from "services/api/index";

const Quiz = (props) => {
  moment().locale("pt-br");
  const { action } = props;
  const { id } = useParams();
  const [quiz, setQuiz] = useState(null);
  const [benefitTiers, setBenefitTiers] = useState([]);

  useEffect(() => {
    scrollTo("#topPage");
  }, []);

  useEffect(() => {
    if (id) {
      getQuiz(id).then((response) => {
        setQuiz(response);
      });
    } else {
      setQuiz({});
    }
    getBenefitTiers().then((response) => {
      setBenefitTiers(response);
    });
  }, [id, action]);

  return (
    <>
      <Helmet>
        {action === "new" ? (
          <title>Novo Quiz | +Alegria</title>
        ) : action === "edit" ? (
          <title>Editar Quiz | +Alegria</title>
        ) : action === "view" ? (
          <title>Quiz | +Alegria</title>
        ) : (
          <title>Quizzes | +Alegria</title>
        )}
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
        id="topPage"
      >
        <Container maxWidth="lg">
          {action === "new" ? (
            <Toolbar name="Novo Quiz" root="quizzes" />
          ) : action === "edit" ? (
            <Toolbar name="Editar Quiz" root="quizzes" buttons={[]} />
          ) : action === "view" ? (
            <Toolbar
              name="Quiz"
              root="quizzes"
              buttons={[
                { label: "Editar", action: `edit/${id}`, primary: true },
                { label: "Voltar", action: `` },
              ]}
            />
          ) : (
            <>
              <Toolbar
                name="Quizzes"
                root="quizzes"
                buttons={[
                  { label: "+ Criar Quiz", action: "new", primary: true },
                ]}
              />
            </>
          )}
          <Grid container spacing={3} sx={{ pt: 3 }}>
            <Grid item lg={12} md={12} xs={12}>
              {action === "edit" ? (
                <QuizEdit quiz={quiz} benefit_tiers={benefitTiers} />
              ) : action === "new" ? (
                <QuizNew benefit_tiers={benefitTiers} />
              ) : action === "view" ? (
                <QuizView benefit_tiers={benefitTiers} />
              ) : (
                <>
                  <QuizList />
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Quiz;
