import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableHead,
  Box,
  Snackbar,
  Skeleton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { getUser, getUserOrders } from "services/api/index";
import formatMonetaryValue from "utils/formatMonetaryValue";
import { green, red, yellow } from "@material-ui/core/colors";

const CustomerView = (props) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const { id } = useParams();
  const [customer, setCustomer] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(0);
  const [showSuccess, setShowSuccess] = useState(Boolean(query.get("success")));
  const navigate = useNavigate();

  useEffect(() => {
    getUser(id).then((response) => {
      if (Object.keys(response).length > 0) setCustomer(response);
    });

    getUserOrders(id, page + 1, 10).then((response) => {
      setOrderList(response.orders);
      setTotal(response.pagination.total);
      setLimit(10);
    });
  }, [page, id]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleCloseAlert = () => {
    setShowSuccess(false);
  };

  const handleClick = (id) => {
    navigate(`/orders/${id}`);
  };

  return (
    <form autoComplete="off" noValidate {...props}>
      {!customer ? (
        <CustomerViewSkeleton />
      ) : (
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Table border={1} bordercolor="#F4F6F8">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Nome
                      </TableCell>
                      <TableCell>{customer.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        E-mail
                      </TableCell>
                      <TableCell>{customer.email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        CPF
                      </TableCell>
                      <TableCell>
                        {" "}
                        {`${customer.cpf.slice(0, 3)}.${customer.cpf.slice(
                          3,
                          6
                        )}.${customer.cpf.slice(6, 9)}-${customer.cpf.slice(
                          9,
                          11
                        )}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Telefone
                      </TableCell>
                      <TableCell>{customer.mobile_number}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Cidade
                      </TableCell>
                      <TableCell>{customer.city}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Estado
                      </TableCell>
                      <TableCell>{customer.state}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Gênero
                      </TableCell>
                      <TableCell>
                        {customer.gender === "m"
                          ? "Masculino"
                          : customer.gender === "f"
                          ? "Feminino"
                          : "Prefiro não dizer"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Segmentação
                      </TableCell>
                      <TableCell>{customer.benefit_tier.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Criado em
                      </TableCell>
                      <TableCell>
                        {moment(customer.created_at).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Status
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "initial",
                            color: "#FFFFFF",
                            fontWeight: "bold",
                            padding: "5px 15px",
                            textAlign: "center",
                            textTransform: "uppercase",
                            backgroundColor: customer.blocked
                              ? red[600]
                              : customer.status === "Bloqueado"
                              ? red[600]
                              : customer.status === "Criado"
                              ? yellow[600]
                              : customer.status === "Ativo"
                              ? green[600]
                              : red[600],
                          }}
                        >
                          {customer.blocked ? "Bloqueado" : customer.status}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {orderList.length > 0 && (
                  <Card sx={{ mt: 3 }}>
                    <CardHeader title="Pedidos"></CardHeader>
                    <PerfectScrollbar>
                      <Box sx={{ minWidth: 960 }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Nº</TableCell>
                              <TableCell>CPF</TableCell>
                              <TableCell>E-mail</TableCell>
                              <TableCell align="center">Data</TableCell>
                              <TableCell align="center">Segmento</TableCell>
                              <TableCell align="center">Valor</TableCell>
                              <TableCell align="center">Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orderList.slice(0, limit).map((order, i) => (
                              <TableRow
                                hover
                                key={i}
                                sx={{ cursor: "pointer" }}
                                onClick={() => handleClick(order.id)}
                              >
                                <TableCell>
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {order.number}
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  {"   "}
                                  {`${order.cpf.slice(0, 3)}.${order.cpf.slice(
                                    3,
                                    6
                                  )}.${order.cpf.slice(6, 9)}-${order.cpf.slice(
                                    9,
                                    11
                                  )}`}
                                </TableCell>
                                <TableCell>{order.email}</TableCell>
                                <TableCell align="center">
                                  {moment(order.order_date_time).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {order.benefit_tier}
                                </TableCell>
                                <TableCell align="center">
                                  {formatMonetaryValue(order.value)}
                                </TableCell>
                                <TableCell align="center">
                                  <Box
                                    sx={{
                                      color: "#FFFFFF",
                                      fontSize: "0.7rem",
                                      fontWeight: "bold",
                                      padding: "5px",
                                      textAlign: "center",
                                      textTransform: "uppercase",
                                      backgroundColor:
                                        order.status === "Recebido"
                                          ? "#3949ab"
                                          : order.status === "Entregue"
                                          ? "green"
                                          : order.status ===
                                            "Pagamento aprovado"
                                          ? "#3949ab"
                                          : order.status === "Cancelado"
                                          ? "gray"
                                          : order.status ===
                                            "Pagamento não realizado"
                                          ? "#d88200"
                                          : "red",
                                    }}
                                  >
                                    {order.status === "Recebido"
                                      ? "Recebido"
                                      : order.status === "Entregue"
                                      ? "Entregue"
                                      : order.status === "Pagamento aprovado"
                                      ? "Aprovado"
                                      : order.status === "Cancelado"
                                      ? "Cancelado"
                                      : order.status ===
                                        "Pagamento não realizado"
                                      ? "Não pago"
                                      : order.status}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </PerfectScrollbar>
                    <TablePagination
                      component="div"
                      count={total}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleLimitChange}
                      page={page}
                      rowsPerPage={limit}
                      rowsPerPageOptions={[]}
                      labelRowsPerPage={"Pedidos por página"}
                      labelDisplayedRows={({ from, to, count }) => {
                        return "" + from + "-" + to + " de " + count;
                      }}
                    />
                  </Card>
                )}
                {showSuccess && (
                  <Snackbar
                    open={showSuccess}
                    autoHideDuration={3000}
                    onClose={handleCloseAlert}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  >
                    <Alert severity="success">
                      Usuário atualizado com sucesso!
                    </Alert>
                  </Snackbar>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </form>
  );
};

const CustomerViewSkeleton = () => {
  return (
    <form autoComplete="off" noValidate>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Table border={1} bordercolor="#F4F6F8">
                <TableBody>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Nome
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      E-mail
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      CPF
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Telefone
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Cidade
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Estado
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Gênero
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Segmentação
                    </TableCell>
                    <Skeleton variant="text" />
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Criado em
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                      Status
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "initial",
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          padding: "5px 15px",
                          textAlign: "center",
                          textTransform: "uppercase",
                        }}
                      >
                        <Skeleton variant="text" />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default CustomerView;
