import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Box,
  Skeleton,
} from "@material-ui/core";

import { getOrder } from "services/api/index";
import formatMonetaryValue from "utils/formatMonetaryValue";

const Order = (props) => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    getOrder(id).then((response) => {
      if (Object.keys(response).length > 0) setOrder(response);
    });
  }, [id]);

  return (
    <form autoComplete="off" noValidate {...props}>
      {order ? (
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Table border={1} bordercolor="#F4F6F8">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Nº
                      </TableCell>
                      <TableCell>{order.number}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Nome
                      </TableCell>
                      <TableCell>
                        {order.user_id ? (
                          <Link to={`/customers/${order.user_id}`}>
                            {order.name}
                          </Link>
                        ) : (
                          order.name
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        E-mail
                      </TableCell>
                      <TableCell>{order.email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        CPF
                      </TableCell>
                      <TableCell>{order.cpf}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Data do Pedido
                      </TableCell>
                      <TableCell>
                        {moment(order.created_at).format("DD/MM/YYYY HH:mm:ss")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Segmento
                      </TableCell>
                      <TableCell>{order.benefit_tier}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Preço de Tabela
                      </TableCell>
                      <TableCell>
                        {formatMonetaryValue(order.price_in_money_before_sale)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Preço de Venda
                      </TableCell>
                      <TableCell>{formatMonetaryValue(order.value)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Itens
                      </TableCell>
                      <TableCell>
                        <Table>
                          <TableHead
                            sx={{
                              backgroundColor: "#F9F9F9",
                            }}
                          >
                            <TableRow>
                              <TableCell sx={{ fontSize: "0.7rem" }}>
                                Produto
                              </TableCell>
                              <TableCell
                                sx={{ fontSize: "0.7rem" }}
                                align="center"
                              >
                                Quantidade
                              </TableCell>
                              <TableCell
                                sx={{ fontSize: "0.7rem" }}
                                align="center"
                              >
                                Valor
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {order.order_items.map((item, i) => (
                              <TableRow key={i}>
                                <TableCell sx={{ fontSize: "0.7rem" }}>
                                  {item.product_name}
                                </TableCell>
                                <TableCell
                                  sx={{ fontSize: "0.7rem" }}
                                  align="center"
                                >
                                  {item.quantity}
                                </TableCell>
                                <TableCell
                                  sx={{ fontSize: "0.7rem" }}
                                  align="center"
                                >
                                  {formatMonetaryValue(
                                    item.value * item.quantity
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="35%"
                        sx={{ backgroundColor: "#F6F6F6" }}
                      >
                        Status
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "initial",
                            color: "#FFFFFF",
                            fontWeight: "bold",
                            padding: "5px 15px",
                            textAlign: "center",
                            textTransform: "uppercase",
                            backgroundColor:
                              order.status === "Recebido"
                                ? "#3949ab"
                                : order.status === "Entregue"
                                ? "green"
                                : order.status === "Pagamento aprovado"
                                ? "#3949ab"
                                : order.status === "Cancelado"
                                ? "gray"
                                : order.status === "Pagamento não realizado"
                                ? "#d88200"
                                : "red",
                          }}
                        >
                          {order.status === "Recebido"
                            ? "Recebido"
                            : order.status === "Entregue"
                            ? "Entregue"
                            : order.status === "Pagamento aprovado"
                            ? "Aprovado"
                            : order.status === "Cancelado"
                            ? "Cancelado"
                            : order.status === "Pagamento não realizado"
                            ? "Não pago"
                            : order.status}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <OrderSkeleton />
      )}
    </form>
  );
};

export default Order;

const OrderSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Table border={1} bordercolor="#F4F6F8">
              <TableBody>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    Nº
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    Nome
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    E-mail
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    CPF
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    Data do Pedido
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    Segmento
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    Preço de Tabela
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    Preço de Venda
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    Itens
                  </TableCell>
                  <TableCell>
                    <Table>
                      <TableHead
                        sx={{
                          backgroundColor: "#F9F9F9",
                        }}
                      >
                        <TableRow>
                          <TableCell sx={{ fontSize: "0.7rem" }}>
                            Produto
                          </TableCell>
                          <TableCell sx={{ fontSize: "0.7rem" }} align="center">
                            Quantidade
                          </TableCell>
                          <TableCell sx={{ fontSize: "0.7rem" }} align="center">
                            Valor
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <Skeleton />
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="35%" sx={{ backgroundColor: "#F6F6F6" }}>
                    Status
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "initial",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        padding: "5px 15px",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      <Skeleton />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
