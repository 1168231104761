import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  CardHeader,
  Button,
  Divider,
} from "@material-ui/core";
import scrollTo from "utils/scrollTo";

import {
  getUserImports,
  getUserImportActivate,
  getUserImport,
} from "services/api/index";

const CustomerMassiveActions = (props) => {
  const [limit, setLimit] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [importList, setImportList] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    props.setLoading(true);

    getUserImports(page + 1, 10)
      .then((response) => {
        setImportList(response.mass_imports);
        setTotal(response.pagination.total);
        setLimit(10);
        props.setLoading(false);
      })
      .catch((error) => {
        props.setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    scrollTo("#topTable");
  };

  const activateImport = (id) => {
    getUserImportActivate(id).then((response) => {
      props.setShowSuccess(true);
      props.setSuccessMessage("Importação ativada com sucesso!");
      getUserImports(page + 1, 10).then((response) => {
        setImportList(response.mass_imports);
        setTotal(response.pagination.total);
        setLimit(10);
      });
    });
  };

  const editImport = (id) => {
    getUserImport(id).then((response) => {
      props.setShowImportEdit(true);
      props.setId(id);
    });
  };

  const viewImport = (id) => {
    props.setId(id);
    props.setShowImport(true);
  };

  return (
    <>
      {importList && (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <CardHeader
              title="Importações"
              sx={{ padding: "10px" }}
            ></CardHeader>
            <Box>
              <Button
                color="primary"
                variant="outlined"
                type="button"
                onClick={() => props.setShowImportNew(!props.showImportNew)}
                sx={{ marginLeft: "20px" }}
              >
                Nova Importação
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="button"
                onClick={() => navigate(`/customers`)}
                sx={{ marginLeft: "5px" }}
              >
                Voltar
              </Button>
            </Box>
          </Box>
          <Divider />
          <PerfectScrollbar>
            <Box sx={{ minWidth: 960, mb: 5 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell align="center">Tipo</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Criado em</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {importList &&
                    importList.length > 0 &&
                    importList.map((mass_import, i) => (
                      <TableRow hover key={i}>
                        <TableCell>{mass_import.name}</TableCell>
                        <TableCell align="center">{mass_import.type}</TableCell>
                        <TableCell align="center">
                          {mass_import.status}
                        </TableCell>
                        <TableCell align="center">
                          {mass_import.created_at}
                        </TableCell>
                        <TableCell align="right">
                          {mass_import.status === "Criado" && (
                            <Button
                              color="success"
                              size="small"
                              variant="outlined"
                              type="button"
                              onClick={() => activateImport(mass_import.id)}
                              sx={{ padding: "0px 9px" }}
                            >
                              Ativar
                            </Button>
                          )}
                          {mass_import.status === "Criado" ? (
                            <Button
                              color="info"
                              size="small"
                              variant="outlined"
                              type="button"
                              onClick={() => editImport(mass_import.id)}
                              sx={{
                                marginLeft: "5px",
                                padding: "0px 9px",
                              }}
                            >
                              Editar
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              size="small"
                              variant="outlined"
                              type="button"
                              onClick={() => viewImport(mass_import.id)}
                              sx={{
                                marginLeft: "5px",
                                padding: "0px 9px",
                              }}
                            >
                              Ver
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          {total > 1 && (
            <TablePagination
              component="div"
              count={total}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[]}
              labelRowsPerPage={"Usuários por página"}
              labelDisplayedRows={({ from, to, count }) => {
                return "" + from + "-" + to + " de " + count;
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default CustomerMassiveActions;
