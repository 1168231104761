import { useState } from "react";
import moment from "moment";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
  FormControl,
  Select,
  MenuItem,
  Skeleton,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    textTransform: "uppercase",
    "&:focus": {
      borderBottom: "0px !important",
    },
  },
  selectRoot: {
    //...other styles
    "&:focus": {
      backgroundColor: "yellow",
    },
  },
  select: {
    marginTop: "4px",
    fontWeight: "bold !important",
    fontSize: "12px !important",
    "&:before": {
      borderBottom: "0px !important",
    },
    "&:after": {
      borderBottom: "0px !important",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SummarySkeleton = (props) => {
  moment().locale("pt-br");
  const { title, hasFilter, numberOfItems, valueTag, avatarSize, spacing } =
    props;
  const classes = useStyles();
  const [interval, setInterval] = useState(15);
  // eslint-disable-next-line no-unused-vars
  const [dataItems, setDataItems] = useState(() => {
    const array = Array.from({ length: numberOfItems }, (_, i) => i + 1);

    return array;
  });

  const handleChange = (event) => {
    setInterval(event.target.value);
  };

  return (
    <>
      <Card sx={{ height: "100%" }}>
        {hasFilter ? (
          <CardHeader
            title={title}
            action={
              <>
                <FormControl variant="standard" className={classes.formControl}>
                  <Select
                    id="interval"
                    value={interval}
                    onChange={handleChange}
                    className={classes.select}
                  >
                    <MenuItem value={15}>
                      <Skeleton variant="text" />
                    </MenuItem>
                    <MenuItem value={30}>
                      <Skeleton variant="text" />
                    </MenuItem>
                    <MenuItem value={60}>
                      <Skeleton variant="text" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </>
            }
          />
        ) : (
          <CardHeader title={<Skeleton variant="text" />} />
        )}
        <Divider />
        <CardContent>
          {dataItems.length > 0 &&
            dataItems.map((_, i) => (
              <Grid key={i}>
                <Grid
                  key={i}
                  container
                  spacing={4}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Grid item>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      sx={{ pb: 1 }}
                    >
                      <Skeleton variant="text" width={200} />
                    </Typography>

                    <Typography color="textPrimary" variant={valueTag}>
                      <Skeleton variant="text" width={200} />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant="circular"
                      width={avatarSize}
                      height={avatarSize}
                    />
                  </Grid>
                </Grid>
                {numberOfItems !== i + 1 && (
                  <Divider sx={{ mb: spacing, mt: spacing }} />
                )}
              </Grid>
            ))}
        </CardContent>
      </Card>
    </>
  );
};

export default SummarySkeleton;
