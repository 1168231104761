import React from "react";
import User from "./images/btn_user.webp";
import Cart from "./images/btn_carrinho.webp";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  text: {
    fontFamily: "Open Sans",
    color: (props) => props.primaryColor,
    "&:hover": {
      cursor: "pointer",
    },
  },
  cart: {
    fontFamily: "Open Sans",
    color: (props) => props.primaryColor,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

const UserActions = () => {
  const previewSettings = useSelector((state) => state.preview);
  const classes = useStyles({
    primaryColor: previewSettings.main_colors.primary_color,
  });

  return (
    <div className={classes.container}>
      <div className={classes.actionContainer}>
        <img src={User} alt="" className={classes.icon} />
        <span className={classes.text}>Entrar</span>
      </div>
      <div className={classes.actionContainer}>
        <img src={Cart} alt="" className={classes.icon} />
        <span className={classes.cart}>0 item</span>
      </div>
    </div>
  );
};

export default UserActions;
